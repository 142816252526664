import React, { /*useReducer, */useContext, /*useEffect*/ } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Alert from './Alert/Alert'
import { auth } from "../../firebase";
import ArburgLogo from "../../ressources/Navbar/Arburg_Logo.svg"
import user from "../../ressources/Navbar/user.png"
import { UserContext } from '../..';

// import logo3 from './bell-2-64.png'
// import Logo1 from './Logo_Arburg.svg'

//SCSS
import './Navbar.scss'


const Navbar = (props) => {

  const userCont = useContext(UserContext);

  // useEffect(() => {console.log("DDDEEEBBBBUUUUUGGGG",userCont?.role)}, [])

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  if (auth.currentUser !== null) {
    var headings = ["News", "Karriere", "Bewerbung", "Ausbilder", "Leitbild"]
  }
  else {
    headings = ["News", "Karriere", "Bewerbung", "Ausbilder", "Leitbild"]
  }

  // "Projektgruppen", "Special"
  const Listheadings = headings.map((heading, index) =>
    <li key={`${heading.title}-${index}`} className="nav-item">
      <NavLink className="nav-link white" onClick={scrollToTop()} to={"../../" + heading}>{heading}
        <span className="sr-only">(current)</span>
      </NavLink>
    </li>
  )



  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark" id="header-navbar">
      <Link className="navbar-brand titel" to="/" onClick={scrollToTop()}>
        <img alt="Arburg Ausbildung" className="LogoArburg" src={ArburgLogo}></img>
        <h2 className="NavbarAusbildung">Ausbildung</h2>
      </Link>

      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse titelheadings" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          {Listheadings}


          {auth.currentUser ? (
            <div>
              <li className="nav-item dropdown">
                <span class="nav-link white dropdown" data-bs-toggle="dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img className="userimg" src={user} alt="userimg" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></img>
                </span>
                <ul class="dropdown-menu dropdownmenu">
                  <li><a class="dropdown-item" href="/#/Account"><b>{userCont?.firstname} {userCont?.lastname}</b></a></li>
                  <div class="dropdown-divider"></div>
                  <li><a class="dropdown-item" href="/#/AzubiInfo">Ausbildung-Aktuell</a></li>
                  {/* <div class="dropdown-divider"></div> */}
                  <li><a class="dropdown-item" href="/#/Infoordner">Infoordner</a></li>
                  <li><a class="dropdown-item" href="/#/VPLAN">VPLAN</a></li>
                  <li><a class="dropdown-item" href="/#/Projektgruppen">Projektgruppen</a></li>
                  {userCont?.role === "2" || userCont?.role === "3" ? (
                    <div>
                      <div class="dropdown-divider"></div>
                      <li><a class="dropdown-item" href="/#/Evaluation">Evaluation</a></li>
                      <li><a class="dropdown-item" href="/#/studienpraktikamanager">Manager Praktika</a></li>
                      <li><a class="dropdown-item" href="/#/ausbildungsmanager">Manager Ausbildung</a></li>
                    </div>
                  ) :
                  (
                    <div></div>
                  )

                  }
                  {userCont?.role === "2" ? (
                    <div>
                      <div class="dropdown-divider"></div>
                      <li><a class="dropdown-item" href="/#/Backend">Backend</a></li>
                    </div>
                  ) : (<div></div>)}

                  <div class="dropdown-divider"></div>
                  <li><a class="dropdown-item" href="/#/SignOut"><b>Logout</b></a></li>
                </ul>
              </li>
            </div>
          ) : (
            <div>
            </div>
          )
          }


          <li className="nav-item" >
            <Alert />
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
