import React from "react";
import { auth, fb } from "../../firebase";
import { Link, Redirect } from "react-router-dom";
// import { signOut } from '../../store/actions/authActions'
import $ from "jquery";

class Sidebar extends React.Component {
  componentDidMount() {
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });

    $("#myModal").on("shown.bs.modal", function () {
      $("#myInput").trigger("focus");
    });
  }

  verify = () => {
    var user = auth.currentUser;
    user
      .sendEmailVerification()
      .then(function () {
        console.log("email send");
      })
      .catch(function (error) {
        console.log("email not send", error);
      });
  };

  signOut = () => {
    fb.firestore().collection("users")
      .doc(auth.currentUser.uid)
      .update({ signedIn: false })
      .then(function (succeed) {
        console.log("logout", succeed);
        window.location.href = "/#/signin";
        auth.signOut();
      })
      .catch(function (error) {
        console.log("User not loged out", error);
      });
  };

  reset = () => {
    var emailAddress = auth.currentUser.email;

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
        fb.firestore().collection("users").doc(auth.currentUser.uid).update({passwordReset: true});
        console.log("email send");
      })
      .catch(function (error) {
        // An error happened.
        console.log("email not send", error);
      });
  };

  render() {

    if (auth.currentUser === undefined) {
      return <Redirect to="/signin"/>
    }

      // fb.firestore().collection('users').doc(auth.currentUser.uid).get().then(c => {
      //   if (c.data().deleted === true) {
      //     alert("Der User wurde gelöscht")
      //     fb.collection('users').doc(auth.currentUser.uid).delete();
      //     fb.collection("users").doc(auth.currentUser.uid).update({signedIn: false});
      //     auth.currentUser.delete();
      //     auth.signOut();
      //   }
      // })
    
      // alert("Der User wurde gelöscht")
      // db.collection('users').doc(result.user.uid).delete();
      // auth.currentUser.delete();
      // auth.signOut();
    


    return (
      <div className="bg-light border-right" id="sidebar-wrapper">
        <Link
          to="/Backend"
          className="sidebar-heading list-group-item list-group-item-action bg-light"
        >
          Backend
        </Link>
        <div className="list-group list-group-flush">
          <a
            href={fb.options.databaseURL}
            className="list-group-item list-group-item-action bg-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {fb.options.projectId}
          </a>

          <Link
            to="/Dashboard"
            className="list-group-item list-group-item-action bg-light"
          >
            Dashboard
          </Link>
          <Link
            to="/DataDeleter"
            className="list-group-item list-group-item-action bg-light"
          >
            Data Deleter
          </Link>
          {/* <Link
            to="/CheckIn"
            className="list-group-item list-group-item-action bg-light"
          >
            CheckIn
          </Link> */}
          <Link
            to="/ProjektOrganizer"
            className="list-group-item list-group-item-action bg-light"
          >
            ProjektOrganizer
          </Link>
          <Link
            to="/QRgenerator"
            className="list-group-item list-group-item-action bg-light"
          >
            QRgenerator
          </Link>
          <Link
            to="/Profile"
            className="list-group-item list-group-item-action bg-light"
          >
            Profile
          </Link>
          <Link
            to="/Wartung"
            className="list-group-item list-group-item-action bg-light"
          >
            Wartung
          </Link>
          <Link
            to="/DataUploader"
            className="list-group-item list-group-item-action bg-light"
          >
            Data Uploader
          </Link>

          <Link
            to="/Anmeldungen"
            className="list-group-item list-group-item-action bg-light"
          >
            Anmeldungen
          </Link>
          <Link
            to="/Register"
            className="list-group-item list-group-item-action bg-light"
          >
            User Registrieren
          </Link>
          {/* <button
            type="button"
            className="list-group-item list-group-item-action bg-light"
            data-toggle="modal"
            data-target="#exampleModal5"
          >
            User anzeigen
          </button> */}

          <button
            className="dropdown-toggle list-group-item list-group-item-action"
            id="navbarDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Optionen
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <button
              className="dropdown-item"
              type="button"
              data-toggle="modal"
              data-target="#exampleModal5"
            >
              User anzeigen
            </button>

            <div className="dropdown-divider"></div>
            <p
              className="dropdown-item"
              type="button"
              onClick={() => {
                this.signOut();
              }}
            >
              Ausloggen
            </p>
            <div className="dropdown-divider"></div>
            <Link to="/" className="dropdown-item" type="button">
              Zurück zur Homepage
            </Link>
          </div>
          <div
            className="modal fade"
            id="exampleModal5"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Aktueller Benutzer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <b>Email: </b>{auth.currentUser.email}
                  <br />
                  <b>Email bestätigt: </b>{auth.currentUser.emailVerified.toString()}
                  <br />
                  <b>User ID: </b>{auth.currentUser.uid}
                  <br />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      this.reset();
                    }}
                  >
                    Passwort Reset
                  </button>
                  {/* {true ? (
                    auth.currentUser.emailVerified
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      disabled
                      onClick={() => {
                        this.verify();
                      }}
                    >
                      Verify E-Mail
                    </button>
                  )} */}

                  {auth.currentUser.emailVerified === true ? (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      disabled
                      onClick={() => {
                        this.verify();
                      }}
                    >
                      Verify E-Mail
                    </button>
                  ) : null}

                  {auth.currentUser.emailVerified === false ? (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.verify();
                      }}
                    >
                      Verify E-Mail
                    </button>
                  ) : null}

                  {/* {false ? (
                    auth.currentUser.emailVerified
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.verify();
                      }}
                    >
                      Verify E-Mail
                    </button>
                  )} */}

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Schließen
                  </button>

                  <button type="button" className="btn btn-primary">
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Sidebar;
