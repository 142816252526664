import React from "react";
import "./Loading.scss";
import logo from "./logo.png";
import arburg from "./Logo_Arburg.svg";

class Loading extends React.Component {
  render() {
    return (
      <div className="text-center container">
        <br />
        <img alt="Arburg Ausbildung" className="LogoArburg" src={arburg}></img>
        <br />
        <h1>Azubi Homepage</h1>
        <br />

        <img src={logo} className="App-logo" alt="logo" />

        <br />
        <br />
        <br />
        <h2 className="text-primary">
          Die Homepage wird geladen
        </h2>
        {/* <u>
          <i>
            <a
              href="https://www.arburg.com/ausbildung"
              className="link-primary old_page"
            >
              <h2 alt="alte Homepage">arburg.com/ausbildung</h2>
            </a>
          </i>
        </u> */}
      </div>
    );
  }
}
export default Loading;
