/**
 * @file: AusbildungPost.jsx
 * @description: Details page for ausbildungsberuf
 */

import { fb } from "../../../firebase";
import { useParams } from "react-router-dom";
import Footer from "../../../pages/JobboardAusbildung/footer/footer";
import Navigation from "../../../pages/JobboardAusbildung/navigation/navigation";
import { useState } from "react";
import { useEffect } from "react";

import './AusbildungPost.scss'
import Benefit from "../Benefits/Benefit";

export default function AusbildungPost() {
    let { id } = useParams();
    let [image, setImage] = useState("praktikum-post-header");

    let [ausbData, setAusbData] = useState({
        benefits: [],
        title: "",
        startDate: "",
        shortDescHeader: "",
        shortDesc: "",
        date: "",
        link: "",
        img: "",
        type: "",
        content: {
            b1Title: "",
            b1Content: [],
            b2Title: "",
            b2Content: [],
            b3Title: "",
            b3Content: [],
            b4Title: "",
            b4Content: "",
        }
      });
      useEffect(() => {
        let getAusbData = fb.firestore().collection('ausbildung');
        
        getAusbData.onSnapshot((data) => {
            data.forEach((doc) => {
                if(doc.id === id) {
                    setAusbData({
                    benefits:  doc.data().data.benefits,
                    title: doc.data().data.postTitle,
                    startDate: doc.data().data.startDate,
                    shortDesc: doc.data().data.shortDesc,
                    shortDescHeader: doc.data().data.shortDescHeader,
                    date: doc.data().creationDate,
                    link: doc.data().data.link,
                    img: doc.data().data.img,
                    type: doc.data().data.displayType,
                    content: {
                        b1Title: doc.data().data.content.b1Title,
                        b1Content: doc.data().data.content.b1Content,
                        b2Title: doc.data().data.content.b2Title,
                        b2Content: doc.data().data.content.b2Content,
                        b3Title: doc.data().data.content.b3Title,
                        b3Content: doc.data().data.content.b3Content,
                        b4Title: doc.data().data.content.b4Title,
                        b4Content: doc.data().data.content.b4Content,
                    }
                })
                switch (doc.data().data.img) {
                    case "Kaufm":
                        setImage("ausbildung-post-header ausbildung-header-kaufm")
                        break;
                    case "Techn":
                        setImage("ausbildung-post-header ausbildung-header-techn")
                        break;
                    case "IT":
                        setImage("ausbildung-post-header ausbildung-header-it")
                        break;
                
                    default:
                        break;
                }
                }
            })
          })
        document.title = 'Jobboard';
      }, [])
      console.log(ausbData)
    return (
        <div className="ausbildung-post">
            <Navigation />
                <div className="ausbildung-post-main">
                    <div className={image}>
                        <div className="ausbildung-post-text">
                            <h1>{ausbData.title}</h1>
                        </div>
                    </div>
                    <div className="ausbildung-post-preview">
                        <h4>{ausbData.shortDescHeader}</h4>
                        <p>{ausbData.shortDesc}</p>
                    </div>
                    <div className="ausbildung-post-content">
                    <div className="post-content-block">
                        <h4>{ausbData.content.b1Title}</h4>
                        <div className="block-list">
                            {ausbData.content.b1Content.map((index, i) => {
                                return <div className="block-list-item"><span className="line-list">/ </span><p key={i}>{index}</p></div>
                            })}
                        </div>
                    </div>
                    <div className="post-content-block">
                        <h4>{ausbData.content.b2Title}</h4>
                        <div className="block-list">
                            {ausbData.content.b2Content.map((index, i) => {
                                return <div className="block-list-item"><span className="line-list">/ </span><p key={i}>{index}</p></div>
                            })}
                        </div>
                    </div>
                    <div className="post-content-block">
                        <h4>{ausbData.content.b3Title}</h4>
                        <div className="block-list">
                            {ausbData.content.b3Content.map((index, i) => {
                                return <div className="block-list-item"><span className="line-list">/ </span><p key={i}>{index}</p></div>
                            })}
                        </div>
                    </div>
                    <div className="post-content-block">
                        <h4>{ausbData.content.b4Title}</h4>
                        <p>{ausbData.content.b4Content}</p>
                    </div>
                </div>
                <div className="ausbildung-post-aio">
                    <div className="aio-container">
                        <span className="aio-header">Art</span>
                        <span>{ausbData.type}</span>
                    </div>
                    <div className="aio-container">
                        <span className="aio-header">Beginn</span>
                        <span>{ausbData.startDate}</span>
                    </div>
                    <div className="aio-container">
                        <span className="aio-header">Stelle online seit</span>
                        <span>{ausbData.date}</span>
                    </div>
                </div>
                <div className="ausbildung-post-menu">
                    <a href={`${ausbData.link}`} 
                    target="_blank"
                    rel="noreferrer">Jetzt bewerben »</a>
                </div>
                <div className="ausbildung-benefits">
                    <div className="benefits-header">
                        <h4><span>/</span> Benefits</h4>
                    </div>
                    <div className="benefits-list">
                        {ausbData.benefits.map((index, i) => {
                            return <Benefit key={i} type={index} />
                        })}
                    </div>
                </div>
                </div>
            <Footer />
        </div>
    )
}