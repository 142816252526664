import React from 'react';
import './LinktreePost.scss';


const LinktreePost = ({title,url,imageUrl}) => {
return(
    
        <div className="card-group col-lg-4 col-md-6 col-sm-12">
            <a href={url}>
            <div className="card mb-4 linktreepost">
            <img className="card-img-top" src={imageUrl} alt={title}/>
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                </div>
            </div>
            </a>
        </div>
    
    )
}
export default LinktreePost