import React from "react";

//SCSS
import "./SocialMedia.scss";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";

import { auth } from "../../firebase";

import FacebookWeiß from "../../ressources/Footer/facebook_weiß.png";
import InstagrammWeiß from "../../ressources/Footer/Instagramm_weiß.png";
import YoutubeWeiß from "../../ressources/Footer/Youtube_weiß.png";
import TwitterWeiß from "../../ressources/Footer/twitter_weiß.png";
import LinkedinWeiß from "../../ressources/Footer/linkedin_weiß.png";

function SocialMedia() {
  if (auth.currentUser !== null) {
    return (
      <div className="Container" key={uuid()}>
        <div className="SocialMedia5">
          {/*<div className="reihe5">
                        <a href="http://arburg.com/">
                    <img alt="Arburg Logo" className="LogoSideArburg" src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2F45d702b6-5690-4079-aab1-25e59a9824a0_arburg_vektor_white.png?alt=media&token=d58ba961-f4ad-45bd-ae2b-d3438cd20d2b"></img>
                        </a>
                    </div>*/}
          <div className="reihe5">
            <Link to="/faq">
              <h2 className="faqSide">FAQ</h2>
            </Link>
          </div>
          <div className="reihe5">
            <a
              href="https://www.instagram.com/arburg_rookies/?hl=de"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Instagram Logo"
                className="LogoSideInstagramm"
                src={InstagrammWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe5">
            <a
              href="https://de-de.facebook.com/arburgworld/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Facebook Logo"
                className="LogoSideFacebook"
                src={FacebookWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe5">
            <a
              href="https://www.youtube.com/user/ARBURGofficial"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Youtube Logo"
                className="LogoSideYoutube"
                src={YoutubeWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe5">
            <a
              href="https://twitter.com/arburgworld"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Twitter Logo"
                className="LogoSideTwitter"
                src={TwitterWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe5">
            <a
              href="https://www.linkedin.com/company/arburg-gmbh-co-kg/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="LinkedIn Logo"
                className="LogoSideLinkedIn"
                src={LinkedinWeiß}
              ></img>
            </a>
          </div>
          {/* <div className="reihe5">
                    <Link to="/infoordner">
                        <h2 className="faqSide">Info</h2>
                        </Link>
                    </div> */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="Container" key={uuid()}>
        <div className="SocialMedia4">
          {/*<div className="reihe4">
                            <a href="http://arburg.com/">
                        <img alt="Arburg Logo" className="LogoSideArburg" src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2F45d702b6-5690-4079-aab1-25e59a9824a0_arburg_vektor_white.png?alt=media&token=d58ba961-f4ad-45bd-ae2b-d3438cd20d2b"></img>
                            </a>
                        </div>*/}
          <div className="reihe4">
            <Link to="/faq">
              <h2 className="faqSide">FAQ</h2>
            </Link>
          </div>
          <div className="reihe4">
            <a
              href="https://www.instagram.com/arburg_rookies/?hl=de"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Instagram Logo"
                className="LogoSideInstagramm"
                src={InstagrammWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe4">
            <a
              href="https://de-de.facebook.com/arburgworld/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Facebook Logo"
                className="LogoSideFacebook"
                src={FacebookWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe4">
            <a
              href="https://www.youtube.com/user/ARBURGofficial"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Youtube Logo"
                className="LogoSideYoutube"
                src={YoutubeWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe4">
            <a
              href="https://twitter.com/arburgworld"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Twitter Logo"
                className="LogoSideTwitter"
                src={TwitterWeiß}
              ></img>
            </a>
          </div>
          <div className="reihe4">
            <a
              href="https://www.linkedin.com/company/arburg-gmbh-co-kg/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="LinkedIn Logo"
                className="LogoSideLinkedIn"
                src={LinkedinWeiß}
              ></img>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialMedia;
