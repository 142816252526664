import React from "react";
import { auth, fb } from "../../firebase";
//SCSS
import "./Account.scss";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection("users");
        this.unsubscribe = null;
        this.state = {
            users: [],
        };

    }
    onCollectionUpdate = (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {

            const { email, firstname, lastname, role, confirmed, deleted, signedIn, passwordReset, beruf, lastlogin, startyear, createdAt } =
                doc.data();
            users.push({
                key: doc.id,
                email,
                firstname,
                lastname,
                role,
                confirmed,
                deleted,
                signedIn,
                passwordReset,
                lastlogin,
                startyear,
                createdAt,
                beruf
            }
            );
        });

        this.setState({
            users,
        });
    };
    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    resetPW = (e) => {
        this.state.users.map((user) => {
            if (user.key === e.target.id) {
                // console.log(user.email)
                var emailAddress = user.email;
                auth
                    .sendPasswordResetEmail(emailAddress)
                    .then(function () {
                        // Email sent.
                        // console.log(emailAddress)
                        fb.firestore().collection("users").doc(auth.currentUser.uid).update({ passwordReset: true });
                        console.log("email send");
                    })
                    .catch(function (error) {
                        // An error happened.
                        console.log("email not send", error);
                    });
            }
            return true;
        });
    };

    triggerChange = (e) => {
        this.state.users.map((user) => {
            if (user.key === auth.currentUser.uid) {
                console.log(user.key)
                e.preventDefault();
                if (e.target.id === "startyear") {
                    this.setState({
                        user: {
                            ...this.state.user,
                            startyear: e.target.value
                        }
                    })
                }
                if (e.target.id === "beruf") {
                    this.setState({
                        user: {
                            ...this.state.user,
                            beruf: e.target.value
                        }
                    })
                }
            }
            return null;
        })
    }

    saveToDb = () => {
        this.state.users.map((user) => {
            if (user.key === auth.currentUser.uid) {
                fb.firestore()
                    .collection("users")
                    .doc(auth.currentUser.uid)
                    .update(this.state.user)
                    .then(() => console.log("Success!"));
                alert("Speichern erfolgreich !");
                // }
            }
            return null;
        })
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="my-4">Mein Konto</h1>
                        {this.state.users && this.state.users.map((user) => {
                            if (user.key === auth.currentUser.uid) {
                                return (

                                    <div className="card-group">
                                        <div className="card blogpost">

                                            <h3 className="card-header">{user.firstname} {user.lastname}</h3>

                                            <div className="card-body">

                                                <div className="card-text">
                                                    <form>
                                                        <div className="form-group row">
                                                            <label for="staticEmail" className="col-sm-2 col-form-label"><b>E-Mail</b></label>
                                                            <div class="col-sm-10">
                                                                <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={user.email} />
                                                            </div>

                                                            <label for="inputPassword" class="col-sm-2 col-form-label"><b>Ausbildungsstart</b></label>
                                                            <div class="col-sm-10">
                                                                <input class="form-control" type="number" id="startyear" placeholder="Jahr" min="1950" max="2100" defaultValue={user.startyear} onChange={this.triggerChange} />
                                                            </div>

                                                            <label for="inputPassword" class="col-sm-2 col-form-label"><b>Beruf</b></label>
                                                            <div class="col-sm-10">
                                                                <input class="form-control" type="text" id="beruf" placeholder="Ausbildungsberuf" defaultValue={user.beruf} onChange={this.triggerChange} />
                                                            </div>

                                                            <label for="staticEmail" className="col-sm-2 col-form-label"><b>Rolle</b></label>
                                                            <div class="col-sm-10">
                                                                {user.role === "1" ? (
                                                                    <input type="text" readonly class="form-control-plaintext" id="role" value="Infoordner" />
                                                                ) : (
                                                                    <input type="text" readonly class="form-control-plaintext" id="role" value="Admin" />
                                                                )}
                                                            </div>

                                                            <label for="staticEmail" className="col-sm-2 col-form-label"><b>User angelegt am: </b></label>
                                                            <div class="col-sm-10">
                                                                <input type="text" readonly class="form-control-plaintext" id="createdAt" value={new Date(user.createdAt).toLocaleDateString()} />
                                                            </div>

                                                        </div>
                                                    </form>


                                                    {/* <div className="card-text"><b>User angelegt am </b>{new Date(user.createdAt).toLocaleDateString()} um {new Date(user.createdAt).toLocaleTimeString()} Uhr</div> */}

                                                    {user.confirmed ? (
                                                        // <div className="good">Ihr Account wurde bestätigt <br /></div>
                                                        <></>
                                                    ) : (

                                                        <div className="bad">Ihr Account wurde nocht nicht bestätigt <br /></div>
                                                    )}

                                                    {user.passwordReset ? (
                                                        // <div className="good"> <br />Ihr Passwort ist sicher !</div>
                                                        <></>
                                                    ) : (<div className="bad"> <br />Ändern Sie ihr Passwort !</div>)}

                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                <button
                                                    type="button"
                                                    className="btnProfil  btn-secondary btnSave btn"
                                                    id={user.key}
                                                    onClick={this.saveToDb}
                                                >
                                                    Speichern
                                                </button>

                                                <a href="/#/SignOut">
                                                    <div type="button" className="btnProfil btn btn-secondary">
                                                        Logout
                                                    </div>
                                                </a>

                                                <button
                                                    type="button"
                                                    className="btnProfil btn btn-danger"
                                                    id={user.key}
                                                    onClick={this.resetPW}
                                                >
                                                    Passwort ändern
                                                </button>

                                            </div>

                                        </div>
                                    </div>

                                );
                            }
                            return null;
                        })}
                        <br />
                    </div>
                </div>
            </div >
        );
    }
}

export default Account;
