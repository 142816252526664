import React from "react";
import { fb } from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
import { Link } from "react-router-dom";
// import Loading from "../../../components/Loading/Loading";
// import ProjektGetter from "./ProjektGetter";

//SCSS
import "./ProjektGetter.scss";

class ProjektOrganizer extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.ref2 = fb.firestore().collection("fl_schemas");

    this.unsubscribe = null;
    this.unsubscribe2 = null;
    this.state = {
      projects: [],
      schemas: [],
      keys: [],
      ueberuns: [],
      currentPage: "",
    };
  }
  onCollectionUpdate2 = (querySnapshot) => {
    const schemas = [];
    querySnapshot.forEach((doc) => {
      const { id, fields } = doc.data();
      schemas.push({
        key: doc.id,
        id,
        fields,
      });
    });

    this.setState({
      schemas,
    });
  };

  onCollectionUpdate = (querySnapshot) => {
    const projects = [];
    const keys = [];
    const ueberuns = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === this.state.currentPage) {
        projects.push(doc.data());
        const { content, imageUrl, videoUrl } = doc.data();
        ueberuns.push({
          key: doc.id,
          doc,
          content,
          imageUrl,
          videoUrl,
        });
      }
    });

    this.setState({
      projects,
      keys,
      ueberuns,
    });
  };

  delete(key) {
    fb.firestore()
      .collection("fl_content")
      .doc(key.id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      })
      .finally(function () {
        window.location.reload();
      });
  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    this.unsubscribe2 = this.ref2.onSnapshot(this.onCollectionUpdate2);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
  }

  changePage = (str) => {
    //Disable everything else
    this.state.schemas.map((ifrm) => {
      document.getElementById(ifrm.id).classList = ["nav-item"];
      return <></>;
    });
    document.getElementById(str).classList.add("active");
    this.setState({ currentPage: str });
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    this.unsubscribe2 = this.ref2.onSnapshot(this.onCollectionUpdate2);
  };

  render() {
    // const width = {
    //   width: "50%",
    //   height: "50%",
    //   display: "block",
    //   marginLeft: "auto",
    //   marginRight: "auto",
    //   paddingTop: "25%",
    //   paddingBottom: "25%",
    // };

    // this.state.projects.sort(function (a, b) {
    //   return b.createdAt.toDate() - a.createdAt.toDate();
    // });

    // const projects = this.state.projects;
    // const ueberuns = this.state.ueberuns;
    const schemas = this.state.schemas;
    const projects = this.state.projects;
    var arr1 = [];
    var arr2 = [];
    var arr3 = [];
    schemas.map((schema, index1) => {
      arr2 = [];
      schema.fields.map((field, index2) => {
        arr2[index2] = field.key;
        arr1[index1] = arr2;
        arr3[schema.id] = arr2;
        return null;
      });
      return null;
    });
    if (schemas) {
      return (
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid">
              <br />

              <nav className="tabbable">
                <ul className="nav nav-tabs" id="nav-tab" role="tablist">
                  {schemas &&
                    schemas.map((schema) => {
                      return (
                        <li
                          key={schema.id}
                          className="nav-item "
                          id={schema.id}
                        >
                          <p
                            onClick={(e) => {
                              this.changePage(schema.id);
                              return null;
                            }}
                            className="nav-link"
                            aria-current="page"
                          >
                            {schema.id}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </nav>

              <div className="tab-content pt-2" id="nav-tabContent">
                {schemas &&
                  schemas.map((e) => {
                    if (this.state.currentPage === e.id) {
                      return (
                        <div className="container-fluid" key={e.id}>
                          {e.id}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
              <br />
              <div class="row" id="categories">
                {/* <MDBRow id="categories"> */}
                {/* <MDBCol md="3">
                  <div key={uuid()} className="card" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Link to="/ProjektCreate">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={width}
                        fill="currentColor"
                        className="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </Link>
                  </div>
                </MDBCol> */}
                {projects &&
                  projects.map((e, index) => {
                    var url = "/ProjektEdit/" + e.id;
                    // var alt = "card" + e.title;
                    var schema = this.state.currentPage;
                    var fields = arr3[schema];
                    return (
                      <div class="col-sm">
                        <div key={e.id} className="card mb-3">
                          <div className="card-body">
                            {fields.map((field) => {
                              var typeimg = field;
                              if (typeimg !== null) {
                                
                             
                              if (
                                typeimg.includes("img") ||
                                typeimg.includes("image") ||
                                typeimg.includes("video")
                              ) {
                                var imgcontent = e[field];
                                // console.log(process.env.NODE_ENV)
                                var umgebung = "";
                                if (process.env.NODE_ENV === "production") {
                                  umgebung = "prod";
                                } else {
                                  umgebung = "dev";
                                }
                                if (
                                  imgcontent !== undefined &&
                                  imgcontent.includes(umgebung)
                                ) {
                                  return (
                                    <div key={field}>
                                      <h1 className="text-primary">
                                        Richtige DB ({umgebung})
                                      </h1>
                                      <h3 className="text-primary">{field}</h3>
                                      <div>{e[field]}</div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={field}>
                                      <h1 className="text-danger">
                                      {umgebung}
                                      </h1>
                                      <h3 className="text-danger">{field}</h3>
                                      <div>{e[field]}</div>
                                    </div>
                                  );
                                }
                              }
                            }

                              return (
                                <div key={field}>
                                  <h3>{field}</h3>
                                  <div>{e[field]}</div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="card-footer text-right">
                            <div className="btn-group">
                              <Link to={url} className="btn btn-warning">
                                Bearbeiten
                              </Link>
                              <button
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target={"#example" + index}
                              >
                                Löschen
                              </button>
                            </div>
                          </div>
                          <div
                            className="modal fade"
                            id={"example" + index}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Löschen
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  Sicher dieses Bild löschen ?
                                  <br />
                                  <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="url"
                                    value={e.title}
                                    aria-label="Bild"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    data-dismiss="modal"
                                  >
                                    Schließen
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={this.delete.bind(
                                      this,
                                      this.state.keys[index]
                                    )}
                                  >
                                    Löschen
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  })}
                {/* {ueberuns &&
                  ueberuns.map((e, index) => {
                    var url = "/ProjektEdit/" + e.key;
                    var alt = "card" + e.title;
                    return (
                      <MDBCol md="4">
                        <div key={uuid()} className="card mb-3">
                          <img
                            className="card-img-top"
                            src={e.imageUrl}
                            alt={alt}
                          />
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor={e.imageUrl}>Bild Url</label>
                              <input
                                type="text"
                                className="form-control"
                                id={uuid()}
                                aria-describedby="bildHelp"
                                value={e.imageUrl}
                                readOnly
                                placeholder="Bild Url"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor={e.videoUrl}>Video Url</label>
                              <input
                                type="text"
                                className="form-control"
                                id={uuid()}
                                aria-describedby="bildHelp"
                                value={e.videoUrl}
                                readOnly
                                placeholder="Video Url"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor={e.content}>Inhalt</label>
                              <textarea
                                className="form-control"
                                id={e.content}
                                value={e.content}
                                readOnly
                                rows="3"
                                placeholder="Inhalt"
                              ></textarea>
                            </div>
                            <Link to={url}>
                              <button className="btn btn-warning">
                                Bearbeiten
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn btn-red"
                              data-toggle="modal"
                              data-target={"#example" + index}
                            >
                              Löschen
                            </button>
                            <div
                              className="modal fade"
                              id={"example" + index}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Löschen
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    Sicher dieses Bild löschen ?
                                    <br />
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      placeholder="url"
                                      value={e.key}
                                      aria-label="Bild"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-dismiss="modal"
                                    >
                                      Schließen
                                    </button>
                                    <button
                                      onClick={this.delete.bind(
                                        this,
                                        this.state.keys[index]
                                      )}
                                      className="btn btn-red"
                                    >
                                      Löschen
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </MDBCol>
                    );
                  })} */}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      <div>{/* <Loading /> */}</div>;
    }
  }
}
export default ProjektOrganizer;
