import React from 'react'
import "./Cookies.scss";

class Cookies extends React.Component {


  // componentDidMount() {
  //   console.log("cookies mounted");
  //     this.checkCookie();
  // }

  // checkCookie() {
  //   if (decodeURIComponent(document.cookie).includes("statisticCookie")) {
  //     this.removeCookieConsent();
  //   } else {
  //     document.body.style.overflowY = "hidden"; /* Set to "auto" to navigate on the site*/

  //   }
  //   let eCookie = document.getElementById("cookie-wrapper");
  //   if (eCookie !== null) {
  //     eCookie.style.display = "block";
  //   }
  // }

  render() {
    function toggleDetails() {
      if (document.getElementById("details-content").style.display !== "block") {
        document.getElementById("popup-cookie_hint").style.top = "5%";
        document.getElementById("details-content").style.display = "block";
        document.getElementById("details-header").innerHTML = document.getElementById("details-header").innerHTML.replace("ein", "aus");
        document.getElementById("details-caret").className = "arrow arrowUp"
      } else {
        document.getElementById("details-content").style.display = "none";
        document.getElementById("details-header").innerHTML = document.getElementById("details-header").innerHTML.replace("aus", "ein");
        document.getElementById("details-caret").className = "arrow arrowDown"
        document.getElementById("popup-cookie_hint").style.top = "10%";
      }
    }

    function setCookie(exDays) {
      let exDate = new Date();
      exDate.setTime(exDate.getTime() + exDays * 24 * 60 * 60 * 1000);
      if (document.getElementById("statisticCookies").checked) {
        document.cookie = "statisticCookie=true; expires=" + exDate.toUTCString() + "; path=/";
      } else {
        document.cookie = "statisticCookie=false; expires=" + exDate.toUTCString() + "; path=/";
      }
      removeCookieConsent();
    }


    function setAllCookies(exDays) {
      let exDate = new Date();
      exDate.setTime(exDate.getTime() + exDays * 24 * 60 * 60 * 1000);
      document.cookie = "statisticCookie=true ; expires=" + exDate.toUTCString() + "; path=/";
      removeCookieConsent();
    }

    function removeCookieConsent() {
      document.body.style.overflowY = "auto";
      let eCookie = document.getElementById("cookie-wrapper");
      if (eCookie !== null) {
        eCookie.style.display = "none";
        // eCookie.parentElement.removeChild(eCookie);
      }
    }

    // function getCookie(pName) {
    //   var name = pName + "=";
    //   var decodedCookie = decodeURIComponent(document.cookie);
    //   var ca = decodedCookie.split(';');
    //   for (var i = 0; i < ca.length; i++) {
    //     var c = ca[i];
    //     while (c.charAt(0) === ' ') {
    //       c = c.substring(1);
    //     }
    //     if (c.indexOf(name) === 0) {
    //       return c.substring(name.length, c.length);
    //     }
    //   }
    //   return "";
    // }

    return (
      <div className="cookie-container" id="cookie-wrapper">
        <div id="popup-cookie_hint" >
          <div id="popup-title"><h1 >Cookie-Einstellungen</h1></div>
          <div id="popup-content">
            <span>
              <div id="popup-message">

                <p>Mit Hilfe von Cookies möchten wir den Besuch auf unserer Website erleichtern und benutzerfreundlicher machen. Mit Ausnahme der zwingend notwendigen Cookies können Sie selbst entscheiden, ob und welche Cookies Sie zulassen möchten. Sie können Ihre einmal erteilte Einwilligung jederzeit mit Wirkung für die Zukunft kostenfrei widerrufen. Weitere Informationen finden Sie in unseren <a className="intern" href="https://www.arburg.com/de/meta-navigation/datenschutz/">Datenschutzrichtlinien</a>.</p>
                <ul>
                  <li>
                    <input type="checkbox" checked={true} onChange={() => ""} disabled={false} id="requiredCookies"></input>
                    <label htmlFor="requiredCookies">Notwendig</label>
                  </li>
                  <li>
                    <input type="checkbox" id="statisticCookies"></input>
                    <label htmlFor="statisticCookies" className="optional">Statistik</label>
                  </li>
                </ul>
                <h3 id="details-header" onClick={() => toggleDetails()}>Details einblenden <i id="details-caret" className="arrow arrowDown"></i></h3>
                <ul id="details-content"> 	<li>Notwendig<br /><p>Diese Cookies sind technisch zur Funktion unserer Website erforderlich und können auf unserer Website nicht deaktiviert werden. Sie ermöglichen beispielsweise sicherheitsrelevante Funktionalitäten.</p></li> 	<li>Statistik<br /><p>Diese Cookies erfassen Informationen über die Nutzungsweise unserer Website, z.B. welche Seiten am häufigsten besucht werden und wie sich Nutzer auf der Website bewegen. Die Cookies ermöglichen uns die statistische Auswertung der Websitenutzung anhand von anonymisierten Daten. Personenbezogene Daten werden nicht gespeichert. Die Cookies werden ausschließlich dazu verwendet, die Benutzerfreundlichkeit zu steigern und unsere Website gezielter auf die Nutzer und ihre Interessen abzustimmen.</p></li> </ul>

              </div>
              <div id="popup-buttons" >
                <a id="popup-button-save-selection" className="button" href={window.location.href} onClick={() => setCookie(1)}>Auswahl speichern</a>
                <a id="popup-button-save-all" className="button" href={window.location.href} onClick={() => setAllCookies(1)}>ALLE COOKIES AKZEPTIEREN</a>
              </div>
            </span>
          </div>
        </div>
      </div>
    )
  }
}
export default Cookies
