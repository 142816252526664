import React from "react";
import { fb } from "../../../../../firebase";
import ReactHtmlParser from "react-html-parser";
import "./ContentDetails.scss";

class ContentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.unsubscribe = null;
    this.state = {
      beruf: [],
      url: "",
      key: "",
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const beruf = [];
    var imageUrl = "";
    querySnapshot.forEach((doc) => {
      if (doc.data().id === this.state.key) {

        const { content, content2, title, imgComic, imageUrlDev, imageUrlProd, titellang, linkBewerbung } = doc.data();
        if (process.env.NODE_ENV === "production") {
          imageUrl = imageUrlProd;
        } else {
          imageUrl = imageUrlDev;
        }
        beruf.push({
          key: doc.id,
          title,
          content,
          content2,
          imgComic,
          imageUrl,
          titellang,
          linkBewerbung
        });
      }
    });

    this.setState({
      beruf,
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);

    var url = window.location.href;
    var laenge = url.search("Karriere/") + 9;
    var key = url.slice(laenge);

    this.setState({ key });
    this.setState({ url });
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    
    return (
      <div>
        {this.state.beruf.map((info, index) => {
          return (
            <div key={`${info.title}-${index}`} className="container">
              <br />
              {/* {console.log(info)} */}
              {info.titellang ?
                (<> <div className="title">
                      <h1 className="titletxt">{info.titellang}</h1>
                    </div></>):
                ( <><div className="title">
                      <h1 className="titletxt">{info.title}</h1>
                    </div></>)}
              {/* <div className="title">
                <h1 className="titletxt">{info.title}</h1>
              </div> */}
              <div className="content_karr">
                <img className="imgComic" src={info.imageUrl} alt={info.title} />
                {ReactHtmlParser(info.content)}
                <br />
                <div className="details_kar">
                  {ReactHtmlParser(info.content2)}

                </div>
                <br />
              </div>

              {info.linkBewerbung ? (
                <div>
                  <a href={info.linkBewerbung}>
                    <input className="ButtonBewerbung" type="submit" value="Bewirb Dich jetzt!" />
                  </a>
                </div>
              ) : (
                <div>

                </div>
              )}

              <div className="button_ausb">
                <a href="/#/Karriere"><input type="button" className="btn-zurueck btn btn-primary" value="Zurück" /> </a>
              </div>
              <br /><br /><br />
            </div>
          );
        })}
      </div>
    );
  }
}
export default ContentDetails;
