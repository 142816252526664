/**
 * @file: ImagePopup.jsx
 * @description: A component for Images, which contains a popup toggle
 */

import { useState } from "react"
import "./ImagePopup.scss"

export default function ImagePopup(props) {
    let [popupStatus, setPopupStatus] = useState(false);
    function Image() {
        return (
            <img
            className={props.className}
            src={props.src}
            alt={props.alt}
            onClick={() => {setPopupStatus(true)}}
            id={props.id} />
        )
    }
    function Popup() {
        if(popupStatus === true) {
            return (
                <div className="image-popup"  onClick={() => {setPopupStatus(false)}}>
                    <div className="popup-content">
                        <div>
                            <img
                            width={600}
                            src={props.src}
                            alt={props.alt}
                            id={props.id} />
                        </div>
                        <div>
                            <button onClick={() => {setPopupStatus(false)}}>&#10005;</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }
    return (
        <div>
            <Image />
            <Popup />
        </div>
    )
}