/**
 * @file: Sidebar.jsx
 * @description: contains the sidebar with links
 */

// Styling
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import './Sidebar.scss';

// Assets
import Facebook from "../../../ressources/Footer/facebook_weiß.png";
import Insta from "../../../ressources/Footer/Instagramm_weiß.png";
import YouTube from "../../../ressources/Footer/Youtube_weiß.png";
import Twitter from "../../../ressources/Footer/twitter_weiß.png";
import LinkedIn from "../../../ressources/Footer/linkedin_weiß.png";
import PDFGen from '../PDF/pdfGen';

export default function Sidebar(props) {
    return (
        <div className="jobportal-sidebar">
            <Link to="/Jobboard/hs" title="Zurück zur Startseite"
            onClick={() => {window.scroll({top: 0, left: 0})}}>&#10096;&#10096;</Link>
            <PDFDownloadLink document={
                <PDFGen
                title={props.title} 
                shortDescHeader={props.shortDescHeader}
                shortDesc={props.shortDesc}
                b1Title={props.b1Title}
                b1Content={props.b1Content}
                b2Title={props.b2Title}
                b2Content={props.b2Content}
                endB1Title={props.endB1Title}
                endB1Content={props.endB1Content}
                endB2Title={props.endB2Title}
                endB2Content={props.endB2Content}
                country={props.country}
                place={props.place}
                department={props.department} 
                />
                } fileName={props.title}>
                {({ loading }) => 
                    loading ? (
                        <button class="download-pdf-btn">&#10140;</button>
                    ) : (
                        <button class="download-pdf-btn">&#10140;</button>
                )}
            </PDFDownloadLink>
            <a
            href="https://de-de.facebook.com/arburgworld/"
            target="_blank"
            rel="noreferrer"
            title="Facebook"><img src={Facebook} width={40}/></a>
            <a
            href="https://www.instagram.com/arburg_rookies/?hl=de"
            target="_blank"
            rel="noreferrer"
            title="Instagram"><img src={Insta} width={40}/></a>
            <a
            href="https://www.youtube.com/user/ARBURGofficial"
            target="_blank"
            rel="noreferrer"
            title="YouTube"><img src={YouTube} width={40}/></a>
            <a
            href="https://twitter.com/arburgworld"
            target="_blank"
            rel="noreferrer"
            title="Twitter"><img src={Twitter} width={40}/></a>
            <a
            href="https://www.linkedin.com/company/arburg-gmbh-co-kg/mycompany/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"><img src={LinkedIn} width={40}/></a>
        </div>
    )
}