import React from 'react'
import {fb} from '../../firebase';
import BlogEntries from '../../components/Projektgruppen/BlogEntries/BlogEntries';


//SCSS
import './Projekt.scss'


class Projekt extends React.Component {
  
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          projektgruppen: []
        };
      }
      onCollectionUpdate = (querySnapshot) => {
        const projektgruppen = [];
        var videoUrl = "";
        querySnapshot.forEach((doc) => {
          if (doc.data()._fl_meta_.schema === "projektgruppen") {
            const { title, imageUrl, summary, videoUrlProd, videoUrlDev, tags, order } = doc.data();
            if (process.env.NODE_ENV === "production") {
              videoUrl = videoUrlProd;
            } else {
              videoUrl = videoUrlDev;
            }
            projektgruppen.push({
                key: doc.id,
                doc, // DocumentSnapshot
                title, 
                imageUrl, 
                videoUrl,  
                tags,
                summary,
                order,
              });
            }
            });
            this.setState({
              projektgruppen
           });
          }
        
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
          }
          componentWillUnmount() {
            this.unsubscribe()
          }

          

 render(){
    return(
      <div>
              <div className="container">
                <h1 className="my-4">Unsere Projektgruppen </h1>
              </div>
              <div className="container">
                <BlogEntries projektgruppen={this.state.projektgruppen}/>
              </div>
     </div>
            
    )
  }
}
export default Projekt