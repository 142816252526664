import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { v4 as uuid } from "uuid";
import "./Wartung.scss";
import Countdown from "../Countdown/Countdown";
import { fb } from "../../firebase";
import CloseWartung from "../Countdown/CloseWartung";

const CountdownShow = (e) => {
  if (e.show === true) {
    return <h2 className="text-primary">ein paar Tagen</h2>;
  } else {
    return (
      <div>
        <Countdown date={e.date} />
        {/* <Countdown date={'2021-06-27T16:51:00'}/> */}
      </div>
    );
  }
};

class Wartung extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("Countdown");
    this.unsubscribe = null;
    // this.unsubscribe2 = null;
    this.state = {
      date: [],
      dDate: [],
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    // const date = [];
    querySnapshot.forEach((doc) => {
      var { day, month, year, hours, min, sec } = doc.data();
      this.setState({ day, month, year, hours, min, sec });
      //2021-07-01T00:00:00
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      var date =
        year + "-" + month + "-" + day + "T" + hours + ":" + min + ":" + sec;
      var dDate = new Date(month + "." + day + "." + year);
      this.setState({ dDate: dDate });
      this.setState({
        date: date,
      });
    });
  };
  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div>
        <div className="body">
          <div className="loading">
            <span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <div>
              <span></span>
              <div></div>
            </div>
          </div>
        </div>
        <div className="longfazers">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="container Wartung">
          <h1 className="text-center">
            <img
              alt="Arburg Ausbildung"
              className="LogoArburg"
              src="https://upload.wikimedia.org/wikipedia/commons/5/57/Logo_Arburg.svg"
            ></img>
          </h1>
          <br />
          <h1 className="text-center">Azubi Homepage</h1>
          <br />

          <div className="text-center">
            {/* <h2 className="text-primary">Aufgrund von Wartungsarbeiten ist die Seite geschlossen.</h2>
          <h2 className="text-primary">Wir sind bald wieder für Sie erreichbar.</h2> */}
            <h2 className="text-primary">Wir sind in </h2>

            <CloseWartung />
            <CountdownShow dDate={this.state.dDate} date={this.state.date} />
            <h2 className="text-primary">
              mit unserer neuen Homepage für Sie erreichbar.
            </h2>
            <br />
            {/* <br /> */}
            <br />
            <h2 className="text-primary">
              Hier geht es zur alten Azubi-Homepage:{" "}
            </h2>
            <u>
              <i>
              <a href="https://www.arburg.com/ausbildung" class="link-primary old_page" target="_blank" rel="noreferrer">
                <h2 alt="alte Homepage">arburg.com/ausbildung</h2>
                <br/>
                <br/>
              </a>
              </i>
            </u>
          </div>
        </div>
        <br/>
        <br/>
      </div>
    );
  }
}
export default Wartung;
