import React from "react";
import Sidebar from "../Sidebar";
import BackendMenuToggle from '../BackendMenuToggle'
import axios from "axios";

import './CheckIn.scss';
var SheetID = '5770259320858500';
var apiUrl = 'https://api.smartsheet.com/2.0/sheets/5770259320858500';
var config = {
  headers: {
    'Authorization': 'Bearer xdqpa5y119ocwvkamah9fo4ew5'
  }
};

class CheckIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: undefined,
      selectedRow: undefined
    };
  }

  async componentDidMount() {

    const res = await axios.get(apiUrl, config);

    this.setState({
      object: res.data,
    })

    // console.log(this.state.object);
  }

  reloadWindow(){
    window.location.reload();
  }

  checkin = (row) => {
    // console.log(row);
    var data;

    if (row.cells[11].value && row.cells[12].value) {
      //DO NOTHING
    }
    else if (row.cells[11].value) {
      data = JSON.stringify([{ "id": row.id, "cells": [{ "columnId": 8329698441750404, "value": new Date().toLocaleTimeString() }] }]);
    }
    else {
      data = JSON.stringify([{ "id": row.id, "cells": [{ "columnId": 3826098814379908, "value": new Date().toLocaleTimeString() }] }]);
      // console.log('3')
    }

    var config = {
      method: 'put',
      url: `https://api.smartsheet.com/2.0/sheets/${SheetID}/rows`,
      headers: {
        'Authorization': 'Bearer xdqpa5y119ocwvkamah9fo4ew5',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.errorCode) {
          //NACHRICHT VERBINDUNG NICHT MÖGLICH
        }
        else {
          window.location.reload();
          // this.state.reloadWindow();
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  deleteRow = () => {
    if (this.state.selectedRow !== undefined) {

      var config = {
        method: 'delete',
        url: `https://api.smartsheet.com/2.0/sheets/${SheetID}/rows?ids=${this.state.selectedRow.id}`,
        headers: {
          'Authorization': 'Bearer xdqpa5y119ocwvkamah9fo4ew5'
        }
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          // IF ERROR 0000 -> Seite Refresh ?

          if (response.errorCode) {
            //NACHRICHT VERBINDUNG NICHT MÖGLICH
          }
          else {
            // this.state.reloadWindow();
            window.location.reload();
          }

        })
        .catch(function (error) {
          console.log(error);

        });

    }

  }

  render() {

    if (this.state.object !== undefined) {

      return (

        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div style={{ overflowX: "scroll", width: "96%", marginLeft: "2%" }}>

              <div className="div-btn-anlegen">
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#add">
                  +
                </button>
                <button
                onClick={this.reloadWindow}
                  className="btn btn-primary">
                  reload page
                </button>
              </div>


              <div>
                Anzahl der Anmeldungen insgesammt: <b>{this.state.object.totalRowCount}</b>
                <br />
                {this.state.object.columns[6].options.map(item => {
                  return (
                    <div>
                      Anzahl der Anmeldungen am <b>{item}</b>:
                      <b>
                        {/* {"   " + this.state.object.rows.filter(r => r.cells.find(c => c.columnId === "8154170141239172").value === item).length} */}
                      </b>
                    </div>
                  )
                })}
            Eingecheckt:
          </div>

              <table className="table">
                <thead>
                  <tr>
                    {this.state.object.columns.map(item => {

                      if (item.id !== '6743950949148548' && item.id !== '2240351321778052' && item.id !== '4471626526943108') {
                        return (
                          <th key={item.id}>
                            {item.title}
                          </th>
                        )
                      }
                      return <></>;

                    })}
                    <th>
                      Check
                  </th>
                    <th>
                      Bearbeiten
                  </th>
                  </tr>
                </thead>

                <tbody>

                  {this.state.object.rows.map(item => {
                    return (
                      <tr>
                        {
                          item.cells.map(cell => {
                            if (cell.columnId === "3650570513868676") {
                              return (
                                <td key={cell.id}>
                                  <b>{cell.value}</b>
                                </td>
                              )
                            } if (cell.columnId !== '6743950949148548' && cell.columnId !== '2240351321778052' && cell.columnId !== '4471626526943108') {
                              return (
                                <td key={cell.id}>
                                  {cell.value}
                                </td>
                              )
                            }
                            return <></>;
                          })
                        }
                        <td>
                          <button className="btn btn-primary" onClick={() => {
                            this.checkin(item);
                          }}>
                            Check
                          </button>
                        </td>

                        <td key={item.id}>
                          <button
                            type="button"
                            onClick={() => { this.setState({ selectedRow: item }) }}
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#bearbeiten"
                            value={item.id}>

                            Bearbeiten

                          </button>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>

            <div className="modal fade" id="add" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Anlegen</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.reloadWindow}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <iframe title="smartsheet-form" width="470" height="700" src="https://app.smartsheet.com/b/form/f307a1fec9cb47a4b486e101596e0bb9"></iframe>
                  </div>
                </div>
              </div>
            </div>


            {/* ------------------------------------------------------------------------------------------------- */}
            {/* BEARBEITEN POPUP */}
            <div className="modal fade" id="bearbeiten" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Bearbeiten</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <table>
                      {this.state.selectedRow && this.state.object.columns.map((col, index) => {
                        if (col.title === "Anmelde-ID") {
                          return (
                            <tr>
                              <th>{col.title}</th>
                              <td>{this.state.selectedRow.cells[index].value}</td>
                            </tr>
                          )
                        }
                        else if (col.title === "Uhrzeit" || col.title === "Erstellt") {
                          return (
                            <>
                            </>

                          )
                        }
                        else {
                          return (
                            <tr>
                              <th>{col.title}</th>
                              <td><input type="text" value={this.state.selectedRow.cells[index].value}></input></td>
                            </tr>
                          )
                          
                        }
                        
                      })}
                      

                    </table>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Schließen</button>
                    <button type="button" onClick={this.deleteRow} className="btn btn-danger">Löschen</button>
                    <button type="button" onClick="checkIn" className="btn btn-primary">Änderungen speichern</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      )
    }

    //LOADING SCREEN
    else {
      return (
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid">
              <p>loading...</p>
            </div>
          </div>
        </div>
      )
      
    }
    
    
  }
}

export default CheckIn;