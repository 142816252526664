import React from "react";
// import firebase from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from '../BackendMenuToggle'


class Views extends React.Component {

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
        <BackendMenuToggle/>
          <div className="container-fluid">
            
          </div>
        </div>
      </div>
    );
  }
}
export default Views;
