/**
 * @file: Jobboard.jsx
 * @description: Jobboard für Langzeitpraktikanten
 */

// packages
import React from 'react';
import { useState, useEffect } from "react";
import Jobcontainer from "./jobcontainer/jobcontainer";
import { Link, useLocation } from 'react-router-dom';
import { fb } from '../../firebase';

// styling
import './Jobboard.scss'
import Footer from './footer/footer';
import Navigation from './navigation/navigation';

export default function Jobboard() {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

      let [praktiData, setPraktiData] = useState([]);
    
      let [queryType, setQueryType] = useState("none");
      let [queryDir, setQueryDir] = useState("none");
      let [queryString, setQueryString] = useState("/Jobboard");
      let queryParams = useQuery();

      useEffect(() => {
        let getPraktiData = fb.firestore().collection('studienpraktika');

        document.title = 'Jobboard';
      
        getPraktiData.onSnapshot((data) => {
            let content = []
            data.forEach((doc) => {
                content.push({
                    id: doc.id,
                    title: doc.data().data.name,
                    department: doc.data().data.department,
                    content: doc.data().data.content,
                    type: doc.data().data.type,
                    direction: doc.data().data.direction,
                    place: doc.data().data.place,
                    archive: doc.data().archive
                })
            })
            const typeOrder = { "abschlussarbeit": 0, "praktikum": 1, "technikerarbeit": 2, "umschulung": 3, "werkstudierende": 4 };
            console.log(content)
            function normalizeType(type) {
                return type.toLowerCase();
            }
            content.sort((a, b) => {
                const normalizedTypeA = normalizeType(a.type);
                const normalizedTypeB = normalizeType(b.type);
                const typeComparison = typeOrder[normalizedTypeA] - typeOrder[normalizedTypeB];
                if (typeComparison !== 0) {
                    return typeComparison;
                } else {
                    return a.title.localeCompare(b.title);
                }
            });
            console.log(content)
            setPraktiData(content)
          })
      }, [])

    function InitiativStellen() {
        let initiativData = [
            {type: "Praktikum", name: "Inititativbewerbung Praktikum", department: "ARBURG", place: "Loßburg"},
            {type: "Praktikum", name: "Inititativbewerbung Abschlussarbeit", department: "ARBURG", place: "Loßburg"},
            {type: "Praktikum", name: "Inititativbewerbung Technikerarbeit", department: "ARBURG", place: "Loßburg"},
            {type: "Praktikum", name: "Inititativbewerbung Umschulung", department: "ARBURG", place: "Loßburg"},
            // {type: "Praktikum", name: "Inititativbewerbung Werkstudierende", department: "ARBURG", place: "Loßburg"}
        ]
                if(queryParams.get("type") == null || queryParams.get("type") == "none") {
                    return (
                        <>
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_01">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Praktikum</span>
                            </div>
                            <div className="job-container-section">
                                <span>Praktikum</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_01">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Abschlussarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>Abschlussarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_53">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Technikerarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>Technikerarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_65">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Umschulung</span>
                            </div>
                            <div className="job-container-section">
                                <span>Umschulung</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        {/* <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2023_62">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Werkstudierende</span>
                            </div>
                            <div className="job-container-section">
                                <span>Werkstudierende</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a> */}
                        </>
                    )
                } else if("praktikum" == queryParams.get("type").toLowerCase()) {
                    return (
                        
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_01">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Praktikum</span>
                            </div>
                            <div className="job-container-section">
                                <span>Praktikum</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                    )
                } else if("abschlussarbeit" == queryParams.get("type").toLowerCase()) {
                    return (    
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_01">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Abschlussarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>Abschlussarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        )
                } else if("technikerarbeit" == queryParams.get("type").toLowerCase()) {
                    return (
                        
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_53">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Technikerarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>Technikerarbeit</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        )
                } else if("umschulung" == queryParams.get("type").toLowerCase()) {
                    return (
                        
                        <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_65">
                            <div className="job-container-section">
                                <span>Inititativbewerbung Umschulung</span>
                            </div>
                            <div className="job-container-section">
                                <span>Umschulung</span>
                            </div>
                            <div className="job-container-section">
                                <span>ARBURG</span>
                            </div>
                            <div className="job-container-section">
                                <span>Loßburg</span>
                            </div>
                        </a>
                        )
                } else if("werkstudierende" == queryParams.get("type").toLowerCase()) {
                    return (
                        <>
                        
                        </>      
                        // <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2023_62">
                        //     <div className="job-container-section">
                        //         <span>Inititativbewerbung Werkstudierende</span>
                        //     </div>
                        //     <div className="job-container-section">
                        //         <span>Werkstudierende</span>
                        //     </div>
                        //     <div className="job-container-section">
                        //         <span>ARBURG</span>
                        //     </div>
                        //     <div className="job-container-section">
                        //         <span>Loßburg</span>
                        //     </div>
                        // </a>
                        )
                }
                
                else {
                    return <></>
                }
        }

    function Jobdesc() {
        if(queryParams.get('type') == null || queryParams.get('type') == 'none') {
            if( queryParams.get("direction") == null || queryParams.get("direction") == 'none') {
                return (
                    <div className="job-description">
                        <div className="job-description-header">
                            <h4><span>/ </span>Karrierestart frei! – Lernen und arbeiten in faszinierender Umgebung</h4>
                        </div>
                        <div className="job-description-image">
                            <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2Fdfb62e0d-6e11-48a9-bb51-44b325257cd2_Start_Img.jpeg?alt=media&token=cb979474-f1c0-4bb7-bb95-472dfad947c2" alt="" />
                        </div>
                        <div className="job-description-content">
                            <p>Digitale Transformation, Big Data, Industrie 4.0 oder Smart Production – 
                                ganz ehrlich: Bei ARBURG gehört das längst zum Tagesgeschäft. So waren 
                                wir beispielsweise Branchen-Vorreiter in der Automatisierung und 
                                informationstechnischen Produktionsvernetzung.
                                Dabei lassen wir jungen Menschen viel Freiraum für eigene Ideen – 
                                die sie dann in Abschlussarbeiten, Praktika oder Technikerarbeiten verwirklichen. 
                                Willkommen in der digitalen Zukunft!
                                <br/>
                                Bei ARBURG finden Sie beides: einen anspruchsvollen und sichereren 
                                Arbeitsplatz in einem zukunftsorientierten Unternehmen gepaart mit dem hohen 
                                Freizeitwert einer faszinierenden Region. Sie treffen auf ein Arbeitsumfeld, in 
                                dem Teamgeist, gegenseitiger Respekt und beste Arbeitsbedingungen für hohe Zufriedenheit 
                                und Spaß am eigenen Tun sorgen.</p>
                        </div>
                    </div>
                    )
            } else {
                return <></>
            }
            
        } else {
            return <></>
        }
    }

    function InitiativBlock() {
        if(queryParams.get('type') == null || queryParams.get('type') == 'none') {
            if(queryParams.get("direction") == null || queryParams.get("direction") == 'none') {
                return (
                    <div className="initiativ-contact-container">
                    <div className="initiativ-contact-header">
                        <h4><span>/ </span>Nichts passendes gefunden? Initiativ als Bewerber registrieren</h4>
                    </div>
                    <div className="contact-block">
                        <div>
                            <p>
                            Sie haben bei unseren offenen Stellenangeboten aktuell nichts Passendes für sich gefunden? 
                            Wagen Sie dennoch den nächsten Schritt. Legen Sie einfach Ihr Profil inklusive Anschreiben, Lebenslauf 
                            und Zeugnissen in unserem Bewerberpool an. Damit wir Ihr Kandidatenprofil bestmöglich einordnen können ist es wichtig, 
                            dass Sie im Anschreiben konkret darstellen, was Ihren beruflichen Zielen entspricht. Nutzen Sie prägnante Schlagworte, 
                            damit wir für Sie vielleicht zukünftig doch eine geeignete Stelle finden können.
                            </p>
                        </div>
                        <div>
                            
                            <h4 className="contact-header">Fragen?</h4>
                            <div className="contact-persons">
                                <div className='contact-person-block'>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Ausbilder%2F102d0fba-f1e8-46ed-8351-b27ae8208f5d_stefanie_scheffold-tritschler.jpg?alt=media&token=193c7524-f1af-417a-8e8a-0c36fb0f40ea" width={250} alt="Stefanie Scheffold-Tritschler" />
                                    <div className="person-info">
                                        <span>Stefanie Scheffold-Tritschler</span><br/>
                                        <a href='tel:+497446333560'>Tel. 07446/33-3560</a>
                                    </div>
                                </div>
                                <div className='contact-person-block'>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Ausbilder%2F4b590597-a6d9-448f-85bb-de747476cd86_marius_singer.jpg?alt=media&token=01f61f41-0453-4d29-8951-581f92921db9" width={250} alt="Stefanie Scheffold-Tritschler" />
                                    <div className="person-info">
                                        <span>Marius Singer</span> <br/>
                                        <a href='tel:+497446334581'>Tel. 07446/33-4581</a>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <a href='mailto:HOCHSCHULKONTAKT@ARBURG.COM'>&#9993; HOCHSCHULKONTAKT@ARBURG.COM</a>
                        </div>
                    </div>
                </div>
                )
            }
            else {
                return <></>
            }
            
        } else {
            return <></>
        }
    }
    return (
        <div className="job-wrapper">
            <Navigation />
            <Jobdesc />
            <div className="job-search-options">
                <div className="job-search-header">
                    <h1>Sie suchen ...</h1>
                </div>
                <div className="job-search-input">
                    {/* <div className="input-container">
                        <label htmlFor="spec-word-input">
                            ... nach Stichwort
                        </label>
                        <input type="text" id="spec-word-input" name="spec-word-input" onChange={(e) => setQueryWord(e.target.value)} />
                    </div> */}
                    <div className="input-container">
                        <label htmlFor="spec-word-input">
                            ... nach Art
                        </label>
                        <select onChange={(e) => setQueryType(e.target.value)}>
                            <option value={"none"}>Kein Filter</option>
                            <option value={"praktikum"}>Praktikum</option>
                            <option value={"abschlussarbeit"}>Abschlussarbeit</option>
                            <option value={"technikerarbeit"}>Technikerarbeit</option>
                            <option value={"umschulung"}>Umschulung</option>
                            <option value={"werkstudierende"}>Werkstudierende</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="spec-word-input">
                            ... nach Fachrichtung
                        </label>
                        <select onChange={(e) => setQueryDir(e.target.value)}>
                            <option value={"none"}>Kein Filter</option>
                            <option value={"Technik"}>Technik</option>
                            <option value={"Informatik"}>Informatik</option>
                            <option value={"Wirtschaft"}>Wirtschaft</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <Link className="search-btn" to={`/Jobboard/hs?type=${queryType}&direction=${queryDir}`}>Stellen suchen</Link>
                    </div>
                </div>
            </div>
            <div className="job-container-wrapper">
            {/* <SpecialJob /> */}
            
            <div className="job-container-wrapper-header">
                <div className="wrapper-header-section">
                    <h3>Stellenbezeichnung</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Art</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Abteilung</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Ort</h3>
                </div>
            </div>
            {praktiData.map((index, i) => {
                 
                if(queryParams.get("type") == null && queryParams.get("direction") == null || queryParams.get("type") == "none" && queryParams.get("direction") == "none") {
                    if(index.archive === false) {
                        return (
                            <Jobcontainer key={index.id} type={index.type} link={index.id} name={index.title} department={index.department} place={index.place} />
                        )
                    } else {
                        return <></>
                    }
                } else {
                    if(index.type.toLowerCase() == queryParams.get("type").toLowerCase() || queryParams.get("type").toLowerCase() == "none") {
                        if(queryParams.get("direction") == null || queryParams.get("direction") == "none") {
                            if(index.archive === false) {
                                return (
                                    <Jobcontainer key={index.id} type={index.type} link={index.id} name={index.title} department={index.department} place={index.place} />
                                )
                            } else {
                                return <></>
                            }
                        } else if(index.direction.toLowerCase() == queryParams.get("direction").toLowerCase()) {
                            if(index.archive === false) {
                                return (
                                    <Jobcontainer key={index.id} type={index.type} link={index.id} name={index.title} department={index.department} place={index.place} />
                                )
                            } else {
                                return <></>
                            }
                        } else {
                            return <></>
                        }
                    } else{
                        return <></>
                    }
                }
            })}
            <InitiativStellen />
            </div>
    
            <InitiativBlock />

            <Footer />
        </div>
    )
}