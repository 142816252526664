/**
 * @file: footer.jsx
 * @description: contains the footer of the jobboard app
 */

import { Link } from "react-router-dom";

import './footer.scss'

// assets
import Facebook from "../../../ressources/Footer/facebook_weiß.png";
import Insta from "../../../ressources/Footer/Instagramm_weiß.png";
import YouTube from "../../../ressources/Footer/Youtube_weiß.png";
import Twitter from "../../../ressources/Footer/twitter_weiß.png";
import LinkedIn from "../../../ressources/Footer/linkedin_weiß.png";

export default function AusbildungFooter() {
    return (
        <div className="jobboard-footer">
            <div className="footer-container">
                <div className="container-section basic-links">
                    <a
                    target="_blank" 
                    href="https://arburg.com">ARBURG Homepage</a> | 
                    <a 
                    target="_blank"
                    href="https://www.google.de/maps/place/ARBURG+GmbH+%2B+Co+KG/@48.4060022,8.4522663,16.29z/data=!4m5!3m4!1s0x4790caab4fa48bcf:0x3845a08b728566c8!8m2!3d48.4083089!4d8.4530112">Anfahrt</a> | 
                    <a 
                    target="_blank"
                    href="https://www.arburg.com/de/unternehmen/">Unternehmen</a> 
                </div>
                <div className="container-section">
                    <div className="other-links">
                        <h4>INFORMATIONEN</h4>
                        <Link to="/Jobboard"
                        onClick={() => {window.scroll({top: 0, left: 0})}}>Home</Link>
                        <Link to="/Jobboard?type=ausbildung"
                        onClick={() => {window.scroll({top: 0, left: 0})}}>Ausbildung</Link>
                        <Link to="/Jobboard?type=dhbw"
                        onClick={() => {window.scroll({top: 0, left: 0})}}>DHBW-Studium</Link>
                        <Link to="/Jobboard?type=schulpraktika"
                        onClick={() => {window.scroll({top: 0, left: 0})}}>Schulpraktika</Link>
                    </div>
                    <div className="other-links">
                        <h4>RECHTLICHES</h4>
                        <Link to="/Datenschutz">Datenschutz</Link>
                        <Link to="/Impressum">Impressum</Link>
                    </div>
                </div>
                <div className="container-section">
                    <p>&copy; {new Date().getFullYear()} ARBURG GmbH + Co. KG - All rights reserved</p>
                </div>
            </div>
            <div className="footer-container social">
                <a
                href="https://www.instagram.com/arburg_rookies/?hl=de"
                target="_blank"
                rel="noreferrer"><img src={Insta} width={40} /></a>
                <a 
                href="https://de-de.facebook.com/arburgworld/"
                target="_blank"
                rel="noreferrer"><img src={Facebook} width={40} /></a>
                <a 
                href="https://twitter.com/arburgworld"
                target="_blank"
                rel="noreferrer"><img src={Twitter} width={40} /></a>
                <a 
                href="https://www.youtube.com/user/ARBURGofficial"
                target="_blank"
                rel="noreferrer"><img src={YouTube} width={40} /></a>
                <a 
                href="https://www.linkedin.com/company/arburg-gmbh-co-kg/mycompany/"
                target="_blank"
                rel="noreferrer"><img src={LinkedIn} width={40} /></a>
            </div>
        </div>
    )
}