/**
 * @file: jobcontainer.jsx
 * @description: the containers for the Jobboard
 */

// styling
import { Link } from 'react-router-dom'
import './jobcontainer.scss'

export default function Jobcontainer(props) {
    return (
        <Link className="job-container" to={`/jobboard/hs/${props.link}`}
        onClick={() => {window.scroll({top: 0, left: 0})}}>
            <div className="job-container-section">
                <span>{props.name}</span>
            </div>
            <div className="job-container-section">
                <span>{props.type}</span>
            </div>
            <div className="job-container-section">
                <span>{props.department}</span>
            </div>
            <div className="job-container-section">
                <span>{props.place}</span>
            </div>
        </Link>
    )
}