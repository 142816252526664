import React from 'react'
import {fb} from '../../firebase';
import ReactHtmlParser from 'react-html-parser';


//SCSS
import './FAQ.scss';


class FAQPage extends React.Component {
  
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          faq: [],

        };
      }
      onCollectionUpdate = (querySnapshot) => {
          
        const faq = [];  
        querySnapshot.forEach((doc) => {
          if (doc.data()._fl_meta_.schema === "faq") {
            const { frage, antwort, order } = doc.data();

            faq.push({
              key: doc.id, 
              doc, //DocumentSnapshot
              frage,
              antwort,
              order,
            });
          }
          
            });
            
          //   this.setState({
          //     faq,
          //  });
          // }

          this.setState( (prevState) => {

            return {
              ...prevState,
              faq: faq.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 0;
                }
              })
            }
      
          })
        }
        
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
          }
          componentWillUnmount() {
            this.unsubscribe()
          }

 render(){
    return(
      <div className= "container">
        <div className="row">
        <div className="col-md-12">
          <h1 className="my-4">FAQ</h1>
          <div id="accordion">
            <div className="card mb-2">
            {this.state.faq.map((element, index) =>{
                    return (
                  <div className="accordionItem" key={`${element.frage}-${index}`}>
                    <div 
                      className="card-header text-center collapsed"
                      id={element.key}
                      data-toggle="collapse"
                      data-target={"#collapse" + element.key}
                    >
                      <button 
                        className="btn btn-link" 
                        aria-expanded="true" 
                        aria-controls={"collapse"+element.key}
                      >
                          <h3 className="TitleFAQ">{element.frage}</h3>
                      </button>
                    </div>
                    <div 
                      id={"collapse" + element.key} 
                      className="collapse" 
                      aria-labelledby={element.key} 
                      data-parent="#accordion"
                    >
                      <div className="card-body" style={{textAlign:"unset"}}>
                              {ReactHtmlParser(element.antwort)}
                      </div>
                    </div>
                    </div>
                  )
                  })}
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  }
}
export default FAQPage
//id={element.key}