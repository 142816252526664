import React from 'react'
import { fb } from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

//SCSS
import './UeberUns.scss'

class UeberUns extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            ueberuns: []
        };
    }

    onCollectionUpdate = (querySnapshot) => {
        const ueberuns = [];
        var imageUrl = "";
        var videoUrl = "";
        querySnapshot.forEach((doc) => {
            if (doc.data()._fl_meta_.schema === "ueberuns") {
                const { content, imageUrlDev, imageUrlProd, videoUrlDev, videoUrlProd } = doc.data();
                if (process.env.NODE_ENV === "production") {
                    imageUrl = imageUrlProd;
                    videoUrl = videoUrlProd;
                } else {
                    imageUrl = imageUrlDev;
                    videoUrl = videoUrlDev;
                }

                ueberuns.push({
                    key: doc.id,
                    doc,
                    content,
                    imageUrl,
                    videoUrl
                });
            }
        });
        this.setState({
            ueberuns
        });
    }
    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }
    componentWillUnmount() {
        this.unsubscribe()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {this.state.ueberuns.map((ueberuns, index) => {
                            return (
                                <div key={`${ueberuns.title}-${index}`}>
                                    <h1 className="my-4">Über Uns</h1>
                                    <div className="card-group">
                                        <div className="card blogpost">
                                            <h3 className="card-header">Projektgruppe - Internet</h3>
                                            <div className="card-body">

                                                <p className="card-text">
                                                    {ReactHtmlParser(ueberuns.content)}
                                                </p>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 ">
                                                <img width="100%" alt={ueberuns.title} src={ueberuns.imageUrl}></img>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col-12">
                                        {ueberuns.videoUrl ? (
                                            <div>
                                                <video width="100%" controls>
                                                    <source src={ueberuns.videoUrl}>
                                                    </source>
                                                </video>
                                            </div>) : (<></>)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

        )
    }
}

export default UeberUns