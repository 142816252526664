/**
 * @file: Benefit.jsx
 * @description: contains the benefits component for the app
 */

// Styling
import './Benefit.scss';

// Icons
import BarrierefreiheitSVG from '../../../ressources/Benefits/barrierefreiheit.svg';
import CoachingSVG from '../../../ressources/Benefits/coaching.svg';
import EssenszuschussSVG from '../../../ressources/Benefits/essenszuschuss.svg';
import Flexible_ArbeitszeitSVG from '../../../ressources/Benefits/flexible_arbeitszeit.svg';
import GesundheitsmanagementSVG from '../../../ressources/Benefits/gesundheitsmanagement.svg';
import GesundheitszentrumSVG from '../../../ressources/Benefits/gesundheitszentrum.svg';
import Hoher_FreizeitwertSVG from '../../../ressources/Benefits/hoher_freizeitwert.svg';
import HomeofficeSVG from '../../../ressources/Benefits/homeoffice.svg';
import Internationales_UmfeldSVG from '../../../ressources/Benefits/internationales_umfeld.svg';
import JobticketSVG from '../../../ressources/Benefits/jobticket.svg';
import KantineSVG from '../../../ressources/Benefits/kantine.svg';
import KitaSVG from '../../../ressources/Benefits/kita.svg';
import ParkplatzSVG from '../../../ressources/Benefits/parkplatz.svg';
import WeiterentwicklungSVG from '../../../ressources/Benefits/weiterentwicklung.svg';
import WohngeldzuschussSVG from '../../../ressources/Benefits/wohngeldzuschuss.svg';
import Work_Life_BalanceSVG from '../../../ressources/Benefits/work_life_balance.svg';

export default function Benefit(props) {
    switch (props.type.toLowerCase()) {
        case "barrierefreiheit": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Barrierefreiheit</span>
                    <img src={BarrierefreiheitSVG} width={70}/>
                </div>
            )       
            break;
        case "coaching": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Coaching</span>
                    <img src={CoachingSVG} width={70}/>
                </div>
            )       
        break;
        case "essenszuschuss": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Essens-zuschuss</span>
                    <img src={EssenszuschussSVG} width={70}/>
                </div>
            )       
        break;
        case "flexarb": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Flexible Arbeitszeit</span>
                    <img src={Flexible_ArbeitszeitSVG} width={70}/>
                </div>
            )       
        break;
        case "gesundmngmt": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Gesundheits-management</span>
                    <img src={GesundheitsmanagementSVG} width={70}/>
                </div>
            )
        break;
        case "gesundcent": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Gesundheits-zentrum</span>
                    <img src={GesundheitszentrumSVG} width={70}/>
                </div>
            )       
        break;
        case "freizeit": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Hoher Freizeitwert</span>
                    <img src={Hoher_FreizeitwertSVG} width={70}/>
                </div>
            )
        break;
        case "homeoffice": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Home Office</span>
                    <img src={HomeofficeSVG} width={70}/>
                </div>
            )       
        break;
        case "international": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Internationales Umfeld</span>
                    <img src={Internationales_UmfeldSVG} width={70}/>
                </div>
            )       
        break;
        case "jobticket": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Jobticket</span>
                    <img src={JobticketSVG} width={70}/>
                </div>
            )       
        break;
        case "kantine": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Kantine</span>
                    <img src={KantineSVG} width={70}/>
                </div>
            )       
        break;
        case "kita": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Kita</span>
                    <img src={KitaSVG} width={70}/>
                </div>
            )       
        break;
        case "parkplatz": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Parkplatz</span>
                    <img src={ParkplatzSVG} width={70}/>
                </div>
            )       
        break;
        case "weiter": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Weiter-entwicklung</span>
                    <img src={WeiterentwicklungSVG} width={70}/>
                </div>
            )       
        break;
        case "wohngeld": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Wohngeld-zuschuss</span>
                    <img src={WohngeldzuschussSVG} width={70}/>
                </div>
            )       
        break;
        case "work_life": 
            return (
                <div className="benefit-container">
                    <span className="benefit-tooltip">Work Life Balance</span>
                    <img src={Work_Life_BalanceSVG} width={70}/>
                </div>
            )       
        break;
    
        default:
            return <></>
            break;
    }
}