import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { auth, fb } from "../../../firebase";
import "./Login.scss";



class Login extends Component {

  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("users");
    this.state = {
      trigger: undefined,
      users: [],
      email: "",
      password: "",
      confirmed: false,
      isActive1: undefined,
      firework: false,
      currentUser: [],
      redirect: "",
    };
    this.handleAuth = this.handleAuth.bind(this);
  }

  onCollectionUpdate = (querySnapshot) => {
    const users = [];
    querySnapshot.forEach((doc) => {
      // console.log(auth.currentUser.uid)
      // if (auth.currentUser.uid === doc.data()) {
      // console.log(doc.id)
      users.push({
        key: doc.id,
        role: doc.data().role,
        confirmed: doc.data().confirmed,
      });

      this.setState({ users });
      // }
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  // state = {
  //   email: "",
  //   password: "",
  // };
  // hide = () => {
  //   console.log("HIDE");
  //   var x = document.getElementById("myDIV");
  //   if (x.style.display === "none") {
  //     x.style.display = "block";
  //   } else {
  //     x.style.display = "none";
  //   }
  // };
  signOut = () => {
    fb.firestore()
      .collection("users")
      .doc(auth.currentUser.uid)
      .update({ signedIn: false })
      .then(function () {
        console.log("logout");
        window.location.href = "/#/signin";
        auth.signOut();
      })
      .catch(function (error) {
        console.log("User not loged out", error);
      });
    // return(<Redirect to="/signin" ></Redirect>)
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    if (e.target.value === "firework" || e.target.value === "Firework") {
      this.setState({ firework: true })
    }
    else {
      this.setState({ firework: false })
    }

    e.preventDefault();
  };

  handleAuth = (e) => {
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(function (result) {
        fb.firestore()
          .collection("users")
          .doc(result.user.uid)
          .get()
          .then((c) => {
            if (c.data().deleted === true) {
              // fb.firestore().collection('users').doc(result.user.uid).delete();
              // console.log(result.user.uid);
              fb.firestore()
                .collection("users")
                .doc(result.user.uid)
                .delete()
                .then(() => {
                  console.log("Document successfully deleted!");
                  auth.currentUser
                    .delete()
                    .then(function (succeed) {
                      alert("Der User wurde gelöscht");
                      window.location.href = "/#/signin";
                      auth.signOut();
                    })
                    .catch(function (error) {
                      console.log("User not deleted", error);
                    });
                })
                .catch((error) => {
                  console.error("Error removing document: ", error);
                });
            } else {
              if (
                c.data().confirmed === true &&
                result.user.emailVerified === true
              ) {
                // console.log(result.user.emailVerified)
                // this.setState({currentUser: result.user})
                var myTimestamp = auth.currentUser.metadata.lastSignInTime;
                var myCreatedAt = auth.currentUser.metadata.creationTime;
                // console.log(auth.currentUser.metadata.creationTime);

                fb.firestore()
                  .collection("users")
                  .doc(auth.currentUser.uid)
                  .update({ signedIn: true });
                fb.firestore()
                  .collection("users")
                  .doc(auth.currentUser.uid)
                  .update({ lastlogin: myTimestamp });
                fb.firestore()
                  .collection("users")
                  .doc(auth.currentUser.uid)
                  .update({ createdAt: myCreatedAt });


            //Redirect nach dem anmelden 
                // if (c.data().role === "1") {
                //   // console.log("Role 1");
                //   window.location.href = "/#/Infoordner";
                // } else if (c.data().role === "2") {
                //   // console.log("Role 2");
                //   window.location.href = "/#/Backend";
                // }

                window.location.href = "/#";

              } else {
                if (result.user.emailVerified === true) {
                  fb.firestore()
                    .collection("users")
                    .doc(result.user.uid)
                    .update({ confirmed: true });

                  auth
                    .sendPasswordResetEmail(result.user.email)
                    .then(function () {
                      // Email sent.

                      console.log("email send");
                    })
                    .catch(function (error) {
                      // An error happened.
                      console.log("email not send", error);
                    });

                  fb.firestore()
                    .collection("users")
                    .doc(auth.currentUser.uid)
                    .update({ passwordReset: true });
                  fb.firestore()
                    .collection("users")
                    .doc(auth.currentUser.uid)
                    .update({ signedIn: false });

                  alert(
                    "Bitte ändern Sie Ihr Passwort. \nE-Mail wurde gesendet."
                  );

                  fb.firestore()
                    .collection("users")
                    .doc(auth.currentUser.uid)
                    .update({ signedIn: false })
                    .then(function () {
                      console.log("logout");
                      window.location.href = "/#/signin";
                      auth.signOut();
                    })
                    .catch(function (error) {
                      console.log("User not loged out", error);
                    });
                } else {
                  // alert("E-Mail noch nicht bestätigt");
                  if (
                    window.confirm(
                      "E-Mail noch nicht bestätigt. \nSoll die E-Mail erneut gesendet werden ?"
                    )
                  ) {
                    // console.log("bestätigt")
                    auth.currentUser
                      .sendEmailVerification()
                      .then(function () {
                        console.log("email send");
                      })
                      .catch(function (error) {
                        console.log("email not send", error);
                      });
                  } else {
                    // console.log("abbrechen")
                  }
                  fb.firestore()
                    .collection("users")
                    .doc(auth.currentUser.uid)
                    .update({ signedIn: false })
                    .then(function () {
                      console.log("logout");
                      window.location.href = "/#/signin";
                      auth.signOut();
                    })
                    .catch(function (error) {
                      console.log("User not loged out", error);
                    });
                }
                // auth.signOut();
              }
            }
          });
      })
      .catch(function (error) {
        alert(error.message);
        // console.log(error.message)
        // return error;
      });
  };

  handleSubmit = (e) => {
    // this.setState({ email: "Marcooehrle01@gmail.com", password: "Test1234" });

    this.handleAuth();

    // console.log(auth.currentUser.emailVerified)

    e.preventDefault();
    this.setState({ email: "" });
    this.setState({ password: "" });
  };

  render() {
    const Firework = (
      <div class="pyro">
        <div class="before"></div>
        <div class="after"></div>
      </div>
    );


    if (auth.currentUser !== null) {
      // return this.state.redirect !== "" ? <Redirect to={`/${this.state.redirect}`} /> : <></>;
      // console.log(auth.currentUser.emailVerified)
      return <Redirect to="/Backend" />;
    } else {
      return (
        <div>
          <div className="container col d-flex justify-content-center pt-4">
            <div className="card text-center w-50">

              <div className="card-header">
                <h3>Login Azubi-Bereich</h3>
              </div>
              {this.state.firework === false ? null : Firework}


              <div className="card-body ">
                <form
                  id="CertForm"
                  className="needs-validation"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipUsername">Username</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="emailPrepend">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-person-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                              <path
                                fillRule="evenodd"
                                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.handleChange}
                          id="email"
                          placeholder="E-Mail"
                          aria-describedby="emailPrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie einen eindeutigen und gültigen Benutzernamen.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipPasswort">password</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="PasswortPrepend"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-key"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"
                              />
                              <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          value={this.state.password}
                          onChange={this.handleChange}
                          id="password"
                          placeholder="Passwort"
                          aria-describedby="PasswortPrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie ein gültiges Passwort.
                          {/* { authError ? <p>{authError}</p> : null } */}
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>
                  <button
                    id="btnSubmit"
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Einloggen
                  </button>
                  <div>{/* { authError ? <p>{authError}</p> : null } */}</div>
                </form>
              </div>
              <Link to="/pwreset">
                <p>Passwort vergessen ?</p>
              </Link>





              <div className="card-footer">

                <a
                  href="."
                  type="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  className="info"
                >
                  <p>Informationen zum Login</p>
                </a>
                <Link to="/" >
                  Zurück zur Homepage
                </Link>
              </div>

            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Informationen
                  </h5>
                </div>

                <div className="modal-body">
                  <p><b>Haben Sie noch keinen Account für unseren Azubi-Bereich ?</b>
                    <br />
                    Dann kontaktieren Sie folgende Ansprechpartner:
                    <br/>
                    <li><a href="mailto:azubi_internetgruppe@arburg.com">Internetgruppe</a></li>
                    <li><a href="mailto:infoordner@arburg.com">Infoordner Gruppe</a></li>
                    <li>oder ihren Ausbilder</li>
                  </p>
                </div>


                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      );
    }
  }
}

export default Login;
