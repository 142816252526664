import Sidebar from "../Sidebar";
import React from "react";
import { fb } from "../../../firebase";
import { fb as fb2 } from "./provider/firebase";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { v4 as uuid } from "uuid";
import isImage from "../DataUploader/provider/ImageTestProvider";
import logError from "../DataUploader/provider/ErrorHandler";
import BackendMenuToggle from "../BackendMenuToggle";
// import { Dropdown} from "react-bootstrap";

//Create variable to access firebase storage
const storage = fb.storage();
const storage2 = fb2.storage();

class DataUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      url2: "",
      progress: 0,
      progress2: 0,
      copied: false,
      copied2: false,
      imageName: "",
      ordner: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleChangeSelect = (e) => {
    // console.log(e.target.id)
    // console.log(e.target.value)
    this.setState({
      [e.target.id]: e.target.value,
    });

    var imageName = this.state.image.name;
    var folder = e.target.value + "/";
    var button = document.getElementById("upload");
    var buttonDev = document.getElementById("uploadDev");
    var buttonProd = document.getElementById("uploadProd");
    button.disabled = false;
    buttonDev.disabled = false;
    buttonProd.disabled = false;
    storage
      .ref(folder)
      .list()
      .then((e) => {
        e.items.forEach((element) => {
          if (element.name.includes(imageName)) {
            console.log("Existiert schon");
            button.disabled = true;
            buttonDev.disabled = true;
            alert(
              "In diesem Ordner gibt es dieses Bild mit diesem Namen schon"
            );
            storage
              .ref(folder)
              .child(element.name)
              .getDownloadURL()
              .then((url) => {
                this.setState({ url });
              });
          } else {
            // buttonDev.disabled = true;
            // if (buttonDev.disabled === false && buttonProd.disabled === false) {
            //   buttonDev.disabled = true;
            //   buttonProd.disabled = true;
            // }
            console.log("Passt");
          }
        });
      });

    storage2
      .ref(folder)
      .list()
      .then((e) => {
        console.log(e.items);
        e.items.forEach((element) => {
          if (element.name.includes(imageName)) {
            console.log("Existiert schon");
            button.disabled = true;
            buttonProd.disabled = true;

            alert(
              "In diesem Ordner gibt es dieses Bild mit diesem Namen schon"
            );
            storage2
              .ref(folder)
              .child(element.name)
              .getDownloadURL()
              .then((url2) => {
                this.setState({ url2 });
              });
          } else {
            // buttonDev.disabled = true;
            // if (buttonDev.disabled === false && buttonProd.disabled === false) {
            //   buttonDev.disabled = true;
            //   buttonProd.disabled = true;
            // }
            console.log("Passt");
          }
        });
      });

    // console.log(test)
  };

  handleChange = (e) => {
    this.setState({ progress: 0 });
    this.setState({ image: "" });
    this.setState({ url: "" });
    this.setState({ url2: "" });
    var button = document.getElementById("upload");
    var buttonDev = document.getElementById("uploadDev");
    var buttonProd = document.getElementById("uploadProd");
    button.disabled = false;
    buttonDev.disabled = false;
    buttonProd.disabled = false;

    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
      document.getElementById("ordner").hidden = false;
      document.getElementById("ordner").selectedIndex = 0;

      // this.state.progress
      // this.state.image
    }
  };

  // hideButtons = (e) => {
  //   console.log("Hide")
  //   console.log(document.getElementById("upload"))
  //   if (document.getElementById("upload") !== null) {
  //     document.getElementById("upload").disabled = true;
  //   }

  // };

  handleUpload = () => {
    const { image } = this.state;
    //Create unique image name

    // console.log(this.state.ordner)

    if (this.state.ordner === "" || this.state.image === "") {
      console.error("Kein Ordner oder Bild ausgewählt");
    } else {
      const imageName = uuid() + "_" + image.name;
      this.setState({ imageName });

      const uploadTask = storage
        .ref(`${this.state.ordner}/${imageName}`)
        .put(image);

      // const uploadTask = storage.ref(`data/${imageName}`).put(image);         //-> Ordnerstruktur !
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        (error) => {
          logError("upload error", 1);
        },
        () => {
          //gets download url if upload was successful
          storage
            .ref(this.state.ordner)
            .child(imageName)
            .getDownloadURL()
            .then((url) => {
              this.setState({ url });
              document.getElementById("ordner").hidden = true;
            });
        }
      );

      // Same for the opposite storage2
      const uploadTask2 = storage2
        .ref(`${this.state.ordner}/${imageName}`)
        .put(image);

      // const uploadTask = storage2.ref(`data/${imageName}`).put(image);         //-> Ordnerstruktur !
      uploadTask2.on(
        "state_changed",
        (snapshot) => {
          const progress2 = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress2 });
        },
        (error) => {
          logError("upload error", 1);
        },
        () => {
          //gets download url if upload was successful
          storage2
            .ref(this.state.ordner)
            .child(imageName)
            .getDownloadURL()
            .then((url2) => {
              this.setState({ url2 });
              document.getElementById("ordner").hidden = true;
            });
        }
      );
    }
  };
  render() {
    const style = {
      // height: '100vh',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };
    const borderStyle = {
      border: "solid 1px",
      borderColor: "lightgrey",
      borderRadius: "4px",
      padding: "2%",
    };
    const inputStyle = {
      border: "none",
      margin: "0px",
      padding: "0px",
    };

    // this.hideButtons();
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <BackendMenuToggle />
          <div className="container-fluid">
            <div style={style}>
              <br />
              <br />
              <div className="form-group border-style" style={borderStyle}>
                <label>Datei hinzufügen:</label>
                <br />
                <input
                  style={inputStyle}
                  className="form-control"
                  type="file"
                  required
                  onChange={this.handleChange}
                />
                <br />
                <div className="form-row">
                  <div className="col mb-3">
                    <div className="input-group-prepend">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01"
                      >
                        Ordner
                      </label>

                      <select
                        className="custom-select"
                        id="ordner"
                        required
                        onChange={this.handleChangeSelect}
                      >
                        <option defaultValue hidden>
                          Auswählen
                        </option>
                        <option value="Slideshow">Slideshow</option>
                        <option value="Cardslider">Cardslider</option>
                        <option value="Beruf des Monats">
                          Beruf des Monats
                        </option>
                        <option vaule="Karriere">Karriere</option>
                        <option vaule="AusbildungAktuell">Ausbildung-Aktuell</option>
                        <option value="Ausbilder">Ausbilder</option>
                        <option value="News">News</option>
                        <option value="Projektgruppen">Projektgruppen</option>
                        <option value="Bewerbung">Bewerbung</option>
                        <option value="Infoordner">Infoordner</option>
                        <option value="Über Uns">Über Uns</option>
                        <option value="Kontakt">Kontakt</option>
                        <option value="Linktree">Linktree</option>
                        <option value="Sonstiges">Sonstiges</option>
                      </select>
                    </div>
                  </div>
                </div>

                <br />
                <button
                  className="btn btn-primary"
                  id="upload"
                  onClick={this.handleUpload}
                >
                  Upload Both
                </button>

                {process.env.NODE_ENV === "development" ? (
                  <div>
                    <button
                      className="btn btn-primary"
                      id="uploadDev"
                      onClick={this.handleUpload}
                    >
                      Upload {process.env.NODE_ENV}
                    </button>

                    <button
                      className="btn btn-primary"
                      id="uploadProd"
                      onClick={this.handleUpload}
                    >
                      Upload Prod
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className="btn btn-primary"
                      id="uploadDev"
                      onClick={this.handleUpload}
                    >
                      Upload {process.env.NODE_ENV}
                    </button>

                    <button
                      className="btn btn-primary"
                      id="uploadProd"
                      onClick={this.handleUpload}
                    >
                      Upload Dev
                    </button>
                  </div>
                )}

                <br />
                <br />
              </div>

              <progress value={this.state.progress} max="100" />

              <br />

              {isImage(this.state.imageName) && (
                <img
                  src={this.state.url || "http://via.placeholder.com/400x300"}
                  alt="Uploaded images"
                  height="300"
                  width="400"
                />
              )}

              <br />
              {this.state.url && (
                <div>
                  <h3>Ergebnis: ({process.env.NODE_ENV})</h3>

                  <input
                    value={this.state.url}
                    onChange={({ target: { value } }) =>
                      this.setState({ value, copied: false })
                    }
                  />

                  <CopyToClipboard
                    text={this.state.url}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <button>Copy</button>
                  </CopyToClipboard>

                  {this.state.copied ? (
                    <span style={{ color: "green" }}>Copied.</span>
                  ) : null}
                </div>
              )}
              <br />
              {this.state.url2 && (
                <div>
                  <h3>
                    Ergebnis (
                    {process.env.NODE_ENV === "production"
                      ? "development"
                      : "production"}
                    ):
                  </h3>

                  <input
                    value={this.state.url2}
                    onChange={({ target: { value } }) =>
                      this.setState({ value, copied2: false })
                    }
                  />

                  <CopyToClipboard
                    text={this.state.url2}
                    onCopy={() => this.setState({ copied2: true })}
                  >
                    <button>Copy</button>
                  </CopyToClipboard>

                  {this.state.copied2 ? (
                    <span style={{ color: "green" }}>Copied.</span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DataUploader;
