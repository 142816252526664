/**
 * @file: JobboardAusbildung.jsx
 * @description: Startseite des Ausbildungsjobboards
 */

import React from "react";
import { useState, useEffect } from "react";
import { fb } from "../../firebase";
import { Link, useLocation } from "react-router-dom";

import './JobboardAusbildung.scss';

import AusbildungFooter from "./footer/footer";
import AusbildungNavigation from "./navigation/navigation";
import AusbildungJobcontainer from "./jobcontainer/jobcontainer";

// Assets
import BewerbungsProzess from '../../ressources/AusbildungsJobboard/BewerbungsProzess.svg'

export default function JobboardAusbildung() {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let [ausbData, setAusbData] = useState([]);
    let [queryType, setQueryType] = useState("none");
    let [queryDir, setQueryDir] = useState("none");
    let queryParams = useQuery();

    useEffect(() => {
        let getAusbData = fb.firestore().collection('ausbildung');

        document.title = 'Jobboard';
      
        getAusbData.onSnapshot((data) => {
            let content = []
            data.forEach((doc) => {
                content.push({
                    id: doc.id,
                    title: doc.data().data.postTitle,
                    type: doc.data().data.type,
                    displayType: doc.data().data.displayType,
                    startDate: doc.data().data.startDate,
                    direction: doc.data().data.direction,
                    place: doc.data().data.place,
                    archive: doc.data().archive,
                    name: doc.data().data.name
                })
            })
            const typeOrder = { "ausbildung": 0, "dhbw": 1, "praktikum": 2 };

            function normalizeType(type) {
                return type.toLowerCase();
            }
            content.sort((a, b) => {
                const normalizedTypeA = normalizeType(a.type);
                const normalizedTypeB = normalizeType(b.type);
                const typeComparison = typeOrder[normalizedTypeA] - typeOrder[normalizedTypeB];
                if (typeComparison !== 0) {
                    return typeComparison;
                } else {
                    return a.title.localeCompare(b.title);
                }
            });
            
            setAusbData(content)

            console.log(content)
          })
      }, [])
      function Jobdesc() {
        if(queryParams.get('type') == null || queryParams.get('type') == 'none') {
            if( queryParams.get("direction") == null || queryParams.get("direction") == 'none') {
                return (
                    <div className="job-ausb-header">
                        <div className="job-description-header">
                            <h4><span>/ </span>#gönnDirARBURG - Lernen und arbeiten in faszinierender Umgebung</h4>
                        </div>
                        <div className="job-description-image">
                            <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2Fb3d141b0-e33a-4f71-946e-b5bc641e603d_Azubi_goennDirARBURG_16-9.jpg?alt=media&token=cd99d5a0-3c50-4ec3-91f7-66ed72b43d3b" alt="" />
                        </div>
                        <div className="job-description-content">
                            <p>
                                ARBURG zählt schon lange zu den Unternehmen mit einer ausgezeichneten Berufsausbildung.
                                Mit unserem mehrfach prämierten ARBURG Ausbildungskonzept haben wir in über 70 Jahren mehr 
                                als 2.000 junge Menschen auf ihren Beruf vorbereitet, ob mit einer Ausbildung oder einem Studium.
                                Denn als einer der weltweit führenden Maschinenhersteller für die Kunststofftechnik brauchen wir 
                                immer engagierte und begeisterte Mitarbeitende – wie Dich!
                            <br />
                                Wir ermöglichen jungen Leuten einen gelungenen Start in die Berufswelt. Wer über Teamgeist 
                                verfügt und bereit ist, lebenslang zu lernen und Verantwortung zu übernehmen, kann es bei 
                                uns weit bringen. Bei ARBURG bieten wir langfristig hervorragende Karriereperspektiven. Und 
                                das nicht nur, weil wir ausschließlich für unseren eigenen Bedarf ausbilden.
                            </p>
                        </div>
                    </div>
                    )
            } else {
                return <></>
            }
            
        } else {
            return <></>
        }
    }
    function InitiativStelle() {
        if(queryParams.get("type") == null || queryParams.get("type") == "none" || queryParams.get("type") == "praktikum") {
            return(
                <a className="job-container" href="https://www5.arburg.com/praktikum/custom/arburgp/register.jsp?posting-nr=2015_23"
                onClick={() => {window.scroll({top: 0, left: 0})}}>
                    <div className="job-container-section">
                        <span>Initiativbewerbung Schulpraktika</span>
                    </div>
                    <div className="job-container-section">
                        <span>Praktikum</span>
                    </div>
                    <div className="job-container-section">
                        <span>Individuell</span>
                    </div>
                    <div className="job-container-section">
                        <span>Loßburg</span>
                    </div>
                </a>   
            )
        } else {
            return <></>
        }
    }
    function JobProc() {
        if(queryParams.get('type') == null || queryParams.get('type') == 'none') {
            if( queryParams.get("direction") == null || queryParams.get("direction") == 'none') {
                return (
                    <div className="ausb-process-info-container">
                        <div className="process-header">
                            <h4><span>/ </span>Unser Bewerbungsprozess im Überblick</h4>
                        </div>
                        <div className="process-content-wrapper">
                            <div className="process-image">
                                <img src={BewerbungsProzess} width={800} />
                            </div>
                            <div className="process-contact">

                                <div className="process-contact-header">
                                <h4>Fragen?</h4>
                                <p>Bei Fragen rund um das Thema Bewerbung, Ausbildung und Praktikum wende Dich bitte an:</p>
                                </div>    
                                <img
                                width={400}
                                src={'https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Ausbilder%2F3676e09c-f54b-4c17-a323-22c2fcde350d_michael_vieth.jpg?alt=media&token=51f49568-3c6a-4e9b-b438-79e7207ca2c7'}/>
                                <div className="process-contact-information">
                                    <span>Michael Vieth</span> <br/>
                                    <span>Ausbildungsleiter</span> <br/>
                                    <a href="mailto:AUSBILDUNG@ARBURG.COM">&#9993; ausbildung@arburg.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
            } else {
                return <></>
            }
            
        } else {
            return <></>
        }
    }
    return (
        <div className="job-wrapper">
        <AusbildungNavigation />
        <Jobdesc />
        <div className="job-search-options">
                <div className="job-search-header">
                    <h1>Du suchst ...</h1>
                </div>
                <div className="job-search-input">
                    <div className="input-container">
                        <label htmlFor="spec-word-input">
                            ... nach Art
                        </label>
                        <select onChange={(e) => setQueryType(e.target.value)}>
                            <option value={"none"}>Kein Filter</option>
                            <option value={"ausbildung"}>Ausbildung</option>
                            <option value={"dhbw"}>DHBW-Studium</option>
                            <option value={"praktikum"}>Schulpraktika</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="spec-word-input">
                            ... nach Fachrichtung
                        </label>
                        <select onChange={(e) => setQueryDir(e.target.value)}>
                            <option value={"none"}>Kein Filter</option>
                            <option value={"Technik"}>Technik</option>
                            <option value={"Informatik"}>Informatik</option>
                            <option value={"Wirtschaft"}>Wirtschaft</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <Link className="search-btn" to={`/Jobboard?type=${queryType}&direction=${queryDir}`}>Stellen suchen</Link>
                    </div>
                </div>
            </div>
        <div className="job-container-wrapper">
            
            <div className="job-container-wrapper-header">
                <div className="wrapper-header-section">
                    <h3>Stellenbezeichnung</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Art</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Beginn</h3>
                </div>
                <div className="wrapper-header-section">
                    <h3>Ort</h3>
                </div>
            </div>
            {ausbData.map((index, i) => {
                if(queryParams.get("type") == null && queryParams.get("direction") == null || queryParams.get("type") == "none" && queryParams.get("direction") == "none") {
                    if(index.archive === false) {
                        return (
                            <AusbildungJobcontainer link={`ausbildung/${index.id}`} name={index.name} type={index.displayType} startDate={index.startDate} place={index.place}/>    
                        )
                    } else {
                        return <></>
                    }
                } else {
                    if(index.type.toLowerCase() == queryParams.get("type").toLowerCase() || queryParams.get("type").toLowerCase() == "none") {
                        if(queryParams.get("direction") == null || queryParams.get("direction") == "none") {
                            if(index.archive === false) {
                                return (
                                    <AusbildungJobcontainer link={`ausbildung/${index.id}`} name={index.name} type={index.displayType} startDate={index.startDate} place={index.place}/>    
                                )
                            } else {
                                return <></>
                            }
                        } else if(index.direction.toLowerCase() == queryParams.get("direction").toLowerCase()) {
                            if(index.archive === false) {
                                return (
                                    <AusbildungJobcontainer link={`ausbildung/${index.id}`} name={index.name} type={index.displayType} startDate={index.startDate} place={index.place}/>
                                )
                            } else {
                                return <></>
                            }
                        } else {
                            return <></>
                        }
                    } else{
                        return <></>
                    }
                }
                
            })}
            <InitiativStelle />
            </div>
            <JobProc />
        <AusbildungFooter />
        </div>
    )
}