import React from "react";
import Sidebar from "../Sidebar";
import BackendMenuToggle from '../BackendMenuToggle'


class Statistiken extends React.Component {

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
        <BackendMenuToggle/>

          <div className="container-fluid">
            
          </div>
        </div>
      </div>
    );
  }
}
export default Statistiken;
