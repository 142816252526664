/**
 * @file: jobcontainer.jsx
 * @description: the jobcontainer for ausbildung Jobboard
 */

import { Link } from "react-router-dom"

import './jobcontainer.scss'

export default function AusbildungJobcontainer(props) {
    return (
        <Link className="job-container" to={`/jobboard/${props.link}`}
        onClick={() => {window.scroll({top: 0, left: 0})}}>
            <div className="job-container-section">
                <span>{props.name}</span>
            </div>
            <div className="job-container-section">
                <span>{props.type}</span>
            </div>
            <div className="job-container-section">
                <span>{props.startDate}</span>
            </div>
            <div className="job-container-section">
                <span>{props.place}</span>
            </div>
        </Link>
    )
}