import React from 'react'
import BlogPost from './BlogPost/BlogPost';
import './BlogEntries.scss';

const BlogEntry = (props) => {
    var sortedArr = [];

    return(
        <div className="row"> 
            {props.ausbilder.map((ausbilder, index) => {
                sortedArr[ausbilder.order] = [ausbilder.title, ausbilder.summary, ausbilder.content, ausbilder.date, ausbilder.tags, ausbilder.author, ausbilder.order, ausbilder.imageUrl, ausbilder.bereich]
                return <div key={`${ausbilder.author}-${index}`}></div>
            })}
            {
            sortedArr.map((ausbilder, index) => {
                return <BlogPost key={`${ausbilder[0]}-${index}`} title={ausbilder[0]} summary={ausbilder[1]} content={ausbilder[2]} date={ausbilder[3]} tags={ausbilder[4]} author={ausbilder[5]} order={ausbilder[6]} imageUrl={ausbilder[7]} bereich={ausbilder[8]}/>
            })
            }
        </div>
        
    )
}

export default BlogEntry