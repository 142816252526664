import React from "react";
import AccordionContent from "./AccordionContent/AccordionContent";
import "./AusbildungAccordion.scss";

class AusbildungAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beruf_technik: [],
      beruf_informatik: [],
      beruf_wirtschaft: [],
    };
  }

  componentDidMount() {
    this.props.ausbildungsberufe.map((beruf) => {
      if (beruf.sector === "technik") {
        this.setState((state) => {
          var arr = [];
          arr = state.beruf_technik;
          arr.sort(this.sortFunc);
          arr.push(beruf);
          return {
            ...state,
            beruf_technik: arr,
          };
        });
      }

      if (beruf.sector === "informatik") {
        this.setState((state) => {
          var arr = [];
          arr = state.beruf_informatik;
          arr.sort(this.sortFunc);
          arr.push(beruf);
          return {
            ...state,
            beruf_informatik: arr,
          };
        });
      }

      if (beruf.sector === "wirtschaft") {
        this.setState((state) => {
          var arr = [];
          arr = state.beruf_wirtschaft;
          arr.sort(this.sortFunc);
          arr.push(beruf);
          return {
            ...state,
            beruf_wirtschaft: arr,
          };
        });
      }

      return <></>;
    });
  }

  sortFunc = (a, b) => {
    if (a.order < b.order) {
      return -1;
    } else {
      if (a.order > b.order) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  

  render() {
    return (
      <div  id="accordion">
        {/* <AusbildungAccordion /> */}

        <div className="card mb-2">
          <div
            className="card-header text-center"
            id="HeadingTwo"
            data-toggle="collapse"
            data-target="#technik"
          >
            <button
              className="btn btn-link"
              aria-expanded="true"
              aria-controls="technik"
            >
              <h3>Technik</h3>
            </button>
          </div>
          <div
            id="technik"
            className="collapse show"
            aria-labelledby="HeadingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              {/* <div className="row"> */}
              {/* Video Elektro:
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card mb-3 blogpost">
                <video width="100%" height="auto" controls poster="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2Ffd574364-63a1-4684-a1bd-d94b36a720e9_Elektroniker.png?alt=media&token=ae8a4b55-7024-47cf-8faf-4a8601d17a0d">
                  <source src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2Fcb536be1-4f75-48cd-8ee8-08eb976993d2_Elektro1.1.mp4?alt=media&token=ac254919-3483-49e6-8dee-e48607fa7a55"></source>
                </video>
                </div>
              </div>

              {/*Video Konstruktionsmechanik:*/}
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card mb-3 blogpost">
                <video width="100%" height="auto" controls poster="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2F100a47bb-b5c7-45ef-93ec-c6c10d999252_industriemechaniker.png?alt=media&token=c372a1d4-fe00-4cf0-8fed-16c3365651f8">
                  <source src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2F87750b99-2ebc-496e-91b5-3f3629689786_Konstruktionsmechanik1.0.mp4?alt=media&token=c6adb91d-2e66-4f78-a5d6-c77dbc9d906b"></source>
                </video>
                </div>
              </div> */}

              {/*Video Mechanik:*/}
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card mb-3 blogpost">
                <video width="100%" height="auto" controls poster="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2F275ff817-e9d0-45bd-8f07-7e6d38748f36_Mechanik.png?alt=media&token=8fb22910-15eb-4ed1-9e22-ae67932cdc34">
                  <source src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/data%2Ffad1d689-5ca4-4594-b3fe-a4bfcb3ad618_Mechanik1.0.mp4?alt=media&token=f8cb8919-fe58-48a6-8f35-db270d584103"></source>
                </video>
                </div>
              </div>
              </div> */}
              <AccordionContent ausbildungsberufe={this.state.beruf_technik} />
              {/* <AlleAusbildungsberufekfm ausbildungsberufe_kfm={this.state.ausbildungsberufe_kfm} /> */}
            </div>
          </div>

          <div
            className="card-header text-center collapsed"
            id="headingThree"
            data-toggle="collapse"
            data-target="#informatik"
          >
            <button
              className="btn btn-link collapsed"
              aria-expanded="false"
              aria-controls="informatik"
            >
              <h3>Informatik</h3>
            </button>
          </div>
          <div
            id="informatik"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              <AccordionContent ausbildungsberufe={this.state.beruf_informatik} />
              {/* <AlleAusbildungsberufetech ausbildungsberufe={this.state.ausbildungsberufe} /></div> */}
            </div>
          </div>

          <div
            className="card-header text-center collapsed"
            id="headingFour"
            data-toggle="collapse"
            data-target="#wirtschaft"
          >
              <button
                className="btn btn-link collapsed"
                aria-expanded="false"
                aria-controls="wirtschaft"
              >
                <h3>Wirtschaft</h3>
              </button>

          </div>
          <div
            id="wirtschaft"
            className="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordion"
          >
            <div className="card-body">
              <AccordionContent ausbildungsberufe={this.state.beruf_wirtschaft} />
              {/* <AlleDh dhbw={this.state.dhbw} /> */}
            </div>
          </div>

          </div>
        </div>
    );
  }
}

export default AusbildungAccordion;
