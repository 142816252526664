import React from 'react';
// import firebase from '../../../../../firebase'
import { fb } from '../../../../firebase'
import ReactHtmlParser from 'react-html-parser';

import "./NewsDetails.scss";

class NewsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            artikel: [],
            url: "",
            key: ""
        };
    }

    onCollectionUpdate = (querySnapshot) => {


        const artikel = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().id === this.state.key) {
                const { content, date, author, tags, title, btnTitle, btnUrl } = doc.data();
                artikel.push({
                    key: doc.id,
                    content,
                    date,
                    author,
                    tags,
                    title,
                    btnTitle,
                    btnUrl
                });
            }
        })

        this.setState({
            artikel
        });
    }


    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);

        var url = window.location.href;
        var laenge = url.search('News/') + 5;
        var key = url.slice(laenge);

        this.setState({ key });
        this.setState({ url });

    }
    componentWillUnmount() {
        this.unsubscribe()
    }


    render() {
        return (
            <div>
                {this.state.artikel.map((info, index) => {
                    return (
                        // <div key={uuid()} className="container">
                        <div key={`${info.title}-${index}`} className="container">
                            <br />
                            <div className="titel_news">
                                <h1 className="schrift">{info.title}</h1>
                            </div>
                            {/* <table>
                            <td className="Farbe">
                                <tr> 
                                 </tr>
                            </td>  
                            <td> */}
                            <div className="Abstand">
                                <br />
                                {ReactHtmlParser(info.content)}
                                <br />
                                <p><i>Autor: {info.author}</i></p>
                            </div>

                            <br/><br/><br/>

                            {info.btnUrl ? (
                                <div>
                                    <a href={info.btnUrl}>
                                        <input className="ButtonNews" type="submit" value={info.btnTitle} />
                                    </a>
                                </div>
                            ) : (
                                <div>
                                </div>
                            )}

                            <div className="button_news">
                                <a href="/#/News"><input type="button" className="btn-zurueck btn btn-primary" value="Zurück"/></a>
                            </div>
                            <br /><br /><br />
                        </div>
                    );
                })}
            </div>
        )
    }
}
export default NewsDetails;
