import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { v4 as uuid } from "uuid";
// import "./Wartung.scss";
// import Countdown from "../Countdown/Countdown";
import { fb } from "../../firebase";

class CloseWartung extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("Countdown");
    this.ref2 = fb.firestore().collection("Wartung");
    this.unsubscribe = null;
    this.unsubscribe2 = null;
    this.state = {
      time: new Date(),
      date: [],
      dDate: [],
      trigger: undefined,
    };
  }
  onCollectionUpdate2 = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      this.setState({
        trigger: doc.data().trigger,
        key: doc.id,
      });
    });
  };

  onCollectionUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      var { day, month, year, hours, min, sec } = doc.data();
      this.setState({ day, month, year, hours, min, sec });
      

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }

      var date = year + "-" + month + "-" + day + "T09:15:00";
      var dDate = new Date(month + "." + day + "." + year);
      this.setState({ dDate: dDate });
      this.setState({
        date: date,
      });
    });
  };
  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    this.unsubscribe2 = this.ref2.onSnapshot(this.onCollectionUpdate2);

    setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
  }

  update = () => {
    this.setState({
      time: new Date(),
    });
  };

  setTrigger = () => {
    fb.firestore()
      .collection("Wartung")
      .doc(this.state.key)
      .update({ trigger: false });
  };

  render() {
    const h = this.state.time.getHours();
    const m = this.state.time.getMinutes();
    const s = this.state.time.getSeconds();
    const d = this.state.time.getDate();
    const month = this.state.time.getMonth() + 1;
    const y = this.state.time.getFullYear();

    if (
      this.state.month === month &&
      this.state.year === y &&
      this.state.day === d &&
      this.state.hours === h &&
      this.state.min === m &&
      this.state.sec === s &&
      this.state.key !== undefined &&
      this.state.trigger === true
    ) {
      this.setTrigger();
      return <div></div>;
    } else {
      return (
        <div></div>
      );
    }
  }
}
export default CloseWartung;
