import React from "react";
// import logo from "./logo.png";
import arburg from "./Logo_Arburg.svg";
import insta from "./insta_logo.svg";
import faceb from "./facebook_logo.svg";
import yt from "./yt_logo.svg";
import twitter from "./twitter_logo.svg";
import linkedIn from "./linkedIn_logo.svg";
import "./SocialMedia.scss";

class SocialMobile extends React.Component {
  render() {
    return (
      <div className="container Wartung">
        <div className="text-center">
          <br />
          <a href="https://www.arburg.com/de/" target="_blank" rel="noreferrer">
            <img alt="Arburg" width="60%" src={arburg} />
          </a>
          <br />
          <br />
          <a href="https://www.instagram.com/arburg_rookies/?hl=de" target="_blank" rel="noreferrer">
            {" "}
            <img alt="Arburg Ausbildung" className="svg" src={insta} />
          </a>
          <br />
          <br />
          <a href="https://de-de.facebook.com/arburgworld/" target="_blank" rel="noreferrer">
            {" "}
            <img alt="Arburg Ausbildung" className="svg" src={faceb} />
          </a>
          <br />
          <br />
          <a href="https://www.youtube.com/user/ARBURGofficial" target="_blank" rel="noreferrer">
            {" "}
            <img alt="Arburg Ausbildung" className="svg" src={yt} />
          </a>
          <br />
          <br />
          <a href="https://twitter.com/arburgworld" target="_blank" rel="noreferrer">
          <i class="fab fa-twitter"></i>
            <img alt="Arburg Ausbildung" className="svg" src={twitter} />
          </a>
          <br />
          <br />
          <a href="https://www.linkedin.com/company/arburg-gmbh-co-kg/mycompany/" target="_blank" rel="noreferrer">
            {" "}
            <img alt="Arburg Ausbildung" className="svg" src={linkedIn} />
          </a>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
export default SocialMobile;
