import React from "react";
import { fb } from "../../../firebase";
import { fb as fb2 } from "../DataUploader/provider/firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
// import Loading from "../../../components/Loading/Loading";

import Clipboard from "react-clipboard.js";

const storage = fb.storage();
const storage2 = fb2.storage();
var folder = "";

class DataDeleter extends React.Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);

    this.ref = fb.storage();
    this.ref2 = fb2.storage();
    this.unsubscribe = null;
    this.state = {
      db: [],
      ordner: "Auswählen",
      images: [],
      keys: [],
    };
  }
  onSuccess() {
    console.log("successfully copied");
  }

  delete(key) {
    // alert(key);
    fb.storage()
      .ref(key)
      .delete()
      .then(function () {
        console.log("File deleted successfully");
      })
      .catch(function (error) {
        console.log("Uh-oh, an error occurred!");
      })
      .finally(function () {
        window.location.reload();
      });
  }

  async componentDidMount() {
    //   let result = await this.ref.listAll();
    //   let fullPath = result.items.map((Path) => Path.fullPath);
    //   let urlPromises = result.items.map((imageRef) => imageRef.getDownloadURL());
    //   const urls = await Promise.all(urlPromises);
    //   let images = [];
    //   if (fullPath.length === urls.length) {
    //     for (let index = 0; index < fullPath.length; index++) {
    //       images.push({
    //         index: index,
    //         path: fullPath[index],
    //         image: urls[index],
    //       });
    //     }
    //   }
    //   this.setState({ images: images });
  }

  // componentWillUnmount() {
  //   this.unsubscribe()
  // }

  handleChangeSelect2 = (e) => {
    console.log(e.target.value);
    var db;
    var ordner = document.getElementById("ordner");
    ordner.value = "Auswählen";
    this.setState({ ordner: "Auswählen" });
    folder = "";

    if (process.env.NODE_ENV === "production") {
      if (e.target.value === "Prod") {
        db = storage;
        ordner.disabled = false;
        ordner.value = "Auswählen";
      } else {
        db = storage2;
        ordner.disabled = false;
        ordner.value = "Auswählen";
      }
    } else {
      if (e.target.value === "Prod") {
        db = storage2;
        ordner.disabled = false;
        ordner.value = "Auswählen";
      } else {
        db = storage;
        ordner.disabled = false;
        ordner.value = "Auswählen";
      }
    }
    this.setState({ db });
  };

  handleChangeSelect = (e) => {
    console.log(e.target.value);
    this.setState({ ordner: e.target.value });
    // ordner = e.target.value
    // console.log("Ordner: " + ordner)
    // this.setState({images: null})

    // console.log("ORDNER: " + this.state.ordner)

    folder = e.target.value + "/";
    var images = [];
    var index = 0;

    this.state.db
      .ref(e.target.value)
      .listAll()
      .then((e) => {
        e.items.forEach((element) => {
          this.state.db
            .ref(folder)
            .child(element.name)
            .getDownloadURL()
            .then((url) => {
              this.setState({ url });
              images[index] = url;
              index = index + 1;
            });
          this.setState({ images: images });
        });
      });
  };

  render() {
    if (this.state.images) {
      return (
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid">
              <div className="card">
                <div className="card-header text-center">
                  {this.state.ordner}
                </div>
                <div className="card-body">
                  <div className="input-group-prepend">
                    <label
                      className="input-group-text"
                      htmlFor="inputGroupSelect02"
                    >
                      Datenbank
                    </label>

                    <select
                      className="custom-select"
                      id="db"
                      required
                      onChange={this.handleChangeSelect2}
                    >
                      <option defaultValue hidden>
                        Auswählen
                      </option>
                      <option value="Prod">Prod</option>
                      <option value="Dev">Dev</option>
                    </select>
                  </div>

                  <div className="input-group-prepend">
                    <label
                      className="input-group-text"
                      htmlFor="inputGroupSelect01"
                    >
                      Ordner
                    </label>

                    <select
                      className="custom-select"
                      id="ordner"
                      disabled={true}
                      onChange={this.handleChangeSelect}
                    >
                      <option defaultValue hidden>
                        Auswählen
                      </option>
                      <option value="Slideshow">Slideshow</option>
                      <option value="Cardslider">Cardslider</option>
                      <option value="Beruf des Monats">Beruf des Monats</option>
                      <option vaule="Karriere">Karriere</option>
                      <option value="Ausbilder">Ausbilder</option>
                      <option value="News">News</option>
                      <option value="Projektgruppen">Projektgruppen</option>
                      <option value="Bewerbung">Bewerbung</option>
                      <option value="Infoordner">Infoordner</option>
                      <option value="Über Uns">Über Uns</option>
                      <option value="Kontakt">Kontakt</option>
                      <option value="Linktree">Linktree</option>
                      <option value="Sonstiges">Sonstiges</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="card-columns">
                {folder !== "" ? (
                  <div>
                    {this.state.images.map((image, index) => {
                      return (
                        <div
                          key={`${image.path}-${index}`}
                          className="card mb-3"
                        >
                          <img
                            className="card-img-top"
                            src={image}
                            alt={index}
                          />
                          <div className="card-body">
                            <Clipboard
                              className="btn btn-warning"
                              data-clipboard-text={image}
                              button-title="In die Zwischenablage kopieren"
                            >
                              Zwischenablage
                            </Clipboard>
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-toggle="modal"
                              data-target={"#example" + image.index}
                            >
                              Löschen
                            </button>
                            <div
                              className="modal fade"
                              id={"example" + image.index}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Löschen
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    Sicher dieses Bild löschen ?
                                    <br />
                                    {/* <input> {image}</input> */}
                                    {/* geht noch nicht */}
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      placeholder="url"
                                      value={image.path}
                                      aria-label="Bild"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                    >
                                      Schließen
                                    </button>
                                    <button
                                      onClick={this.delete.bind(
                                        this,
                                        image.path
                                      )}
                                      className="btn btn-danger"
                                    >
                                      Löschen
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>keiner ausgewählt</div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>{/* <Loading /> */}</div>;
    }
  }
}
export default DataDeleter;
