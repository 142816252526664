import React from 'react'
import './Gruende.scss';

const AlleGruende = (props) => {
    let width = String(100 / props.grundeFurArb.length) + "%";
    const dynamictable = {
        width: width,
    };

    return (
        <div className="card-group">
            {/* <table>
                <tbody>
                    <tr>
                        {props.grundeFurArb.map(grund => {
                            return <td key={uuid()}><p>{grund.title}</p></td>
                        })}
                    </tr>
                    <tr> */}
                        {props.grundeFurArb.map((grund, index) => {
                            return <div key={`${grund.title}-${index}`} style={dynamictable}>
                            <img className="img_gruende" src={grund.imageUrl} alt={grund.title}></img></div>
                          })}
                      {/* </tr>
                 </tbody>
             </table>
             <br></br>  */}
        </div>

    )
}

export default AlleGruende

