/**
 * Function gets the filename, then cuts it and returns true if the 
 * file is an Image and false if not
 * @param {String} imageName 
 */
function isImage(imageName){
    var cuttedStringArray = imageName.split(".");
    switch (cuttedStringArray[1]) {
        case 'png': return true;
        case 'jpg': return true;
        case 'gif': return true;
        case 'tiff': return true;
        case 'nef': return true;
        case 'ai': return true;
        case 'orf': return true;
        case 'indd': return true;
        case 'eps': return true;
        case 'cr2': return true;
        case 'psd': return true;
        default: return false;
      }
}

export default isImage;