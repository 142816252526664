/**
 * @file: ausbildungsContainer.jsx
 * @description: Der Container, der die Liste und das Slide-In für die einzelnen Artikel beinhaltet
 */

import './ausbildungsContainer.scss';
import { useState, useContext, useEffect } from 'react';
import {fb} from '../../../firebase';
import { UserContext } from '../../../';

export default function AusbildungsContainer(props) {
    
    let [popup, setPopup] = useState(false);
    let [containerClass, setContainerClass] = useState("ausbildungs-container");
    let name = props.name;
    let title = props.title;
    let place = props.place;
    let direction = props.direction;
    let type = props.type;
    let link = props.link;
    let shortDesc = props.shortDesc;
    let shortDescHeader = props.shortDescHeader;
    let image = props.image;
    let startDate = props.startDate;


    let b1Title = props.b1Title;

    let b1Line = "";
    let [b1Content, setB1Content] = useState(props.b1Content);

    let b2Title = props.b2Title;

    let b2Line = "";
    let [b2Content, setB2Content] = useState(props.b2Content);

    let b3Title = props.b3Title;

    let b3Line = "";
    let [b3Content, setB3Content] = useState(props.b3Content);

    let b4Title = props.b4Title;
    let b4Content = props.b4Content;

    let benefitsArr = props.benefits;

    const userCont = useContext(UserContext);

    useEffect(() => {
        
        if(props.archived === true) {
            setContainerClass("praktika-container archived-prak-cont")
        } else {
            setContainerClass("praktika-container")
        }
    }, [props.archived])

    function EditPraktikum(){

        function UpdateData() {

            let pushObj = {
                archive: false,
                changedBy: `${userCont?.firstname} ${userCont?.lastname}`,
                changedDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
                createdBy: props.creator,
                creationDate: props.creationDate,
                data: {
                    benefits: benefitsArr,
                    content: {
                        b1Content: b1Content,
                        b1Title: b1Title,
                        b2Content: b2Content,
                        b2Title: b2Title,
                        b3Title: b3Title,
                        b3Content: b3Content,
                        b4Title: b4Title,
                        b4Content: b4Content
                    },
                    direction: direction,
                    img: image,
                    link: link,
                    name: name,
                    postTitle: title,
                    place: place,
                    shortDesc: shortDesc,
                    shortDescHeader: shortDescHeader,
                    type: type.toLowerCase(),
                    displayType: type,
                    startDate: startDate
                }
            }
            fb.firestore().collection("ausbildung").doc(props.id).update(pushObj)
            window.alert("Dokument erfolgreich aktualisiert!")
            setPopup(false);
        }

        function ArchiveData() {
            if(props.archived === true) {
                if(window.confirm("Dokument wirklich Zurückholen? - Alle aktuellen Änderungen werden übernommen!") === true) {let pushObj = {
                    archive: false,
                    changedBy: `${userCont?.firstname} ${userCont?.lastname}`,
                    changedDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
                    createdBy: props.creator,
                    creationDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
                    data: {
                        benefits: benefitsArr,
                        content: {
                            b1Content: b1Content,
                            b1Title: b1Title,
                            b2Content: b2Content,
                            b2Title: b2Title,
                            b3Title: b3Title,
                            b3Content: b3Content,
                            b4Title: b4Title,
                            b4Content: b4Content
                        },
                        direction: direction,
                        img: image,
                        link: link,
                        name: name,
                        postTitle: title,
                        place: place,
                        shortDesc: shortDesc,
                        shortDescHeader: shortDescHeader,
                        type: type.toLowerCase(),
                        displayType: type,
                        startDate: startDate
                    }
                }
                fb.firestore().collection("ausbildung").doc(props.id).update(pushObj)
                    setPopup(false);
                }
            } else {
                if(window.confirm("Dokument wirklich Archivieren? - Alle aktuellen Änderungen werden übernommen!") === true) {let pushObj = {
                    archive: true,
                    changedBy: `${userCont?.firstname} ${userCont?.lastname}`,
                    changedDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
                    createdBy: props.creator,
                    creationDate: props.creationDate,
                    data: {
                        benefits: [],
                        content: {
                            b1Content: b1Content,
                            b1Title: b1Title,
                            b2Content: b2Content,
                            b2Title: b2Title,
                            b3Title: b3Title,
                            b3Content: b3Content,
                            b4Title: b4Title,
                            b4Content: b4Content
                        },
                        direction: direction,
                        img: image,
                        link: link,
                        name: name,
                        postTitle: title,
                        place: place,
                        shortDesc: shortDesc,
                        shortDescHeader: shortDescHeader,
                        type: type.toLowerCase(),
                        displayType: type,
                        startDate: startDate
                    }
                }
                fb.firestore().collection("ausbildung").doc(props.id).update(pushObj)
                    setPopup(false);
                }
            }
        }
        function DeleteData() {
            if(window.confirm("Dokument wirklich Löschen?") === true) {
                fb.firestore().collection("ausbildung").doc(props.id).delete();
                setPopup(false);
            }
        }

        function ManageBenefitArr(status, name) {
            if(status === true) {
                benefitsArr.push(name);
            } else {
                benefitsArr = benefitsArr.filter(item => item !== name)
            }
        }
        function ArchiveStatus() {
            if(props.archived === true) {
                return (
                    <span>Eintrag Archiviert</span>
                )
            } else {
                return <></>
            }
        }
        if(popup === true) {
            return (
                <div className="edit-background-blend">
                    <div className="edit-slider-container">
                        <div className="slider-header">
                            <div>
                                <h1>Eintrag bearbeiten</h1>
                                <ArchiveStatus />
                             </div>
                            <div>
                                <button className="close-slider-btn" onClick={() => setPopup(false)}>&#10006;</button>
                            </div>
                        </div>
                        <div className="slider-content">
                            
                             {/* Section 1 */}
                             <div className="section-container">

                                <div className="content-container">
                                    <label className="form-label">Titel (Jobboard Liste):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        defaultValue={props.name}
                                        onChange={(e) => {
                                            name = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Titel (Jobbeschreibung):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        defaultValue={props.title}
                                        onChange={(e) => {
                                            title = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Ort:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Loßburg"
                                        defaultValue={props.place}
                                        onChange={(e) => {
                                            place = e.target.value
                                        }}/>
                                    </div>
                                </div>



                                <div className="content-container">
                                    <label className="form-label">Startdatum:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="01.09.20xx"
                                        defaultValue={props.startDate}
                                        onChange={(e) => {
                                            startDate = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Link:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Link"
                                        defaultValue={props.link}
                                        onChange={(e) => {
                                            link = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Art:</label>
                                    <select defaultValue={props.type} onChange={(e) => {
                                        type = e.target.value;
                                    }}>
                                        <option value={"Ausbildung"}>Ausbildung</option>
                                        <option value={"DHBW"}>DHBW</option>
                                        <option value={"Praktikum"}>Praktikum</option>
                                    </select>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Fachrichtung:</label>
                                    <select defaultValue={props.direction} onChange={(e) => {
                                        direction = e.target.value;
                                    }}>
                                        <option value={"Technik"}>Technik</option>
                                        <option value={"Informatik"}>Informatik</option>
                                        <option value={"Wirtschaft"}>Wirtschaft</option>
                                    </select>
                                </div>

                                <div className="content-container">
                                        <label className="form-label">Hintergrund:</label>
                                        <select defaultValue={props.image} onChange={(e) => {
                                            image = e.target.value;
                                        }}>
                                            <option value={"Kaufm"}>Kaufmännisch</option>
                                            <option value={"Techn"}>Technisch</option>
                                            <option value={"IT"}>IT</option>
                                        </select>
                                </div>

                                </div>


                                {/* Section 2 */}
                                <div className="section-container">

                                <div className="content-container">
                                <label className="form-label">Preview - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    defaultValue={props.shortDescHeader}
                                    onChange={(e) => {
                                        shortDescHeader = e.target.value
                                    }}/>
                                </div>
                                </div>

                                <div className="content-container">
                                <label className="form-label">Preview - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Kurze Einleitung"
                                    defaultValue={props.shortDesc}
                                    onChange={(e) => {
                                        shortDesc = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                                </div>

                                </div>
                                {/* Section 3 */}
                                <div className="section-container">

                                <div className="content-container">
                                <label className="form-label">Block 1 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    defaultValue={props.b1Title}
                                    onChange={(e) => {
                                        b1Title = e.target.value
                                    }}/>
                                </div>
                                </div>

                                
                                <div className="content-container">
                                <label className="form-label">Block 1 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {b1Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setB1Content(b1Content => b1Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        b1Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setB1Content([...b1Content, b1Line])
                                    }}>Hinzufügen</button>
                                </div>
                                </div>

                                </div>

                                {/* Section 4 */}
                                <div className="section-container">

                                <div className="content-container">
                                <label className="form-label">Block 2 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    defaultValue={props.b2Title}
                                    onChange={(e) => {
                                        b2Title = e.target.value
                                    }}/>
                                </div>
                                </div>

                                
                                <div className="content-container">
                                <label className="form-label">Block 2 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {b2Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setB2Content(b2Content => b2Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        b2Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setB2Content([...b2Content, b2Line])
                                    }}>Hinzufügen</button>
                                </div>
                                </div>

                                </div>

                                {/* Section 5 */}
                                <div className="section-container">

                                <div className="content-container">
                                <label className="form-label">Block 3 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    defaultValue={props.b3Title}
                                    onChange={(e) => {
                                        b3Title = e.target.value
                                    }}/>
                                </div>
                                </div>

                                <div className="content-container">
                                <label className="form-label">Block 3 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {b3Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setB3Content(b3Content => b3Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        b3Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setB3Content([...b3Content, b3Line])
                                    }}>Hinzufügen</button>
                                </div>
                                </div>

                                </div>
                                {/* Section 6 */}
                                <div className="section-container">

                                <div className="content-container">
                                <label className="form-label">Block 4 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    defaultValue={props.b4Title}
                                    onChange={(e) => {
                                        b4Title = e.target.value
                                    }}/>
                                </div>
                                </div>

                                <div className="content-container">
                                <label className="form-label">Block 4 - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Hier kommt der Inhalt hin"
                                    defaultValue={props.b4Content}
                                    onChange={(e) => {
                                        b4Content = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                                </div>

                                </div>
                                
                                <div className="section-container">
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-barrierefreiheit"
                                    defaultChecked={props.benefits.includes("Barrierefreiheit")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Barrierefreiheit")} />
                                    <label htmlFor='check-barrierefreiheit'>Barrierefreiheit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-coaching" 
                                    defaultChecked={props.benefits.includes("Coaching")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Coaching")} />
                                    <label htmlFor='check-coaching'>Coaching</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-essenszuschuss"
                                    defaultChecked={props.benefits.includes("Essenszuschuss")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Essenszuschuss")} />
                                    <label htmlFor='check-essenszuschuss'>Essenszuschuss</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-flexarb" 
                                    defaultChecked={props.benefits.includes("flexarb")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "flexarb")} />
                                    <label htmlFor='check-flexarb'>Flexible Arbeitszeit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundmngmt" 
                                    defaultChecked={props.benefits.includes("Gesundmngmt")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundmngmt")} />
                                    <label htmlFor='check-gesundmngmt'>Gesundheitsmanagement</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundcent" 
                                    defaultChecked={props.benefits.includes("Gesundcent")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundcent")} />
                                    <label htmlFor='check-gesundcent'>Gesundheitszentrum</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-frei" 
                                    defaultChecked={props.benefits.includes("Freizeit")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Freizeit")} />
                                    <label htmlFor='check-frei'>Hoher Freizeitwert</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-ho" 
                                    defaultChecked={props.benefits.includes("Homeoffice")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Homeoffice")} />
                                    <label htmlFor='check-ho'>Homeoffice</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-international" 
                                    defaultChecked={props.benefits.includes("International")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "International")} />
                                    <label htmlFor='check-international'>Internationales Umfeld</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-jobticket" 
                                    defaultChecked={props.benefits.includes("Landesjugendticket")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Landesjugendticket")} />
                                    <label htmlFor='check-jobticket'>Landesjugendticket</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kantine" 
                                    defaultChecked={props.benefits.includes("Kantine")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Kantine")} />
                                    <label htmlFor='check-kantine'>Kantine</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kita" 
                                    defaultChecked={props.benefits.includes("Kita")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Kita")} />
                                    <label htmlFor='check-kita'>Kita</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-parkplatz" 
                                    defaultChecked={props.benefits.includes("Parkplatz")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Parkplatz")} />
                                    <label htmlFor='check-parkplatz'>Parkplatz</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-sonder" 
                                    defaultChecked={props.benefits.includes("Sonderzahlungen")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Sonderzahlungen")} />
                                    <label htmlFor='check-sonder'>Sonderzahlungen</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-work_life" 
                                    defaultChecked={props.benefits.includes("Work_Life")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Work_Life")} />
                                    <label htmlFor='check-work_life'>Work Life Balance</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-zusatz_quali" 
                                    defaultChecked={props.benefits.includes("Zusatz_Quali")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Zusatz_Quali")} />
                                    <label htmlFor='check-zusatz_quali'>Zusatzqualifikationen</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kleidung" 
                                    defaultChecked={props.benefits.includes("Arbeitskleidung")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Arbeitskleidung")} />
                                    <label htmlFor='check-kleidung'>Arbeitskleidung</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-uebernahme" 
                                    defaultChecked={props.benefits.includes("Uebernahme")}
                                    onClick={(e) => ManageBenefitArr(e.target.checked, "Uebernahme")} />
                                    <label htmlFor='check-uebernahme'>Hohe Übernahmequote</label>
                                </div>
                            </div>
                            <div className="content-actions">
                                <button className="btn btn-success" onClick={() => UpdateData()}>Speichern</button>
                                {props.archived === false ?
                                    <button type="button" className="btn btn-warning" onClick={() => ArchiveData()}>Archivieren</button>
                                    :
                                    <button type="button" className="btn btn-warning" onClick={() => ArchiveData()}>Zurückholen</button>
                                }
                                <button type="button" className="btn btn-danger" onClick={() => DeleteData()}>Löschen</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <button className={containerClass} onClick={() => setPopup(true)}>
                <div className="container-section">
                    <p>{props.id}</p>
                </div>
                <div className="container-section">
                    <p>{props.title}</p>
                </div>
                <div className="container-section">
                    <p>{props.type}</p>
                </div>
                <div className="container-section">
                    <p>{props.creationDate}</p>
                </div>
                <div className="container-section">
                    <p>{props.creator}</p>
                </div>
            </button>
            <EditPraktikum />
    
        </>
    )
}