import firebase from "firebase";
// import React, { createContext, FC, useEffect, useState } from "react";

var firebaseConfig;

switch (process.env.NODE_ENV) {
  case "production":
    // DEV
    // Your web app's Firebase configuration for development
    firebaseConfig = {
      apiKey: "AIzaSyCNa1it1mh0H_4N_auMpDnQO2hLWSvnRu4",
      authDomain: "azubi-homepage-dev-1.firebaseapp.com",
      databaseURL: "https://azubi-homepage-dev-1.firebaseio.com",
      projectId: "azubi-homepage-dev-1",
      storageBucket: "azubi-homepage-dev-1.appspot.com",
      messagingSenderId: "328071205380",
      appId: "1:328071205380:web:b2a8bff599a6453a679b51",
      measurementId: "G-6PLV4FDP1B",
    };
    break;
  case "development":
        // PROD
    // Your web app's Firebase configuration for production
    firebaseConfig = {
      apiKey: "AIzaSyCVweh36IuBQ1jaWmNz3cH7sDVgDfpQl1E",
      authDomain: "azubi-homepage-prod-1-66fbb.firebaseapp.com",
      databaseURL: "https://azubi-homepage-prod-1-66fbb-default-rtdb.firebaseio.com",
      projectId: "azubi-homepage-prod-1-66fbb",
      storageBucket: "azubi-homepage-prod-1-66fbb.appspot.com",
      messagingSenderId: "1089852776139",
      appId: "1:1089852776139:web:bdf679734a9ee554f1854e",
      measurementId: "G-DXQXBRMYJX"
    };

    break;
  case "test":
        // PROD
    // Your web app's Firebase configuration for production
    firebaseConfig = {
      apiKey: "AIzaSyCVweh36IuBQ1jaWmNz3cH7sDVgDfpQl1E",
      authDomain: "azubi-homepage-prod-1-66fbb.firebaseapp.com",
      databaseURL: "https://azubi-homepage-prod-1-66fbb-default-rtdb.firebaseio.com",
      projectId: "azubi-homepage-prod-1-66fbb",
      storageBucket: "azubi-homepage-prod-1-66fbb.appspot.com",
      messagingSenderId: "1089852776139",
      appId: "1:1089852776139:web:bdf679734a9ee554f1854e",
      measurementId: "G-DXQXBRMYJX"
    };
    break;
  default:
        // PROD
    // Your web app's Firebase configuration for production
    firebaseConfig = {
      apiKey: "AIzaSyCVweh36IuBQ1jaWmNz3cH7sDVgDfpQl1E",
      authDomain: "azubi-homepage-prod-1-66fbb.firebaseapp.com",
      databaseURL: "https://azubi-homepage-prod-1-66fbb-default-rtdb.firebaseio.com",
      projectId: "azubi-homepage-prod-1-66fbb",
      storageBucket: "azubi-homepage-prod-1-66fbb.appspot.com",
      messagingSenderId: "1089852776139",
      appId: "1:1089852776139:web:bdf679734a9ee554f1854e",
      measurementId: "G-DXQXBRMYJX"
    };
    break;
}

console.log(process.env.NODE_ENV)

// Configure
let fb = firebase.initializeApp(firebaseConfig, "[SECOND]");
let db = fb.firestore();
let auth = fb.auth();

// [START auth_state_listener]
// auth.onAuthStateChanged((user) => {
//     if (user) {
//         var uid = user.uid;
//         localStorage.setItem("uid", uid);
//         console.log("RES:",auth)
//     } else {
//         localStorage.removeItem("uid");
//     }
// });
// [END auth_state_listener]


export { db, fb, auth };

