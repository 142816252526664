import React, { useEffect } from 'react'
import { useState } from 'react';
// import { Link } from 'react-router-dom';


//SCSS
import './Slideshow.scss'

const Slideshow = (props) => {

  const { slides } = props;
  const filteredSlides = [];
  slides.map((index, i) => {
    if(index.show === true) {
      filteredSlides.push(index)
    }
    else {
      return null;
    }
  })
  const scrollDown = () => {
    var element = document.getElementById("neuigkeiten_startseite");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="arrow" className="carousel slide container-fluid" data-ride="carousel">

      <button id="scrollButton" onClick={scrollDown}>
        {/* Das SVG muss noch durch ein Bild ersetzt werden, welches auf Firebase gespeichert werden muss und das CSS muss dementsprechend angepasst werden. 
                Der Butten verschwindet ab einer geringeren Beite als 1100px. Das passt vielleicht noch nicht*/}
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-chevron-double-down animated bounce" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
        </svg>

        {/*alter Button     
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-chevron-down animated bounce" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg> */}
      </button>

      <ol className="carousel-indicators">
        {filteredSlides && filteredSlides.map((slide, i) => {
          return (
            <li key={`${slide.title}-${i}`} className={i === 0 ? "active" : " "} data-target="#arrow" data-slide-to={i}></li>
          )
        })}
      </ol>
      <div className="carousel-inner" role="listbox">
        {filteredSlides && filteredSlides.map((slide, i) => {
          

          //  var style = {
          //   src:`url(${slide.img})`,
          //   background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${slide.img}) no-repeat center center fixed`,
          //   height: "100vh",
          //   position: "relative",
          //   backgroundPosition: "center center",
          //   backgroundAttachment: "fixed",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "cover",
          // };

         

          if (slide.link === '') {
              return (
                <div key={`${slide.title}-${i}`} className={i === 0 ? "blogpost-slide active carousel-item text-center" : "carousel-item blogpost-slide text-center"}  >
                  {/* <div className="d-block w-100" style={style}></div> */}
                  <img height="auto" width="100%" src={slide.imageUrl} alt={slide.title} />
                  <div className="overlay">
                    <div className="carousel-caption d-none d-md-block ">
                      <div className="colorbox">
                        <h1 className="titleh1">{slide.title}</h1>
                        <h4 className="titleh2">{slide.subtitle}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              )
          } else {
              return (
              <div key={`${slide.title}-${i}`} className={i === 0 ? "blogpost-slide active carousel-item text-center" : "carousel-item blogpost-slide text-center"}  >
                {/*  */}
                {/* <div className="d-block w-100" style={style}></div> */}
                <a href={slide.link}>
                  <img height="auto" width="100%" src={slide.imageUrl} alt={slide.title} />
                  <div className="overlay">
                    <div className="carousel-caption d-none d-md-block ">
                      <div className="colorbox">
                        <h1 className="titleh1">{slide.title}</h1>
                        <h4 className="titleh2">{slide.subtitle}</h4>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
                )
              }
        })}
      </div>
      <a className="carousel-control-prev" href="#arrow" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#arrow" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div >
  )

}

export default Slideshow