/**
 * @file: Evaluation.jsx
 * @description: Contains the Evaluation Page
 */

import './Evaluation.scss';

export default function Evaluation() {
    return (
        <div id="evaluation-container">
            <iframe
            height={900}
            frameborder={0} src="https://app.smartsheet.com/b/publish?EQBCT=ce10ac045cfe46f9b853e798a308aa49"></iframe>
        </div>
    )
}