import React from 'react'
import './Sidenav.scss'

const Sidenav = (props) => {
    var a = [];
    var counter = 0;
    var counter2 = 0;
    var date = [];
    function getDatum(e) {
        a[counter] = e.date.substr(0, 4);
        counter = counter + 1;
    }
    function removeDuplicates(data) {
        data.sort(function (a, b) { return b - a });
        let unique = [];
        data.forEach(element => {
            if (!unique.includes(element)) {
                unique.push(element)
            }
        });
        a = unique;
    }
    function getTitleId(titel_id) {
        titel_id = titel_id.split(" ").join("");
        titel_id = titel_id.split(".").join("_");
        return titel_id;
    }

    function scrollToArticle(event, e) {
        event.preventDefault();
        let section = document.getElementById(e);
        let pos = getOffset(section) - 70;
        window.scrollTo({ top: pos, left: 0, behavior: "smooth" });
    }

    function getOffset(el) {
        var _y = 0;
        while (el && !isNaN(el.offsetTop)) {
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return _y;
    }

    return (
        <nav className="sticky-top" id="sidebar">
            <div className="sidebar-header">
                <h3>NEWS</h3>
            </div>
            <ul className="list-unstyled components" id="articlelist">
                <li className="active">
                    {props.titles.map((item) => {
                        getDatum(item);
                        return <></>
                    })}
                    <div id={removeDuplicates(a)}></div>
                    {a.map((element, index) => {
                        date[counter2] = element;
                        counter2++;
                        return <div key={`${element}-${index}`}><a href={"#News" + element} data-toggle="collapse" aria-expanded="false">{element}<span className="down-caret"></span></a>
                            <ul className="collapse list-unstyled" id={"News" + element}>
                                {props.titles.map((item, index) => {
                                    if (item.date.substr(0, 4) === element) {
                                        var titel_id = getTitleId(item.title);
                                        titel_id = titel_id.replace(/[^\w\s]/gi, '')
                                        return <a key={`${item.title}-${index}`} href={"#" + titel_id} className="list-group-item nav-item" onClick={(e) => scrollToArticle(e, titel_id)}>{item.title}</a>
                                    }
                                    return <></>
                                })}
                            </ul>

                        </div>
                    })}
                </li>
            </ul>
        </nav>
    );
}

export default Sidenav