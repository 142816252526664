import React from "react";
import "./BlogPost.scss";
// import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useState } from "react";
import ImagePopup from "../../../ImagePopup/ImagePopup";

// var trigger = false;
var before = "";
var count = 1;

function Blogpost({ summary, title, imageUrl, order }) {
  var [trigger, settrigger] = useState(false);
  var [text, setText] = useState("");

  function handleClick(e) {
    if (e.target.alt === before) {
      count = count + 1;
      if (count === 2) {
        settrigger(true);
        setText("Noch 2 Clicks");
      }
      if (count === 3) {
        settrigger(true);
        setText("Noch 1 Click");
      }
      if (count === 4) {
        settrigger(true);

        if (order === 0) {
            setText("Herzlich Willkommen...")
        }
        if (order === 1) {
            setText("...auf der neuen...")
        }
        if (order === 2) {
            setText("...Azubi-Homepage !")
        }

        // setText(e.target.alt);
      }
    } else {
      count = 0;
    }
    before = e.target.alt;
  }

  {imageUrl ? (<></>):(imageUrl = "https://firebasestorage.googleapis.com/v0/b/azubi-homepage-dev-1.appspot.com/o/Ausbilder%2Fcdecd844-aae6-430e-8a2e-3741ecfaf7f8_leeres_Bild.PNG?alt=media&token=433c647a-f7e6-4066-8cde-a8b14c746be8" ) }

  if (order <= 2) {
    return (
      <div className="card-group col-lg-4 col-md-6 col-sm-12">
        <div className="card mb-4 blogpost">
          {trigger === false ? (
            <ImagePopup 
              className="card-img-top"
              src={imageUrl}
              alt={title}/>
          ) : (
            <div
              // delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>{text}</Tooltip>}
              placement="top"
            >
              <ImagePopup 
                className="card-img-top"
                src={imageUrl}
                alt={title}
                id={order} />
            </div>
          )}

          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{summary}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card-group col-lg-4 col-md-6 col-sm-12">
      <div className="card mb-4 blogpost">
    <>
    <ImagePopup className="card-img-top" src={imageUrl} alt={title} />
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <p className="card-text">{summary}</p>
    </div></>
      </div>
      </div>
  );
    
}
export default Blogpost;


