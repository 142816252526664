import React from 'react';
import './Berufdesmonats.scss';
import ReactHtmlParser from 'react-html-parser';

const Berufdesmonats = (props) => {
    return (
        <div className="container-fluid">
            <h1>Beruf des Monats</h1>
            <div className="card">
                <div id="cardBody" className="card-body row">
                    <div className="titleberuf">
                        <h1 className="titletxt">{props.beruf.title}</h1>
                    </div>

                    <div className="content_karr">
                        <img className="imgComicBeruf" src={props.beruf.imageUrl} alt={props.beruf.title} />
                        {ReactHtmlParser(props.beruf.content)}
                        <br />
                        <div className="details_kar">
                            {ReactHtmlParser(props.beruf.content2)}

                        </div>
                        <br />


                        {props.beruf.linkBewerbung ? (
                        <div>

                            <a href={props.beruf.linkBewerbung}>
                                <div className="ButtonBewerbungHomepage">
                                   <h2>Bewirb Dich jetzt !</h2>
                                </div>
                            </a>

                        </div>
                    ) : (
                        <div>

                        </div>
                    )}

                    </div>



                </div>
            </div>




        </div >
    )
}

export default Berufdesmonats