import React from "react";
import {fb} from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
import "./Dashboard.scss";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.unsubscribe = null;
    this.state = {
      iframe: [],
      currentPage: "",
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const iframe = [];

    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === "dashboards") {
        const { iframeTitle, iframeLink, sort } = doc.data();
        iframe.push({
          key: doc.id,
          doc,
          iframeTitle,
          iframeLink,
          sort,
        });
      }
    });

    this.setState({
      iframe,
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  componentWillUnmount() {
    this.unsubscribe()
  }
  
  changePage = (str) => {
    //Disable everything else
    this.state.iframe.map((ifrm) => {
      document.getElementById(ifrm.iframeTitle).classList = ["nav-item"];
      return <></>;
    });

    document.getElementById(str).classList.add("active");
    // document.getElementById(str).classList.add("border-style");
    this.setState({ currentPage: str });
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <BackendMenuToggle />
          <div className="container-fluid">
            <div
              className="navbar sticky-top navbar-expand-lg navbar-light bg-light "
            >
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive2"
                aria-controls="navbarResponsive2"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive2">
                <ul className="nav nav-tabs">
                  {this.state.iframe.map((ifrm, index) => {
                    return (
                      <li key={`${ifrm.iframeTitle}-${index}`}  className="nav-item " id={ifrm.iframeTitle}>
                        <p
                          onClick={(e) => {
                            this.changePage(ifrm.iframeTitle);
                          }}
                          className="nav-link"
                          aria-current="page"
                        >
                          {ifrm.iframeTitle}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="tab-content" id="nav-tabContent">
              {this.state.iframe.map((ifrm, index) => {
                if (this.state.currentPage === ifrm.iframeTitle) {
                  return (
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      key={`${ifrm.iframeTitle}-${index}`}
                    >
                      <div className="container-fluid">
                        <div
                          className="embed-responsive embed-responsive-4by3"
                          allowFullScreen
                        >
                          <iframe
                            title={ifrm.iframeTitle}
                            src={ifrm.iframeLink}
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <></>
                    </div>
                  );
                } else {
                  <></>;
                }
                return <></>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
