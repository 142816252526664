/**
 * @file: addPraktikumBeta.jsx
 * @description: contains a Beta version for the add Component
 */
import { useEffect, useRef } from 'react';
import { useState, useContext } from 'react';
import { UserContext } from '../../../../';
import { fb } from '../../../../firebase';
import './addPraktikumBeta.scss';

export default function AddPraktikumBeta() {
    let [popup, setPopup] = useState(false);
    const userCont = useContext(UserContext);
    
    let name = "";
    let title = "";
    let country = "";
    let place = "";
    let department = "";
    let direction = "Technik";
    let type = "Praktikum";
    let link = "";
    let shortDesc = "";
    let shortDescHeader = "";
    let image = "imga";

    let benefitsArr = [];

    function ManageBenefitArr(status, name) {
        if(status === true) {
            benefitsArr.push(name);
        } else {
            benefitsArr = benefitsArr.filter(item => item !== name)
        }
    }

    let block1Title = "";

    let block1Line = "";
    let [block1Content, setBlock1Content] = useState([]);
    let [betaBlock1Content, betaSetBlock1Content] = useState([]);
    let block1Ref = useRef([]);

    function HandleBlock1Content() {
        setBlock1Content([...block1Content, block1Line])
    } 
    let block2Title = "";

    let block2Line = "";
    let [block2Content, setBlock2Content] = useState([]);

    let endB1Title = "";
    let endB1Content = "";
    let endB2Title = "";
    let endB2Content = "";

    function SaveData() {
        let pushObj = {
            archive: false,
            changedBy: "",
            changedDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
            createdBy: `${userCont?.firstname} ${userCont?.lastname}`,
            creationDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
            data: {
                benefits: benefitsArr,
                content: {
                    block1Content: block1Content,
                    block1Title: block1Title,
                    block2Content: block2Content,
                    block2Title: block2Title,
                    endB1Content: endB1Content,
                    endB1Title: endB1Title,
                    endB2Content: endB2Content,
                    endB2Title: endB2Title
                },
                country: country,
                department: department,
                direction: direction,
                img: image,
                link: link,
                name: name,
                postTitle: title,
                place: place,
                shortDesc: shortDesc,
                shortDescHeader: shortDescHeader,
                type: type
            }
        }
        fb.firestore().collection("studienpraktika").add(pushObj)
        window.alert('Artikel wurde erfolgreich angelegt!')
        setBlock1Content([])
        setBlock2Content([])
        setPopup(false)
    }

    function Block1MainContent() {
        return (
            <div className="content-container">
                <label className="form-label">Block 1 - Spiegelstriche:</label>
                <div className="content-list-items">
                    {block1Content.map((index, i) => {
                        return (
                            <div key={index} className="content-list-item">
                                <span>{index}</span>
                                <button onClick={() => {
                                    setBlock1Content(block1Content => block1Content.filter(item => item !== index))
                                }}>&#9986;</button>
                            </div>
                        )
                    })}
                </div>
                <div className=" input-group mb-3">
                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                        block1Line = e.target.value
                    }}/>
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                        setBlock1Content([...block1Content, block1Line]);
                    }}>Hinzufügen</button>
                </div>
            </div>
        )
    }

    function PopupWindow() {
        if(popup === true) {
            return (
                <div className="add-praktikum-popup">
                    <div className="add-praktikum-main-window">
                        <div className="main-window-header">
                            <div>
                                <h1>Eintrag anlegen</h1>
                            </div>
                            <div>
                                <button className="close-btn" onClick={() => {
                                    setPopup(false)
                                    setBlock1Content([])
                                    setBlock2Content([])
                                }}>&#10006;</button>
                            </div>
                        </div>

                        <div className="main-window-sections">

                            {/* Section 1 */}
                            <div className="section-container">
                                <div className="content-container">
                                    <label className="form-label">Titel (Jobboard Liste):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        onChange={(e) => {
                                            name = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Titel (Jobbeschreibung):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        onChange={(e) => {
                                            title = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Land:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Deutschland"
                                        onChange={(e) => {
                                            country = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Ort:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Loßburg"
                                        onChange={(e) => {
                                            place = e.target.value
                                        }}/>
                                    </div>
                                </div>
                            
                            

                                <div className="content-container">
                                    <label className="form-label">Abteilung:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Abteilungsname"
                                        onChange={(e) => {
                                            department = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Link:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Link"
                                        onChange={(e) => {
                                            link = e.target.value
                                        }}/>
                                    </div>
                                </div>
                                
                                <div className="content-container">
                                    <label className="form-label">Art:</label>
                                    <select onChange={(e) => {
                                        type = e.target.value;
                                    }}>
                                        <option value={"Praktikum"} defaultValue>Praktikum</option>
                                        <option value={"Abschlussarbeit"}>Abschlussarbeit</option>
                                        <option value={"Technikerarbeit"}>Technikerarbeit</option>
                                        <option value={"Umschulung"}>Umschulung</option>
                                        <option value={"Werkstudierende"}>Werkstudierende</option>
                                    </select>
                                </div>
                                
                                <div className="content-container">
                                    <label className="form-label">Fachrichtung:</label>
                                    <select onChange={(e) => {
                                        direction = e.target.value;
                                    }}>
                                        <option value={"Technik"} defaultValue>Technik</option>
                                        <option value={"Informatik"}>Informatik</option>
                                        <option value={"Wirtschaft"}>Wirtschaft</option>
                                    </select>
                                </div>

                                <div className="content-container">
                                        <label className="form-label">Hintergrund:</label>
                                        <select onChange={(e) => {
                                            image = e.target.value;
                                        }}>
                                            <option value={"imga"} defaultValue>Skater</option>
                                            <option value={"imgb"}>Balance</option>
                                        </select>
                                </div>

                            </div>
                                

                            {/* Section 2 */}
                            <div className="section-container">
                                
                            <div className="content-container">
                                <label className="form-label">Preview - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        shortDescHeader = e.target.value
                                    }}/>
                                </div>
                            </div>
                            
                            <div className="content-container">
                                <label className="form-label">Preview - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Kurze Einleitung"
                                    onChange={(e) => {
                                        shortDesc = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                            </div>
                            
                            </div>
                            {/* Section 3 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 1 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        block1Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                                  
                            <Block1MainContent />
                            
                            </div>
                            
                            {/* Section 4 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 2 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        block2Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                                  
                            <div className="content-container">
                                <label className="form-label">Block 2 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {block2Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setBlock2Content(block2Content => block2Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        block2Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setBlock2Content([...block2Content, block2Line])
                                    }}>Hinzufügen</button>
                                </div>
                            </div>

                            </div>

                            {/* Section 5 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Abschluss Block 1 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        endB1Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                            <div className="content-container">
                                <label className="form-label">Abschluss Block 1 - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Hier kommt der Inhalt hin"
                                    onChange={(e) => {
                                        endB1Content = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                            </div>
                            
                            </div>
                            {/* Section 6 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Abschluss Block 2 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        endB2Title = e.target.value
                                    }}/>
                                </div>
                            </div>
                            
                            <div className="content-container">
                                <label className="form-label">Abschluss Block 2 - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Hier kommt der Inhalt hin"
                                    onChange={(e) => {
                                        endB2Content = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                            </div>
                           
                            </div>
                            {/* Section 7 */}
                            <div className="section-container">
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-barrierefreiheit" onClick={(e) => ManageBenefitArr(e.target.checked, "Barrierefreiheit")} />
                                    <label htmlFor='check-barrierefreiheit'>Barrierefreiheit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-coaching" onClick={(e) => ManageBenefitArr(e.target.checked, "Coaching")} />
                                    <label htmlFor='check-coaching'>Coaching</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-essenszuschuss" onClick={(e) => ManageBenefitArr(e.target.checked, "Essenszuschuss")} />
                                    <label htmlFor='check-essenszuschuss'>Essenszuschuss</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-flexarb" onClick={(e) => ManageBenefitArr(e.target.checked, "flexarb")} />
                                    <label htmlFor='check-flexarb'>Flexible Arbeitszeit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundmngmt" onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundmngmt")} />
                                    <label htmlFor='check-gesundmngmt'>Gesundheitsmanagement</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundcent" onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundcent")} />
                                    <label htmlFor='check-gesundcent'>Gesundheitszentrum</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-frei" onClick={(e) => ManageBenefitArr(e.target.checked, "Freizeit")} />
                                    <label htmlFor='check-frei'>Hoher Freizeitwert</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-ho" onClick={(e) => ManageBenefitArr(e.target.checked, "Homeoffice")} />
                                    <label htmlFor='check-ho'>Homeoffice</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-international" onClick={(e) => ManageBenefitArr(e.target.checked, "International")} />
                                    <label htmlFor='check-international'>Internationales Umfeld</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-jobticket" onClick={(e) => ManageBenefitArr(e.target.checked, "Jobticket")} />
                                    <label htmlFor='check-jobticket'>Jobticket</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kantine" onClick={(e) => ManageBenefitArr(e.target.checked, "Kantine")} />
                                    <label htmlFor='check-kantine'>Kantine</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kita" onClick={(e) => ManageBenefitArr(e.target.checked, "Kita")} />
                                    <label htmlFor='check-kita'>Kita</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-parkplatz" onClick={(e) => ManageBenefitArr(e.target.checked, "Parkplatz")} />
                                    <label htmlFor='check-parkplatz'>Parkplatz</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-weiter" onClick={(e) => ManageBenefitArr(e.target.checked, "Weiter")} />
                                    <label htmlFor='check-weiter'>Weiterentwicklung</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-wohngeld" onClick={(e) => ManageBenefitArr(e.target.checked, "Wohngeld")} />
                                    <label htmlFor='check-wohngeld'>Wohngeldzuschuss</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-work_life" onClick={(e) => ManageBenefitArr(e.target.checked, "Work_Life")} />
                                    <label htmlFor='check-work_life'>Work Life Balance</label>
                                </div>
                            </div>

                            {/* Section 8 */}
                            <div className="content-actions">
                                <button className='btn btn-success' onClick={() => SaveData()}>Speichern</button>
                            </div>

                        </div>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
    return (
        <>
            <button className="add-praktikum-btn" onClick={() => setPopup(true)}>&#10010;</button>
            <PopupWindow />
        </>
    )
}