import React from 'react'
import Posttitle from '../../../components/OnePager/Posttitle/Posttitle';
import Sidenav from '../../../components/OnePager/Sidenav/Sidenav';
import {fb} from '../../../firebase';
// import Footer from "../../../components/Footer/Footer";
// SCSS
import './NewsOnepager.scss';
// import { wait } from '@testing-library/dom';

class NewsOnepager extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection('fl_content');
    this.unsubscribe = null;
    this.state = {
      articles: [],
    };
  }
  onCollectionUpdate = (querySnapshot) => {
    const articles = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === "artikel") {
        const { summary, title, content, date, tags, author } = doc.data();
        articles.push({
          key: doc.id,
          doc, // DocumentSnapshot
          summary,
          title,
          content,
          date,
          tags,
          author,
        });
      }
    });
    this.setState({
      articles
    });
  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidUpdate() {
    //----------------------------------------------------------//
    //***Change anchors and images and enable modal functions***//
    //----------------------------------------------------------//
    let images = document.getElementById("content").getElementsByTagName("img");
    let anchors = document.getElementById("content").getElementsByTagName("a");
    let modalSpan = document.getElementById("modal-span");
    let modal = document.getElementById("imgModal");
    modal.onclick = function () {
      modal.style.display = "none";
      document.body.style.overflowY = "auto";
      document.body.style.paddingRight = "0";
    }
    modalSpan.onclick = function () {
      modal.style.display = "none";
      document.body.style.overflowY = "auto";
      document.body.style.paddingRight = "0";
    }
    for (let index = 0; index < anchors.length; index++) {
      if (anchors[index].hasChildNodes) {
        anchors[index].onclick = function () {
          return false;
        }
      }

    }
    for (let index = 0; index < images.length; index++) {
      if (images[index].id !== "modal-image") {
        images[index].onclick = function () {
          document.getElementById('modal-image').setAttribute('src', images[index].src);
          document.getElementById('imgModal').style.display = 'block';
          document.body.style.overflowY = 'hidden';
          document.body.style.paddingRight = '15px';
        }
      }
    }
    document.getElementById("header-navbar").style.display = "flex";
  }

  render() {
    var date = [];
    var counter = 0;
    var counter2 = 0;
    var articles2 = [];

    function sortArticles(articles) {
      articles.map((article) => {
        date[counter] = article.date.replace(":", '').replace(":", '').replace(":", '').replace("-", '').replace("-", '').replace("T", '').replace("+", '')
        counter++;
        return null;
      })
      date.sort(function (a, b) { return b - a });
      let unique = [];
      date.forEach(element => {
        if (!unique.includes(element)) {
          unique.push(element)
        }
      });
      date = unique;
      date.map((datum) => {
        articles.map((element) => {
          if (element.date.replace(":", '').replace(":", '').replace(":", '').replace("-", '').replace("-", '').replace("T", '').replace("+", '') === datum) {
            articles2[counter2++] = element;

          }
          return element;
        })
        return datum;
      })
    }

    function handleClick(e) {
      var sidebar = document.getElementById("sidebar");
      if (sidebar.classList.contains("active")) {
        sidebar.classList.remove("active");
        e.target.classList.remove("active");
      } else {
        sidebar.classList.add("active");
        e.target.classList.add("active");
      }
    }

    function backToTop() {
      document.getElementById("header-navbar").style.display = "flex";
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    let tick;
    function setCurrentTitle() {
      toggleHeaderNavbar();
      let currentArticle = document.getElementsByClassName("list-group-item nav-item active");
      let headline = document.getElementById("artikeltitel")
      if (currentArticle[0] !== undefined) {
        if (currentArticle[0].innerHTML !== null) {
          if (headline.innerHTML !== currentArticle[0].innerHTML) {
            headline.style.opacity = "0";
          }
          if (!tick) {
            setTimeout(function () {
              if (currentArticle[0] !== undefined) {
                if (currentArticle[0].innerHTML !== null) {
                  headline.innerHTML = currentArticle[0].innerHTML;
                }
              }
              headline.style.opacity = "1";
              tick = false;

            }, 600);
          }
          tick = true;
        }
      }
    }

    function toggleHeaderNavbar() {
      if (document.getElementById("content") !== null) {
        if (document.getElementById("header-navbar").style.display === "flex") {
          if (document.getElementById("header-navbar").getBoundingClientRect().y < -62) {
            document.getElementById("header-navbar").style.display = "none";
          }
        } else if (document.getElementById("content").getBoundingClientRect().y === 0) {
          document.getElementById("header-navbar").style.display = "flex";
        }
      }
    }

    window.addEventListener("scroll", setCurrentTitle);
    sortArticles(this.state.articles);
    return (
      <div className="wrapper homeContainer">
        <Sidenav titles={articles2} />
        <div id="content">

          {/*Start of Modal*/}
          <div id="imgModal" className="imgModal">
            <span className="close" id="modal-span">&times;</span>
            <img className="imgModal-content" alt="Artikel Bild" id="modal-image"></img>
          </div>
          {/*End of Modal*/}

          
          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom sticky-top navbar-top">
            <table className="topnav-table">
              <tbody>
                <tr>
                  <td>
                    <input type="checkbox" onClick={handleClick} id="sidebarCollapse" className="navbar-btn"></input>
                    <label htmlFor="sidebarCollapse" className="sidebarCollapse">
                      <span className="line" key="s1"> </span>
                      <span className="line" key="s2"> </span>
                      <span className="line" key="s3"> </span>
                    </label>
                  </td>
                  <td>
                    <h3 id="artikeltitel" className="titleTransition">Artikeltitel</h3>
                  </td>
                  <td>
                    <button onClick={backToTop} id="toTopButton"><table><tbody><tr><td>Top</td><td><b>⇧</b></td></tr></tbody></table></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </nav>

          
          {articles2.map((article, index) => {
            counter2++;
            return <Posttitle key={`${article.title}-${index}`} summary={article.summary} title={article.title} content={article.content} author={article.author} date={article.date} tags={article.tags} />
          })}
          </div>
        </div>



    )
  }
}
export default NewsOnepager
