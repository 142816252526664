/**
 * @file: StudienpraktikaManager.jsx
 * @description: Manager Seite für Studienpraktika
 */

import Praktikacontainer from "./praktikacontainer/praktikacontainer"
import './StudienpraktikaManager.scss'
import ArburgLogo from '../../../ressources/Navbar/Arburg_Logo.svg';
import { Link } from "react-router-dom";
// import AddPraktikum from "./AddPraktikum/AddPraktikum";
import { fb } from "../../../firebase";
import { useState, useEffect } from "react";
import AddPraktikumBeta from "./AddPraktikumBeta/addPraktikumBeta";


export default function StudienpraktikaManager() {
    let [praktiData, setPraktiData] = useState([]);

      useEffect(() => {
        let getPraktiData = fb.firestore().collection('studienpraktika');
      
        getPraktiData.onSnapshot((data) => {
            let content = []
            data.forEach((doc) => {
                content.push({
                    id: doc.id,
                    creator: doc.data().createdBy,
                    creationDate: doc.data().creationDate,
                    archive: doc.data().archive,
                    changedBy: doc.data().changedby,
                    changedDate: doc.data().changedDate,
                    data: {
                        title: doc.data().data.postTitle,
                        name: doc.data().data.name,
                        link: doc.data().data.link,
                        department: doc.data().data.department,
                        description: doc.data().data.description,
                        content: doc.data().data.content,
                        place: doc.data().data.place,
                        country: doc.data().data.country,
                        type: doc.data().data.type,
                        direction: doc.data().data.direction,
                        img: doc.data().data.img,
                        shortDesc: doc.data().data.shortDesc,
                        shortDescHeader: doc.data().data.shortDescHeader,
                        benefits: doc.data().data.benefits,
                        content: {
                            block1Title: doc.data().data.content.block1Title,
                            block1Content: doc.data().data.content.block1Content,
                            block2Title: doc.data().data.content.block2Title,
                            block2Content: doc.data().data.content.block2Content,
                            endB1Title: doc.data().data.content.endB1Title,
                            endB1Content: doc.data().data.content.endB1Content,
                            endB2Title: doc.data().data.content.endB2Title,
                            endB2Content: doc.data().data.content.endB2Content
                        }
                    }
                })
            })
            setPraktiData(content)
          })
      }, [])
    return (
        <div>
            <div className="praktikum-header">
                <div>
                    <Link to="/">
                        <img src={ArburgLogo} width="125"/>
                    </Link>
                </div>
                <div className="nav-link">
                    <Link to="/">Home</Link> 
                    <Link to="/Jobboard/hs">Jobboard</Link>
                </div>
            </div>
            <div className="praktika-list">
                <div className="list-header">
                    <div className="header-section">
                        <h2>ID</h2>
                    </div>
                    <div className="header-section">
                        <h2>Name</h2>
                    </div>
                    <div className="header-section">
                        <h2>Art</h2>
                    </div>
                    <div className="header-section">
                        <h2>Erstelldatum</h2>
                    </div>
                    <div className="header-section">
                        <h2>Ersteller</h2>
                    </div>
                </div>
                {praktiData.map((index, i) => {
                    return <Praktikacontainer 
                    key={index.id}
                    id={index.id}
                    archived={index.archive}
                    creator={index.creator}
                    creationDate={index.creationDate}
                    block1Title={index.data.content.block1Title}
                    block1Content={index.data.content.block1Content}
                    block2Title={index.data.content.block2Title}
                    block2Content={index.data.content.block2Content}
                    endB1Title={index.data.content.endB1Title}
                    endB1Content={index.data.content.endB1Content}
                    endB2Title={index.data.content.endB2Title}
                    endB2Content={index.data.content.endB2Content}
                    department={index.data.department}
                    shortDesc={index.data.shortDesc}
                    shortDescHeader={index.data.shortDescHeader}
                    link={index.data.link}
                    name={index.data.name}
                    title={index.data.title}
                    place={index.data.place}
                    country={index.data.country}
                    type={index.data.type}
                    direction={index.data.direction}
                    image={index.data.img}
                    benefits={index.data.benefits} />
                })}
            </div>
            <AddPraktikumBeta />
            {/* <AddPraktikum /> */}
        </div>
    )
}