import React from 'react'
import Slideshow from '../../components/Homepage/Slideshow/Slideshow';
import {fb} from '../../firebase';
import Cardsslider from '../../components/Homepage/Cardsslider/Cardsslider';
import Berufdesmonats from '../../components/Homepage/Berufdesmonats/Berufdesmonats';
// import Info from '../../components/Info/Info.jsx';
//SCSS
import './Homepage.scss'



 class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          slideshow: [],
          articles: [],
          projektgruppen: [],
          ausbilder: [],
          infoleiste: [],
          berufdesmonats: [],
          neuigkeiten: [],
        };
      }
      onCollectionUpdate = (querySnapshot) => {
        const articles = [];
        const projektgruppen = [];
        const ausbilder = [];
        const infoleiste = [];
        const slideshow = [];
        const berufdesmonats = [];
        const neuigkeiten = [];
        var imageUrl = "";

        querySnapshot.forEach((doc) => {
          var schema;
          if (doc.data()._fl_meta_.schema === "artikel") {
            schema = doc.data()._fl_meta_.schema;
            const { summary, title, content, date, tags, author, imageUrlDev, imageUrlProd} = doc.data();
            
            if (process.env.NODE_ENV === "production") {
              imageUrl = imageUrlProd;
            } else {
              imageUrl = imageUrlDev;
            }
            articles.push({
                key: doc.id,
                schema,
                summary,
                title,
                content,
                date,
                tags,
                author,
                imageUrl,
              });

            }
            if (doc.data()._fl_meta_.schema === "slideshow") {
              schema = doc.data()._fl_meta_.schema;
              const { title,subtitle, link, imageUrlProd,imageUrlDev, order, show} = doc.data();


              if (process.env.NODE_ENV === "production") {
                imageUrl = imageUrlProd;
              } else {
                imageUrl = imageUrlDev;
              }

              slideshow.push({
                  key: doc.id,
                  schema,
                  title,
                  subtitle,
                  link,
                  imageUrl,
                  order, 
                  show
                });  
                
            }
            
            if (doc.data()._fl_meta_.schema === "projektgruppen") {
                schema = doc.data()._fl_meta_.schema;
                const { content,title,tags,imageUrlDev, imageUrlProd} = doc.data();
    
                if (process.env.NODE_ENV === "production") {
                  imageUrl = imageUrlProd;
                } else {
                  imageUrl = imageUrlDev;
                }

                projektgruppen.push({
                    key:doc.id,
                    schema,
                    content,
                    title,
                    tags,
                    imageUrl,
                  });
                }
                if (doc.data()._fl_meta_.schema === "neuigkeitenInDerAusbildung") {
                  schema = doc.data()._fl_meta_.schema;
                  const { sort,title,imageUrlDev, imageUrlProd, link, order, show} = doc.data();
      
                  if (process.env.NODE_ENV === "production") {
                    imageUrl = imageUrlProd;
                  } else {
                    imageUrl = imageUrlDev;
                  }
                  neuigkeiten.push({
                      key:doc.id,
                      schema,
                      title,
                      imageUrl,
                      sort,
                      link,
                      order,
                      show
                    });
                  }
            if (doc.data()._fl_meta_.schema === "ausbilder") {
              schema = doc.data()._fl_meta_.schema;
              const { summary, title, content, date, tags, author, imageUrlDev, imageUrlProd} = doc.data();

              if (process.env.NODE_ENV === "production") {
                imageUrl = imageUrlProd;
              } else {
                imageUrl = imageUrlDev;
              }

              ausbilder.push({
                  key: doc.id,
                  schema,
                  summary,
                  title,
                  content,
                  date,
                  tags,
                  author,
                  imageUrl,
                });  
            }
            if (doc.data()._fl_meta_.schema === "infoleiste") {
              schema = doc.data()._fl_meta_.schema;
              const { infoText, infoColor, infoBackgroundColor, infoLink} = doc.data();
              infoleiste.push({
                  key: doc.id,
                  schema,
                  infoText,
                  infoLink,
                  infoColor,
                  infoBackgroundColor
                });
            }
            if (doc.data()._fl_meta_.schema === "ausbildung") {
              const { title, content,content2, imageUrlDev, imageUrlProd, linkBewerbung, month} = doc.data();
              
              if (process.env.NODE_ENV === "production") {
                imageUrl = imageUrlProd;
              } else {
                imageUrl = imageUrlDev;
              }
              berufdesmonats.push({
                  key: doc.id,
                  schema,
                  title,
                  content,
                  imageUrl,
                  linkBewerbung,
                  content2,
                  month,
                });
                
              }
              
            });
            this.setState({
              // slideshow,
              articles,
              projektgruppen,
              ausbilder,
              infoleiste,
              berufdesmonats,
              // neuigkeiten,
           });


           this.setState( (prevState) => {

            return {
              ...prevState,
              slideshow: slideshow.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 0;
                }
              })
            }
      
          })

          this.setState( (prevState2) => {

            return {
              ...prevState2,
              neuigkeiten: neuigkeiten.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 0;
                }
              })
            }
      
          })




          }  
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
          }
          componentWillUnmount() {
            this.unsubscribe()
          }
          
 render(){


  
    return (
      <div>
        {/* {this.state.infoleiste.map((info) => {
          return<Info key={uuid()} infoText={info.infoText} infoColor={info.infoColor} infoBackgroundColor={info.infoBackgroundColor} infoLink={info.infoLink}/>
        })} */}
        <div className="container-fluid mt-2">
            {<Slideshow slides={this.state.slideshow}/>}
        </div>
         <div className="container-fluid mt-2" id="neuigkeiten_startseite">
            {<Cardsslider neuigkeiten={this.state.neuigkeiten}/>}
        </div> 
        <div className="container-fluid mt-2">
          {this.state.berufdesmonats.map((beruf, index) => {
            // console.log((new Date().getMonth()+1).toString())
            if(beruf.month === (new Date().getMonth()+1).toString()){
              return<Berufdesmonats key={`${beruf}-${index}`} beruf={beruf}/>
            }
            return <></>
            
          })}
        </div>
      </div>
    )
  }
}

export default Homepage
