import React from "react";
import { fb } from "../../firebase";
import ReactHtmlParser from "react-html-parser";

import "./Datenschutz.scss";

class Datenschutz extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.unsubscribe = null;
    this.state = {
      datenschutz: [],
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const datenschutz = [];
    querySnapshot.forEach((doc) => {
      // console.log(doc.data())
      // console.log(doc.data()._fl_meta_.schema)
      if (doc.data()._fl_meta_.schema === "datenschutz") {
        const { content, title } = doc.data();

        datenschutz.push({
          key: doc.id,
          doc,
          content,
          title,
        });
      }
    });
    this.setState({
      datenschutz,
    });
  };
  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  render() {
    //  console.log(this.state.datenschutz)
    const { datenschutz } = this.state;
    //  console.log(datenschutz)
    return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">



              {datenschutz &&
                datenschutz.map((daten, index) => {
                  // console.log(daten.content)
                  return (

                    <div key={`${daten.title}-${index}`}>
                      <h1 className="my-4">{daten.title}</h1>
                      <div className="card-group">
                        <div className="card blogpost">
                          <div className="card-body">
                            <p className="card-text">
                              {ReactHtmlParser(daten.content)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>


    );
  }
}
export default Datenschutz;
//id={element.key}
