import React from "react";
import BlogEntries from "../../components/Ausbilder/BlogEntries/BlogEntries.jsx";
import { fb } from "../../firebase";

//SCSS
import "./Ausbilder.scss";
class Ausbilder extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.unsubscribe = null;
    this.state = {
      ausbildungsleitung: [],
      kaufmaennisch: [],
      technisch: [],
    };
  }
  onCollectionUpdate = (querySnapshot) => {
    const kaufmaennisch = [];
    const ausbildungsleitung = [];
    const technisch = [];
    var imageUrl = "";

    querySnapshot.forEach((doc) => {
      if (
        doc.data()._fl_meta_.schema === "ausbilder" &&
        doc.data().bereich === "ausbildungsleitung"
      ) {
        const {
          summary,
          title,
          date,
          tags,
          author,
          imageUrlDev,
          imageUrlProd,
          // imageUrl,
          order,
        } = doc.data();

        if (process.env.NODE_ENV === "production") {
          imageUrl = imageUrlProd;
        } else {
          imageUrl = imageUrlDev;
        }

        ausbildungsleitung.push({
          key: doc.id,
          doc,
          summary,
          title,
          date,
          tags,
          author,
          imageUrl,
          order,
        });
      }

      if (
        doc.data()._fl_meta_.schema === "ausbilder" &&
        doc.data().bereich === "kaufmaennisch"
      ) {
        const {
          summary,
          title,
          date,
          tags,
          author,
          imageUrlProd,
          imageUrlDev,
          // imageUrl,
          order,
        } = doc.data();

        imageUrl = "";
        if (process.env.NODE_ENV === "production") {
          imageUrl = imageUrlProd;
        } else {
          imageUrl = imageUrlDev;
        }

        kaufmaennisch.push({
          key: doc.id,
          doc, // DocumentSnapshot
          summary,
          title,
          date,
          tags,
          author,
          imageUrl,
          order,
        });
      }

      if (
        doc.data()._fl_meta_.schema === "ausbilder" &&
        doc.data().bereich === "technisch"
      ) {
        const {
          summary,
          title,
          date,
          tags,
          author,
          imageUrlProd,
          imageUrlDev,
          order,
        } = doc.data();
        if (process.env.NODE_ENV === "production") {
          imageUrl = imageUrlProd;
        } else {
          imageUrl = imageUrlDev;
        }

        technisch.push({
          key: doc.id,
          doc,
          summary,
          title,
          date,
          tags,
          author,
          imageUrl,
          order,
        });
      }

      if (
        doc.data()._fl_meta_.schema === "ausbilder"
      ) {
        const {
          summary,
          title,
          date,
          tags,
          author,
          imageUrlProd,
          imageUrlDev,
          order,
        } = doc.data();
        if (process.env.NODE_ENV === "production") {
          imageUrl = imageUrlProd;
        } else {
          imageUrl = imageUrlDev;
        }
      }
    });

    this.setState({
      ausbildungsleitung,
      kaufmaennisch,
      technisch,
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  componentDidUpdate() {
    //----------------------------------------------------------//
    //***Change anchors and images and enable modal functions***//
    //----------------------------------------------------------//
    // let images = document.getElementsByClassName("col-md-10")[0].getElementsByTagName("img");
    // // console.log("Hallo")
    // // console.log(images)
    // let modalSpan = document.getElementById("modal-span");
    // let modal = document.getElementById("imgModal");
    // modal.onclick = function () {
    //   modal.style.display = "none";
    //   document.body.style.overflowY = "auto";
    //   document.body.style.paddingRight = "0";
    //   document.getElementById('header-navbar').style.display = 'flex';
    // }
    // modalSpan.onclick = function () {
    //   modal.style.display = "none";
    //   document.body.style.overflowY = "auto";
    //   document.body.style.paddingRight = "0";
    //   document.getElementById('header-navbar').style.display = 'flex';
    // }
    // for (let index = 0; index < images.length; index++) {
    //   if (images[index].id !== "modal-image") {
    //     images[index].onclick = function () {
    //       document.getElementById('modal-image').setAttribute('src', images[index].src);
    //       document.getElementById('imgModal').style.display = 'block';
    //       document.getElementById('header-navbar').style.display = 'none';
    //       document.body.style.overflowY = 'hidden';
    //       document.body.style.paddingRight = '15px';
    //     }
    //   }
    // }
    document.getElementById("header-navbar").style.display = "flex";
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          {/*Start of Modal*/}
          <div id="imgModal" className="imgModal">
            <span className="close" id="modal-span">
              &times;
            </span>
            <img
              className="imgModal-content"
              alt="Artikel Bild"
              id="modal-image"
            ></img>
          </div>
          {/*End of Modal*/}

          <div className="col-md-10">
            <h1 className="my-4">Ausbilder</h1>
            <h3>Ausbildungsleitung</h3>
            <BlogEntries ausbilder={this.state.ausbildungsleitung} />
            <h3>Kaufmännische, IT-Ausbildung & Studenten</h3>
            <BlogEntries ausbilder={this.state.kaufmaennisch} />
            <h3>Technische Ausbildung</h3>
            <BlogEntries ausbilder={this.state.technisch} />
          </div>
        </div>
      </div>
    );
  }
}

export default Ausbilder;
