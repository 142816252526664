import React from "react";
import "./Backend.scss";
import {fb} from '../../firebase'

class BackendMenuToggle extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("Wartung");

    this.state = {
      key: "",
      trigger: false,
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      this.setState({
        trigger: doc.data().trigger,
        key: doc.id,
      });
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe()
  }

  handleChange = (e) => {
    this.setState({
      trigger: e.target.checked,
    });

    e.preventDefault();

    const updateRef = fb
      .firestore()
      .collection("Wartung")
      .doc(this.state.key);
    updateRef
      .set({
        trigger: e.target.checked,
      })
      .then((docRef) => {
        // this.props.history.push("/Edit/" + this.props.match.params.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
  render() {
    var url = window.location.href;
    var laenge = url.search("#/") + 2;
    var page = url.slice(laenge);
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <button className="btn btn-primary" id="menu-toggle">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-justify"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mr-auto mt-2 mt-lg-0">
            <span className="navbar-brand h1">
              {page} der ARBURG Azubi Homepage
            </span>
          </ul>
          {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={this.state.trigger}
              onChange={this.handleChange}
              id="switch"
            />

            <label className="custom-control-label" htmlFor="switch">
              Wartung
            </label>
          </div> */}
        </div>
      </nav>
    );
  }
}

export default BackendMenuToggle;
