import React from 'react'
import {fb} from '../../firebase';
import Linktrees from '../../components/Linktree/Linktrees';

import InstagrammGrün from '../../ressources/Footer/Instagramm_grün.png';
import FacebookGrün from '../../ressources/Footer/facebook_grün.png';
import ARBURGAusbildungLogo from '../../ressources/Navbar/ARBURG_AUSBILDUNGLOGO.jpg'

//SCSS
 import './Linktree.scss';

 class Linktree extends React.Component {
     constructor(props){
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            linktree: [],
        };
     }

     onCollectionUpdate =(querySnapshot) =>{
         const linktree = [];
         var imageUrl= "";

         querySnapshot.forEach((doc) => {

            if(doc.data()._fl_meta_.schema === "linktree"){
                const { title, url, imageUrlDev, imageUrlProd, order } = doc.data();
                if (process.env.NODE_ENV === "production") {
                    imageUrl = imageUrlProd;
                  } else {
                    imageUrl = imageUrlDev;
                  }
                linktree.push({
                    key: doc.id,
                    doc,
                    title,
                    url,
                    imageUrl,
                    order,
                });
            }
         });
         this.setState({
            linktree
         });
     }

     componentDidMount() {
         this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
     }
     componentWillUnmount() {
        this.unsubscribe()
      }


 render(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-10">
                    <a href="../"><img alt="Arburg Ausbildung" className="LogoArburgTree" src={ARBURGAusbildungLogo}/></a>
                    <h1 className="my-4">Ausbildung & Studium @ ARBURG</h1>
                    <Linktrees linktree={this.state.linktree}></Linktrees>

                    <div className="socialmediabuttonsTree">
                        {/* <a href="https://www.arburg.com/">
                                 <img alt="Arburg Logo" className="socialmediaTreeArburgLogo" src={ARBURGLogo}></img>
                        </a> */}

                        <a href="https://de-de.facebook.com/arburgworld/" target="_blank" rel="noreferrer">
                                 <img alt="Facebook Logo" className="socialmediaTree" src={InstagrammGrün}></img>
                        </a>

                        <a href="https://www.instagram.com/arburg_rookies/?hl=de" target="_blank" rel="noreferrer">
                                <img alt="Instagram Logo" className="socialmediaTree" src={FacebookGrün}></img>
                        </a>
                    </div>
                    

                    <div className="copyrightTree">
                        <div className="copyrightTextTree">
                            <a href="/#/impressum">Impressum </a>
                            <a href="/#/datenschutz"> Datenschutz</a> <br/>

                            &copy; {new Date().getFullYear()} Copyright:{" "}
                            <a className="arburgCopyTree" href="https://www.arburg.com/">
                                {" "}
                                ARBURG GmbH + Co KG{" "}
                            </a>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
  }
}

export default Linktree