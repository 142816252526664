import React from 'react'
import { fb } from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

import './Impressum.scss'

class Impressum extends React.Component {

  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection('fl_content');
    this.unsubscribe = null;
    this.state = {
      impressum: [],
    };
  }

  onCollectionUpdate = (querySnapshot) => {

    const impressum = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === "impressum") {
        const { title, content } = doc.data();
        impressum.push({
          title,
          content,
        });
      }
    })

    this.setState({
      impressum
    });
  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {this.state.impressum.map((impressum, index) => {
                return (
                  <div key={`${impressum.title}-${index}`}>
                    <h1 className="my-4">{impressum.title}</h1>
                    <div className="card-group">
                      <div className="card blogpost">
                        <div className="card-body">
                          <p className="card-text">
                            {ReactHtmlParser(impressum.content)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>


    )
  }
}
export default Impressum
