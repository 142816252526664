/**
 * @file: navigation.jsx
 * @description: contains the navigation of the app
 */

import { Link } from "react-router-dom"
import ArburgLogo from "../../../ressources/Navbar/Arburg_Logo.svg"

import './navigation.scss'

export default function AusbildungNavigation() {


    return (
        <nav className="jobboard-navigation">
            <div className="logo">
                <Link to="/Jobboard">
                    <img src={ArburgLogo} width={200} />
                </Link>
            </div>
            <div className="links">
                <Link to="/Jobboard"
                onClick={() => {window.scroll({top: 0, left: 0})}}>HOME</Link>
                <Link to="/Jobboard?type=ausbildung"
                onClick={() => {window.scroll({top: 0, left: 0})}}>AUSBILDUNG</Link>
                <Link to="/Jobboard?type=dhbw"
                onClick={() => {window.scroll({top: 0, left: 0})}}>DHBW-STUDIUM</Link>
                <Link to="/Jobboard?type=praktikum"
                onClick={() => {window.scroll({top: 0, left: 0})}}>SCHULPRAKTIKA</Link>
            </div>
        </nav>
    )
}