import React, { Component } from "react";
import { fb } from "../../../../firebase";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar";
import BackendMenuToggle from "../../BackendMenuToggle";
// import { v4 as uuid } from "uuid";
// import { Form, Button } from "react-bootstrap";
// import Loading from "../../../../components/Loading/Loading";

class ProjektEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      schemas: [],
      fields: [],
    };
  }

  componentDidMount() {
    var url = window.location.href;
    var laenge = url.search("Edit/") + 5;
    var key = url.slice(laenge);

    this.setState({ key });

    fb.firestore()
      .collection("fl_content")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var projects = doc.data();
          fb.firestore()
            .collection("fl_schemas")
            .doc(doc.data()._fl_meta_.schemaRef.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const fields = [];
                doc.data().fields.map((field, index) => {
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      [field.key]: projects[field.key],
                    };
                  });

                  // push current key to firebase
                  fields.push({
                    key: field.key,
                  });

                  // this.state[field.key] = projects[field.key];

                  return projects[field.key];
                });
                this.setState({ fields });
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  delete(key) {
    fb.firestore()
      .collection("fl_content")
      .doc(key)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      })
      .finally(function () {
        window.location.replace("/#/ProjektOrganizer");
        window.location.reload();
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // const { title, content, markdown, imageUrl, createdAt } = this.state;

    // console.log(this.state.fields);

    // const updateRef = fb
    // .firestore()
    // .collection("fl_content")
    // .doc(this.state.key);

    // updateRef
    //   .update({
    //     // [field.key]: this.state[field.key],
    //   })
    //   .then((docRef) => {
    //     console.log(docRef)
    //     // this.props.history.push("/Edit/" + this.props.match.params.id);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //   })
    //   .finally(function () {
    //     window.location.replace("/#/ProjektOrganizer");
    //     window.location.reload();
    //   });
  };

  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  getFields = () => {
    // const schemas = this.state.schemas;
    // const projects = this.state.projects;
    // var arr1 = [];
    // var arr2 = [];
    // var arr3 = [];
    // var arr4 = [];
    // var fields = [];
    // schemas.map((schema, index1) => {
    //   arr2 = [];
    //   schema.fields.map((field, index2) => {
    //     arr2[index2] = field.key;
    //     arr1[index1] = arr2;
    //     arr3[schema.id] = arr2;
    //     arr4[schema.key] = arr2;
    //     return null;
    //   });
    //   return null;
    // });
    // projects &&
    //   projects.map((project) => {
    //     fields = arr3[project._fl_meta_.schema];
    //     this.state.fields = arr3[project._fl_meta_.schema];
    //     // this.setState({fields: arr3[project._fl_meta_.schema]})
    //     return null;
    //   });
    // if (projects.length >= 1 && fields !== undefined) {
    //   projects &&
    //     projects.map((e, index) => {
    //       fields &&
    //         fields.map((field) => {
    //           this.state[field] = e[field];
    //         });
    //     });
    //   }
  };

  submitEntry = () => {
    // iterrate through every field
    this.state.fields.map((field) => {
      // get key and value
      let key = field.key;
      let value = this.state[field.key];

      // get correct id for document in fl_content
      let id = this.state.key;

      // update value in firebase
      fb.firestore()
        .collection("fl_content")
        .doc(id)
        .update({ [key]: value });
      return id;
    });
  };

  render() {
    if (this.state !== undefined && this.state.fields !== undefined) {
      return (
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper" className="justify-content-center">
            <BackendMenuToggle />
            <div className="container">
              <br />
              <br />
              {/* <div className="card justify-content-center">
                <div className="card-body"> */}
              <form
                id="CertForm"
                className="needs-validation"
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div className="card justify-content-center">
                  <div className="card-body">
                    {this.state.fields &&
                      this.state.fields.map((field) => {
                        var typeimg = field.key;
                        if (
                          typeimg.includes("img") ||
                          typeimg.includes("image") ||
                          typeimg.includes("video")
                        ) {
                          var imgcontent = this.state[field.key];
                          // console.log(process.env.NODE_ENV)
                          var umgebung = "";
                          if (process.env.NODE_ENV === "production") {
                            umgebung = "prod";
                          } else {
                            umgebung = "dev";
                          }
                          if (imgcontent !== undefined && imgcontent.includes(umgebung)) {
                            return (
                              <div>
                                <h1 className="text-primary">Richtige DB</h1>
                                <h3>{field.key}</h3>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      [e.target.id]: e.target.value,
                                    })
                                  }
                                  id={field.key}
                                  value={this.state[field.key]}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div>
                                <h1 className="text-danger">Falsche DB</h1>
                                <h3>{field.key}</h3>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      [e.target.id]: e.target.value,
                                    })
                                  }
                                  id={field.key}
                                  value={this.state[field.key]}
                                />
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div className="form-group" key={field.key}>
                              <h3>{field.key}</h3>
                              <br />
                              {/* <input
                              type="text"
                              className="form-control"
                              onChange={(e) => this.setState({[e.target.id]: e.target.value})}
                              id={field.key}
                              value={this.state[field.key]}
                            /> */}
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    [e.target.id]: e.target.value,
                                  })
                                }
                                id={field.key}
                                value={this.state[field.key]}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.submitEntry}
                >
                  Änderungen speichern
                </button>
              </form>
              <div>
                <Link to="/ProjektOrganizer" className="btn btn-warning">
                  {/* <button type="button" > */}
                  Zurück
                  {/* </button> */}
                </Link>

                <button
                  type="button"
                  className="btn btn-danger"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                >
                  Löschen
                </button>
                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Löschen
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        Sicher dieses Projekt löschen ?
                        <br />
                        <input
                          type="text"
                          readOnly
                          className="form-control"
                          placeholder="url"
                          value={this.state.title}
                          aria-label="Bild"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Schließen
                        </button>
                        <button
                          onClick={this.delete.bind(this, this.state.key)}
                          className="btn btn-red"
                        >
                          Löschen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default ProjektEdit;
