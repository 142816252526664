import React from 'react'
import { fb } from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

//SCSS
import './Leitbild.scss';

// import { support } from 'jquery';

class Leitbild extends React.Component {
  //   constructor(props) {
  //     super(props);
  //     this.ref = fb.firestore().collection('fl_content');
  //     this.unsubscribe = null;
  //     this.state = {
  //       leitbild: [],
  //     };
  //   }
  //   onCollectionUpdate = (querySnapshot) => {
  //     const leitbild = [];

  //     querySnapshot.forEach((doc) => {

  //          if (doc.data()._fl_meta_.schema === "leitbild") {
  //             const { inhaltLeitbild, summary } = doc.data();

  //             leitbild.push({
  //                 inhaltLeitbild,
  //                 summary,
  //               });
  //             }
  //         });

  //         this.setState({
  //           leitbild,
  //        });
  //       }

  //     componentDidMount() {
  //         this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  //       }

  //  render(){
  //    console.log(this.state.leitbild)
  //     return(
  //         <div className="container">
  //             <h1 className="my-4">Leitbild der Ausbildung</h1>


  //         </div>
  //     )
  //   }
  // }



  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection('fl_content');
    this.unsubscribe = null;
    this.state = {
      leitbild: [],

    };
  }
  onCollectionUpdate = (querySnapshot) => {

    const leitbild = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === "leitbild") {
        const { title, content, order } = doc.data();

        leitbild.push({
          key: doc.id,
          title,
          content,
          order,
        });
      }

    });

    this.setState( (prevState) => {

      return {
        ...prevState,
        leitbild: leitbild.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        })
      }

    })

  }

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="my-4">Leitbild der Ausbildung</h1>
            <div id="accordion">
              <div className="card">

                {this.state.leitbild.map((element, index) => {

                  return (
                    <div key={`${element.title}-${index}`}>
                      <div className="btn card-header collapsed" style={{ textAlign: "unset" }} data-toggle="collapse" data-target={"#collapse" + element.key}>
                        <button  className="LeitbildAccordeon btn btn-link" aria-expanded="true" aria-controls={"collapse" + element.key} >
                            <h3 className="TitleLeitbild">{element.title}</h3>
                        </button> 
                      </div>
                      <div id={"collapse" + element.key} className="collapse" aria-labelledby={element.key} data-parent="#accordion">
                        <div className="card-body" style={{ textAlign: "unset" }}>
                          {ReactHtmlParser(element.content)}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Leitbild