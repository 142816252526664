import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'
import { useEffect } from 'react'

const Matomo = ({url, title, other}) => {
    
    const { trackPageView } = useMatomo()

    useEffect(() => {
        
        trackPageView({
            documentTitle: title,
            href: url,
          })

    // eslint-disable-next-line
    }, [])

    return (<></>)
}

export default Matomo
