import React, { useState } from "react";
// import { TextField } from "@material-ui/core";
// import { ArrowBack, GetApp } from "@material-ui/icons";
// import { Link } from "react-router-dom";
import QRcode from "qrcode.react";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";

function QRgenerator() {
  const [qr, setQr] = useState("");
  const handleChange = (event) => {
    setQr(event.target.value);
  };
  const downloadQR = () => {
    const canvas = document.getElementById("myqr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "myqr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="d-flex" id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper">
        <BackendMenuToggle />
        <div className="container-fluid">
          <div className="text-center">
            <h1>QR Generator</h1>

            <div style={{ marginTop: 30 }}>
              <div className="row">
                <div className="col-lg-3 center-block">
                  <div className="input-group">
                    <input
                      onChange={handleChange}
                      style={{ width: 320 }}
                      value={qr}
                      label="QR content"
                      size="large"
                      variant="outlined"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/* <TextField
                onChange={handleChange}
                style={{ width: 320 }}
                value={qr}
                label="QR content"
                size="large"
                variant="outlined"
                color="#33cc99"
              /> */}
            </div>

            <div>
              {qr ? (
                <QRcode id="myqr" value={qr} size={320} includeMargin={true} />
              ) : (
                <p>No QR code preview</p>
              )}
            </div>
            <div>
              {qr ? (
                <div>
                  {/* <Grid container> */}
                  {/* <Grid item xs={10}> */}
                  <div>{qr}</div>
                  {/* <TextareaAutosize
                      style={{ fontSize: 18, width: 250, height: 100 }}
                      rowsMax={4}
                      defaultValue={qr}
                      value={qr}
                    /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={2}> */}
                  <br />
                  <button className="btn btn-primary" onClick={downloadQR}>
                    Download QR Code
                  </button>
                  {/* <Fab
                      onClick={downloadQR}
                      style={{ marginLeft: 10 }}
                      color="primary"
                    >
                        Download
                    </Fab> */}
                  {/* </Grid> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRgenerator;
