import React from "react";
// import firebase from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from '../BackendMenuToggle'


function calc()
{
  // var today = new Date();
  // var start_a = document.getElementById("input-start").value.split(":");
  
	// if (start_a[0] == "")
	// 	return;
	// var end_a = document.getElementById("input-end").value.split(":");
	// if (end_a[0] == "")
	// {
	// 	end_a[0] = today.getHours();
	// 	end_a[1] = today.getMinutes();
	// }
	// var pause = document.getElementById("pause").value;
	// var start = parseInt(start_a[0] * 60) + parseInt(start_a[1]);
	// var end = parseInt(end_a[0] * 60) + parseInt(end_a[1]);
	// var rest;
	// var min = "0";
	
	// var minuten = end - start - pause - 7*60; 
	// var stunden = end - start - pause;
	// document.getElementById("stunden").innerHTML = runden(stunden / 60, 2) + " Stunden";
	// if (minuten <= 24 && minuten >= 0)
	// {
	// 	end = end - minuten + 24;
	// 	rest = end % 60;
	// 	if (rest < 10)
	// 		min += rest;
	// 	else
	// 		min = rest;
	// 	document.getElementById("freischicht").innerHTML = runden(minuten/60,2) + " Stunden (voll um " + parseInt((end-rest)/60) + ":" + min +" Uhr)";;
	// 	document.getElementById("gleitzeit").innerHTML = 0 + " Stunden";
	// }
	// else if (minuten < 0)
	// {
	// 	end -= minuten;
	// 	rest = end % 60;
	// 	if (rest < 10)
	// 		min += rest;
	// 	else
	// 		min = rest;
	// 	document.getElementById("gleitzeit").innerHTML = runden(minuten/60,2) + " Stunden (Freischicht ab " + parseInt((end-rest)/60) + ":" + min +" Uhr)";
	// 	min = "0";
	// 	end += 24;
	// 	rest = end % 60;
	// 	if (rest < 10)
	// 		min += rest;
	// 	else
	// 		min = rest;
	// 	document.getElementById("freischicht").innerHTML = 0 + " Stunden  (voll um " + parseInt((end-rest)/60) + ":" + min +" Uhr)";
	// }
	// else
	// {
	// 	minuten = minuten - 24;
	// 	document.getElementById("freischicht").innerHTML = 0.4 + " Stunden";
	// 	document.getElementById("gleitzeit").innerHTML = runden(minuten/60,2) + " Stunden";
	// }
}
// function runden(x,n)
// {
// 	if(n<1 || n>14){
// 	return false;
// 	}
	
// 	var e = Math.pow(10,n);
// 	var k = (Math.round(x*e) / e).toString();
// 	if(k.indexOf('.') === -1) {
// 		k += '.';
// 	}
// 	k += e.toString().substring(1);
// 	return k.substring(0,k.indexOf('.') + n+1);
// }


class Tabellen extends React.Component {

  render() {




    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
        <BackendMenuToggle/>
          <div className="container-fluid">
          <div className="container">
			<h1>Gleitzeitrechner</h1>
			<form className="form-horizontal">
				<div className="form-group">
					<label htmlFor="input-start" className="col-sm-4 control-label">Uhrzeit angestempelt</label>
					<div className="col-sm-6">
						<div className="input-group 1">
							<input type="text" className="form-control" id="input-start" onChange={calc()}/>
							<span className="input-group-btn" id="basic-addon1"><button type="button" className="btn btn-default" id="button-start"><span className="glyphicon glyphicon-time"></span></button></span>
						</div>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="input-end" className="col-sm-4 control-label">Uhrzeit abgestempelt</label>
					<div className="col-sm-6">
						<div className="input-group">
							<input type="text" className="form-control" id="input-end" onChange={calc()}/>
							<span className="input-group-btn" id="basic-addon1"><button type="button" className="btn btn-default" id="button-end"><span className="glyphicon glyphicon-time"></span></button></span>
						</div>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="pause" className="col-sm-4 control-label">Pausenzeit</label>
					<div className="col-sm-6">
						<select className="form-control" id="pause" onChange={calc()}>
							<option value="45">45 min</option>
							<option value="60">60 min</option>
							<option value="75">75 min</option>
						</select>
					</div>
				</div>
				<div className="form-group">
					<div className="col-sm-offset-2 col-sm-10">
						<input type="button" onClick={calc()} className="btn btn-primary" value="Berechnen"/>
					</div>
				</div>
			</form>
			<br/>
			<label htmlFor="freischicht" className="col-sm-4 control-label">Freischicht:</label>
			<div className="col-sm-6">
				<span id="freischicht"></span>
			</div>
			<br/>
			<br/>
			<label htmlFor="gleitzeit" className="col-sm-4 control-label">Gleitzeit:</label>
			<div className="col-sm-6">
				<span id="gleitzeit"></span>
			</div>
			<br/>
			<br/>
			<label htmlFor="stunden" className="col-sm-4 control-label">Stunden:</label>
			<div className="col-sm-6">
				<span id="stunden"></span>
			</div>
		
		</div>

            
          </div>
        </div>
      </div>
    );
  }
}
export default Tabellen;
