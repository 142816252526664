import React from 'react'
import { fb } from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

import MailWeiß from "../../ressources/Footer/mail_weiß.png";
import TelefonWeiß from "../../ressources/Footer/telefon_weiß.png";

//SCSS
import './Kontakt.scss'

class Kontakt extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            kontakt: []
        };
    }

    onCollectionUpdate = (querySnapshot) => {
        const kontakt = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()._fl_meta_.schema === "kontakt") {
                const {
                    content,
                    content2,
                    iFrameUrl,
                } = doc.data();

                kontakt.push({
                    key: doc.id,
                    doc,
                    content,
                    content2,
                    iFrameUrl,

                });
            }
        });
        this.setState({
            kontakt
        });
    }
    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }
    componentWillUnmount() {
        this.unsubscribe()
    }

    render() {
        return (

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {this.state.kontakt.map((kontakt) => {
                            return (
                                <div>
                                    <h1 className="my-4">Kontakt</h1>
                                    <div className="card-group">
                                        <div className="card blogpost">
                                            <div className="card-body">
                                                <p className="card-text">
                                                    {ReactHtmlParser(kontakt.content)}
                                                </p>
                                            </div>
                                            <div className="BereichButton">

                                                <a href="mailto:ausbildung@arburg.com">
                                                    <div className="Kontaktfelder">
                                                        <img
                                                            alt="mail"
                                                            className="swap-on-hover__front-image"
                                                            src={MailWeiß}
                                                        ></img>
                                                    </div>
                                                </a>

                                                <a href="tel:+497446330">
                                                    <div className="Kontaktfelder">
                                                        <img
                                                            alt="telefon"
                                                            className="swap-on-hover__front-image"
                                                            src={TelefonWeiß}
                                                        ></img>
                                                    </div>
                                                </a>

                                                <a href="https://ausbildung.arburg.com/#/FAQ">
                                                    <div className="Kontaktfelder">
                                                        FAQ
                                                    </div>
                                                </a>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />

                                    <div className="card-group">
                                        <div className="card blogpost">
                                            <h3 className="card-header">Kontaktdaten</h3>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    {ReactHtmlParser(kontakt.content2)}
                                                </p>
                                            </div>

                                        </div>
                                    </div>


                                    <br />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

        )
    }
}

export default Kontakt