import React from 'react'
import { fb } from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

//SCSS
import './Bewerbung.scss'

class Bewerbung extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            Bewerbung: []
        };
    }

    onCollectionUpdate = (querySnapshot) => {
        const Bewerbung = [];
        var video = "";
        querySnapshot.forEach((doc) => {
            if (doc.data()._fl_meta_.schema === "bewerbung") {
                const { content, title, sector, videoDev,videoProd, order } = doc.data();
                if (process.env.NODE_ENV === "production") {
                    video = videoProd;
                  } else {
                    video = videoDev;
                  }
                Bewerbung.push({
                    key: doc.id,
                    doc,
                    title,
                    content,
                    sector,
                    video,
                    order
                });
            }
        });
        // this.setState({
        //     Bewerbung
        // });


        this.setState( (prevState) => {

            return {
              ...prevState,
              Bewerbung: Bewerbung.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 0;
                }
              })
            }
      
          })

    }
    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }
    componentWillUnmount() {
        this.unsubscribe()
      }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="my-4">Bewerbung</h1>
                        {/* <div className="container-fluid mb-3">

                            <iframe
                                width="100%"
                                height="460"
                                src="https://www.youtube-nocookie.com/embed/nFEoY61SWPU?"
                                title="ARBURG Kinotrailer"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; gyroscope;  fullscreen"
                                >
                            </iframe>

                          
                        </div> */}
                        <div id="accordion">
                            <div className="card">
                                {this.state.Bewerbung.map((bewerbung, index) => {
                                    if (bewerbung.video === undefined || bewerbung.video === '') {
                                        return (
                                            <div key={`${bewerbung.title}-${index}`}>
                                                <div className="card-header collapsed" data-toggle="collapse" data-target={"#collapse" + bewerbung.key} >
                                                    <button className="btn btn-link collapsed" aria-expanded="true" aria-controls={"collapse" + bewerbung.key}>
                                                        <h3 className="Titlebewerbung">{bewerbung.title}</h3>
                                                    </button> 
                                                </div>
                                                <div id={"collapse" + bewerbung.key} className="collapse" aria-labelledby={bewerbung.key} data-parent="#accordion">
                                                    <div className="card-body">
                                                        {ReactHtmlParser(bewerbung.content)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }   
                                    else {
                                        var title = bewerbung.title;
                                        var title_split = title.split(' ');
                                    
                                        let title_sentence = "";
                                        let title_last_word = "";

                                        for (let i=0; i<=6; i++){
                                            if(i < 6){
                                                title_sentence = title_sentence + title_split[i] + " "; 
                                            }else{
                                                title_last_word = title_last_word + title_split[i];
                                            }
                                        }
                                        return (
                                            <div key={`${bewerbung.title}-${index}`}>
                                                <div className="card-header collapsed" data-toggle="collapse" data-target={"#collapse" + bewerbung.key}> 
                                                    <button className="btn btn-link collapsed" aria-expanded="true" aria-controls={"collapse" + bewerbung.key}>
                                                        <h3 className="Titlebewerbung">{title_sentence} <u>{title_last_word}</u></h3>
                                                    </button>
                                                </div>
                                                <div id={"collapse" + bewerbung.key} className="collapse" aria-labelledby={bewerbung.key} data-parent="#accordion">
                                                    <div className="card-body">
                                                        {ReactHtmlParser(bewerbung.content)}
                                                        <br />
                                                        <video width="100%" height="100%" controls>
                                                            <source src={bewerbung.video}></source>
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Bewerbung;

//id={bewerbung.key}