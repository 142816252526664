import React from "react";
import { NavLink } from "react-router-dom";
import StandortWeiß from "../../ressources/Footer/Standort_weiß.png";
import StandortGrün from "../../ressources/Footer/Standort_grün.png";
import MailWeiß from "../../ressources/Footer/mail_weiß.png";
import MailGrün from "../../ressources/Footer/mail_grün.png";
import TelefonWeiß from "../../ressources/Footer/telefon_weiß.png";
import TelefonGrün from "../../ressources/Footer/telefon_grün.png";
import ARBURGLogo from "../../ressources/Navbar/Arburg_Logo.svg";
import FacebookWeiß from "../../ressources/Footer/facebook_weiß.png";
import FacebookGrün from "../../ressources/Footer/facebook_grün.png";
import InstagrammWeiß from "../../ressources/Footer/Instagramm_weiß.png";
import InstagrammGrün from "../../ressources/Footer/Instagramm_grün.png";

//SCSS
import "./Footer.scss";

function Footer() {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  const headings = ["Über Uns", "Kontakt", "FAQ", "Impressum", "Datenschutz"];
  const Listheadings = headings.map((heading, index) => (
    <td key={`${heading}-${index}`}>
      <li className="nav-item">
        <NavLink
          className="heading"
          onClick={scrollToTop()}
          to={"../../" + heading.split(" ").join("_").split("Ü").join("Ue")}
        >
          {heading.toUpperCase()}
          <span className="sr-only">(current)</span>
        </NavLink>
      </li>
    </td>
  ));
  return (
    <div>
      <div className="footer">
        <table className="footertable">
          <tbody>
            <tr className="listheadings">{Listheadings}</tr>
            <tr>
              <td>
                <div className="borderline"></div>
              </td>
            </tr>

            <tr className="Contact">
              <td>

                <a href="https://goo.gl/maps/5mVFMHW7eJX6Xftw8">
                  <div className="ContactBoxContainer">
                    <div className="ContactBox">
                      <div className="ContactIconDiv">
                        <img
                          alt="Standort"
                          className="swap-on-hover__front-image"
                          src={StandortWeiß}
                        ></img>
                        <img
                          alt="Standort"
                          className="swap-on-hover__back-image"
                          src={StandortGrün}
                        ></img>
                      </div>
                      <div className="ContactTextDiv">
                        Arthur-Hehl-Str.
                        <br />
                        72290 Loßburg
                      </div>
                    </div>
                  </div>
                </a>
              </td>
              <td>
                <a href="mailto:ausbildung@arburg.com">
                  <div className="ContactBoxContainer">
                    <div className="ContactBox">
                      <div className="ContactIconDiv">
                        <img
                          alt="Mail"
                          className="swap-on-hover__front-image"
                          src={MailWeiß}
                        ></img>
                        <img
                          alt="Mail"
                          className="swap-on-hover__back-image"
                          src={MailGrün}
                        ></img>
                      </div>
                      <div className="ContactTextDiv">
                        ausbildung@arburg.com
                      </div>
                    </div>
                  </div>
                </a>
              </td>
              <td>
                <a href="tel:+497446330">
                  <div className="ContactBoxContainer">
                    <div className="ContactBox">
                      <div className="ContactIconDiv">
                        <img
                          alt="Telefon"
                          className="swap-on-hover__front-image"
                          src={TelefonWeiß}
                        ></img>
                        <img
                          alt="Telefon"
                          className="swap-on-hover__back-image"
                          src={TelefonGrün}
                        ></img>
                      </div>
                      <div className="ContactTextDiv">+49 7446 33-0</div>
                    </div>
                  </div>
                </a>
              </td>
            </tr>
            {/* <tr>
              <td>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Standort
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="embed-responsive embed-responsive-4by3">
                          <iframe
                            title="ARBURG"
                            width="600"
                            height="450"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            src="https://www.openstreetmap.org/export/embed.html?bbox=8.445686101913454%2C48.40355121096661%2C8.459848165512087%2C48.40990422528507&amp;layer=mapnik&amp;marker=48.40672781732643%2C8.452767133712769"
                            // style="border: 1px solid black"
                          ></iframe>
                        </div>
                        <small>
                          <a href="https://www.openstreetmap.org/?mlat=48.40673&amp;mlon=8.45277#map=17/48.40673/8.45277">
                            Größere Karte anzeigen
                          </a>
                        </small>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Schließen
                        </button>
                        <a href="https://goo.gl/maps/5mVFMHW7eJX6Xftw8">
                          <button type="button" className="btn btn-primary">
                            In Google Maps öffnen
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr> */}

            <tr className="socialMedia">
              <td></td>
              <td>
                <a href="https://www.arburg.com/" target="_blank" rel="noreferrer">
                  <div className="reihesocialmedia">
                    <img
                      alt="Arburg Logo"
                      className="swap-on-hover_sm__front-image1"
                      src={ARBURGLogo}
                    ></img>
                    <img
                      alt="Arburg Logo"
                      className="swap-on-hover_sm__back-image1"
                      src={ARBURGLogo}
                    ></img>
                  </div>
                </a>
              </td>
              <td>
                <a href="https://de-de.facebook.com/arburgworld/" target="_blank" rel="noreferrer">
                  <div className="reihesocialmedia">
                    <img
                      alt="Facebook Logo"
                      className="swap-on-hover_sm__front-image"
                      src={FacebookWeiß}
                    ></img>
                    <img
                      alt="Facebook Logo"
                      className="swap-on-hover_sm__back-image"
                      src={FacebookGrün}
                    ></img>
                  </div>
                </a>
              </td>
              <td>
                <a href="https://www.instagram.com/arburg_rookies/?hl=de" target="_blank" rel="noreferrer">
                  <div className="reihesocialmedia">
                    <img
                      alt="Instagram Logo"
                      className="swap-on-hover_sm__front-image"
                      src={InstagrammWeiß}
                    ></img>
                    <img
                      alt="Instagram Logo"
                      className="swap-on-hover_sm__back-image"
                      src={InstagrammGrün}
                    ></img>
                  </div>
                </a>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div className="copyright">
          <div className="copyrightText">
            {/* &copy;  */}
            <NavLink style={{ color: "rgba(255, 255, 255, 0.8)" }} to="/signin">
              {" "}
              &copy;
            </NavLink>
            {new Date().getFullYear()} Copyright:{" "}
            <a className="arburgCopy" href="https://www.arburg.com/" target="_blank" rel="noreferrer">
              {" "}
              ARBURG GmbH + Co KG{" "}
            </a>
          </div>
        </div>
      </div>

      <div className="footer-medium">
        <div className="collapse" id="navbarToggleExternalContent2">
          <div className="bg-dark p-4">
            <ul className="navbar-nav ml-auto text-white text-center">
              <table>
                <tbody>
                  <tr>{Listheadings}
                  <NavLink
                      className="heading"
                      onClick={scrollToTop()}
                      to={"/SocialMedia"}
                    >
                      SOCIALMEDIA
                      <span className="sr-only">(current)</span>
                    </NavLink>
                  </tr>
                </tbody>
              </table>
              {/* <a href="https://www.arburg.com/de/meta-navigation/impressum/"><div className="heading"><p>IMPRESSUM</p></div></a>
              <a href="https://www.arburg.com/de/meta-navigation/datenschutz/"><div className="heading"><p>DATENSCHUTZ</p></div></a> */}
              <div className="copyright">
                <div className="copyrightText">
                  <NavLink
                    style={{ color: "rgba(255, 255, 255, 0.8)" }}
                    to="/signin"
                  >
                    {" "}
                    &copy;
                  </NavLink>
                  {/* &copy;  */}
                  {new Date().getFullYear()} Copyright:{" "}
                  <a className="arburgCopy" href="https://www.arburg.com/" target="_blank" rel="noreferrer">
                    {" "}
                    ARBURG GmbH + Co KG{" "}
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <nav className="navbar navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent2"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>

      <div className="footer-small">
        <div className="collapse" id="navbarToggleExternalContent2">
          <div className="bg-dark p-4">
            <ul className="navbar-nav ml-auto text-white text-center">
              <table>
                <tbody>
                  <tr style={{ display: "inline-grid" }}>
                    {Listheadings}
                    <NavLink
                      className="heading"
                      onClick={scrollToTop()}
                      to={"/SocialMedia"}
                    >
                      SOCIALMEDIA
                      <span className="sr-only">(current)</span>
                    </NavLink>
                  </tr>
                </tbody>
              </table>
              <div className="copyright">
                <div className="copyrightText">
                  <NavLink
                    style={{ color: "rgba(255, 255, 255, 0.8)" }}
                    to="/signin"
                  >
                    {" "}
                    &copy;
                  </NavLink>
                  {new Date().getFullYear()} Copyright:{" "}
                  <a className="arburgCopy" href="https://www.arburg.com/" target="_blank" rel="noreferrer">
                    {" "}
                    ARBURG GmbH + Co KG{" "}
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <nav className="navbar navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent2"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Footer;
