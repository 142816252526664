import React from "react";
// import { Switch, Route, HashRouter as Router } from "react-router-dom";

import { fb } from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
// import $ from "jquery";

class Anmeldungen extends React.Component {
  constructor(props) {
    super(props);
    // this.ref = firebase.firestore().collection("Wartung");
    this.ref = fb.firestore().collection("fl_content");
    this.state = {
      veranstaltungen: [],
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const veranstaltungen = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()._fl_meta_.schema === "veranstaltungen") {
        veranstaltungen.push({
          ...doc.data(),
          key: doc.id,
        });
      }
    });
    this.setState({
      veranstaltungen,
    });

    this.state.veranstaltungen.map((item) => {
      this.setState({ [item.key]: item.trigger });
      return <></>;
    });

  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);


  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  handleChange = (e) => {
    console.log(e.target.id)
    // this.setState({
    //   [e.target.id]: e.target.checked,
    // });

    // const updateRef = fb
    //   .firestore()
    //   .collection("fl_content")
    //   .doc(e.target.id);
    // updateRef
    //   .set({
    //     ...this.state.veranstaltungen.find(
    //       (veranstaltungen) => veranstaltungen.key === e.target.id
    //     ),
    //     trigger: e.target.checked,
    //   })
    //   .then((docRef) => {
    //     // this.props.history.push("/Edit/" + this.props.match.params.id);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //   });
  };

  render() {
    // const birthday = new Date('August 19, 1975 23:15:30');



    return (
      <div>
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid">
              <br />
              <div className="card-deck">
                <br />
                {this.state.veranstaltungen &&
                  this.state.veranstaltungen.map((veranstaltungen, index) => {
                    // var status = '';
                    if (veranstaltungen.activ === false) {
                      // status = 'Veranstaltung nicht Aktiv'
                    }
                    else {
                      // status = 'Veranstaltung Aktiv'
                    }

                    var startdatumLabel = veranstaltungen.startDatum;

                    console.log(startdatumLabel)

                    return (
                      <div className="card" key={`${veranstaltungen.title}-${index}`}>
                        <div className="card-header">
                          <h5 className="card-title text-center">
                            {veranstaltungen.title}
                          </h5>
                        </div>
                        <div className="card-body">
                          <table>
                            <tr>
                              <td>
                                {/* <p><b>Startdatum: </b>{veranstaltungen.startDatum}</p> */}
                                <label for="begin"><b>Startdatum:</b></label>
                              </td>
                              <td>
                                <input type="date" id="begin" name="begin" value={veranstaltungen.startDatum.substring(0, 10)} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {/* <p><b>Enddatum: </b>{veranstaltungen.endDatum}</p> */}
                                <label for="begin"><b>Enddatum:</b> </label>
                              </td>
                              <td>
                                <input type="date" id="begin" name="begin" value={veranstaltungen.endDatum.substring(0, 10)} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>URL: </b>
                              </td>
                              <td>
                                {process.env.NODE_ENV === "development" ? (
                                  <b>{window.location.href.substring(0, window.location.href.length - 11)}Veranstaltung/{veranstaltungen.url}</b>
                                ) : (
                                  <b>{window.location.href.substring(0, window.location.href.length - 11)}Veranstaltung/{veranstaltungen.url}</b>
                                )}
                              </td>
                            </tr>
                          </table>
                          <br />
                          <a type="button" className="btn btn-primary" href={"#/Veranstaltung/" + veranstaltungen.url}>
                            zur Anmeldung
                          </a>
                        </div>


                        <div className="card-footer">
                          <div className="text-muted text-center">

                            <div className="custom-control custom-switch ">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={veranstaltungen.activ}
                                onChange={this.handleChange}
                                data-toggle="modal"
                                data-target="#exampleModal"
                                data-whatever="@getbootstrap"
                                id={veranstaltungen.id}
                              />
                              {this.state.trigger === true ? (
                                <label className="custom-control-label" htmlFor="switch">
                                  Veranstaltung aktiv
                                </label>
                              ) : (
                                <label className="custom-control-label" htmlFor="switch">
                                  Veranstaltung inaktiv
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

        </div>
      </div>
    );

  }
}

export default Anmeldungen;
