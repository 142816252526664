import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import "./Cardsslider.scss";

const Cardsslider = (props) => {

  const { neuigkeiten } = props;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  // const customDot = {
  //   border: 0,
  //   // background: "transparent",
  //   // border: 0,
  //   // color: "#fff",
  //   // fontSize: "80px"
  // };
  // const CustomDot = ({ onMove, index, onClick, active }) => {
  //   return (
  //     <li
  //       className={active ? "active" : "inactive"}
  //       onClick={() => onClick()}
  //     >
  //       {/* <MaximizeIcon /> */}
  //     </li>
  //   );
  // };



    return (
      <section className="pt-5 pb-5">
        <div className="container-fluid">
          <h1 className="my-4">
            Neuigkeiten in der Ausbildung
          </h1>
          <div className="row">
            <div className="col-sm-12 slideshowRow">
              <Carousel 
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={6000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={props.deviceType}
                dotListClass="react-multi-carousel-dot"
                itemClass="carousel-item-padding-40-px"
              >
                {neuigkeiten.map((card, index) => {
                  if (card.show == true){
                  return (
                    <div className="" key={`${card.title}-${index}`}>
                      <a href={card.link}>
                      <div className="card mb-4 blogpost">
                        <img className="card-img-top" src={card.imageUrl} alt={card.title}/>
                        <div className="card-body">
                            <div className="cardbodyextra">
                            <h5 className="card-title">{card.title}</h5>
                            </div>
                        </div>
                      </div>
                      </a>
                    </div>
                  )
                  };
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Cardsslider;
