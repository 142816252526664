/**
 * @file: ausbildungsManager.jsx
 * @description: Manager Seite für Ausbildungen
 */

// import Praktikacontainer from "./praktikacontainer/praktikacontainer"
import './AusbildungsManager.scss'
import ArburgLogo from '../../ressources/Navbar/Arburg_Logo.svg';
import { Link } from "react-router-dom";
// import AddPraktikum from "./AddPraktikum/AddPraktikum";
import { fb } from "../../firebase";
import { useState, useEffect } from "react";
import AddAusbildungBeta from './addAusbildung/AddAusbildung';
import AusbildungsContainer from './ausbildungsContainer/ausbildungsContainer';
// import AddPraktikumBeta from "./AddPraktikumBeta/addPraktikumBeta";


export default function AusbildungsManager() {
    let [ausbData, setAusbData] = useState([]);

      useEffect(() => {
        let getAusbData = fb.firestore().collection('ausbildung');
      
        getAusbData.onSnapshot((data) => {
            let content = []
            data.forEach((doc) => {
                content.push({
                    id: doc.id,
                    creator: doc.data().createdBy,
                    creationDate: doc.data().creationDate,
                    archive: doc.data().archive,
                    changedBy: doc.data().changedby,
                    changedDate: doc.data().changedDate,
                    data: {
                        title: doc.data().data.postTitle,
                        name: doc.data().data.name,
                        link: doc.data().data.link,
                        place: doc.data().data.place,
                        country: doc.data().data.country,
                        type: doc.data().data.type,
                        direction: doc.data().data.direction,
                        img: doc.data().data.img,
                        shortDesc: doc.data().data.shortDesc,
                        shortDescHeader: doc.data().data.shortDescHeader,
                        benefits: doc.data().data.benefits,
                        startDate: doc.data().data.startDate,
                        content: {
                            b1Title: doc.data().data.content.b1Title,
                            b1Content: doc.data().data.content.b1Content,
                            b2Title: doc.data().data.content.b2Title,
                            b2Content: doc.data().data.content.b2Content,
                            b3Title: doc.data().data.content.b3Title,
                            b3Content: doc.data().data.content.b3Content,
                            b4Title: doc.data().data.content.b4Title,
                            b4Content: doc.data().data.content.b4Content
                        }
                    }
                })
            })
            setAusbData(content)
            
            console.log("Content", content)
          })
      }, [])
    return (
        <div>
            <div className="praktikum-header">
                <div>
                    <Link to="/">
                        <img src={ArburgLogo} width="125"/>
                    </Link>
                </div>
                <div className="nav-link">
                    <Link to="/">Home</Link> 
                    <Link to="/Jobboard">Jobboard</Link>
                </div>
            </div>
            <div className="praktika-list">
                <div className="list-header">
                    <div className="header-section">
                        <h2>ID</h2>
                    </div>
                    <div className="header-section">
                        <h2>Name</h2>
                    </div>
                    <div className="header-section">
                        <h2>Art</h2>
                    </div>
                    <div className="header-section">
                        <h2>Erstelldatum</h2>
                    </div>
                    <div className="header-section">
                        <h2>Ersteller</h2>
                    </div>
                </div>
                {ausbData.map((index, i) => {
                    console.log(index)
                    return <AusbildungsContainer 
                    key={index.id}
                    id={index.id}
                    archived={index.archive}
                    creator={index.creator}
                    creationDate={index.creationDate}
                    b1Title={index.data.content.b1Title}
                    b1Content={index.data.content.b1Content}
                    b2Title={index.data.content.b2Title}
                    b2Content={index.data.content.b2Content}
                    b3Title={index.data.content.b3Title}
                    b3Content={index.data.content.b3Content}
                    b4Title={index.data.content.b4Title}
                    b4Content={index.data.content.b4Content}
                    shortDesc={index.data.shortDesc}
                    shortDescHeader={index.data.shortDescHeader}
                    link={index.data.link}
                    name={index.data.name}
                    title={index.data.title}
                    place={index.data.place}
                    type={index.data.type}
                    direction={index.data.direction}
                    image={index.data.img}
                    benefits={index.data.benefits}
                    startDate={index.data.startDate}
                    />
                })}
                {/* {praktiData.map((index, i) => {
                    return <Praktikacontainer 
                    key={index.id}
                    id={index.id}
                    archived={index.archive}
                    creator={index.creator}
                    creationDate={index.creationDate}
                    block1Title={index.data.content.block1Title}
                    block1Content={index.data.content.block1Content}
                    block2Title={index.data.content.block2Title}
                    block2Content={index.data.content.block2Content}
                    endB1Title={index.data.content.endB1Title}
                    endB1Content={index.data.content.endB1Content}
                    endB2Title={index.data.content.endB2Title}
                    endB2Content={index.data.content.endB2Content}
                    department={index.data.department}
                    shortDesc={index.data.shortDesc}
                    shortDescHeader={index.data.shortDescHeader}
                    link={index.data.link}
                    name={index.data.name}
                    title={index.data.title}
                    place={index.data.place}
                    country={index.data.country}
                    type={index.data.type}
                    direction={index.data.direction}
                    image={index.data.img}
                    benefits={index.data.benefits} />
                })} */}
            </div>
            {/* <AddPraktikumBeta /> */}
            {/* <AddPraktikum /> */}
            <AddAusbildungBeta />
        </div>
    )
}