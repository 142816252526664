import React from "react";
import { fb, auth } from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
import './Profile.scss';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("users");
    this.unsubscribe = null;
    this.state = {
      users: [],
      selectedUser: undefined
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const users = [];
    querySnapshot.forEach((doc) => {
      // if (doc.data()._fl_meta_.schema === "anmeldungen") {
      const { email, firstname, lastname, role, confirmed, deleted, signedIn, passwordReset, startyear, lastlogin, beruf, createdAt } =
        doc.data();

      users.push({
        key: doc.id,
        email,
        firstname,
        lastname,
        beruf,
        role,
        confirmed,
        deleted,
        signedIn,
        startyear,
        passwordReset,
        lastlogin,
        createdAt
      }
      );
      // }
    });



    this.setState({
      users,
    });
  };
  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  resetPW = (e) => {
    this.state.users.map((user) => {
      if (user.key === this.state.selectedUser.key) {
        var emailAddress = user.email;
        auth
          .sendPasswordResetEmail(emailAddress)
          .then(function () {

            fb.firestore().collection("users").doc(auth.currentUser.uid).update({ passwordReset: true });
            console.log("email send");
          })
          .catch(function (error) {
            // An error happened.
            console.log("email not send", error);
          });
      }
      return true;
    });
    this.state.selectedUser.setState(undefined);
  };

  deleteAcc = (e) => {
    this.state.users.map((user) => {
      if (user.key === this.state.selectedUser.key) {
        console.log(user.email + " gelöscht");
        fb.firestore()
          .collection("users")
          .doc(user.key)
          .update({ deleted: true });
      }
      return true;
    });
    this.state.selectedUser.setState(undefined);
  };


  noDeleteAcc = (e) => {

    this.state.users.map((user) => {
      if (user.key === e.target.id) {
        console.log(user.email + " wiederhergestellt");
        fb.firestore()
          .collection("users")
          .doc(user.key)
          .update({ deleted: false });
      }
      return true;
    });
  };

  triggerChange = (e) => {
    console.log(this.state.selectedUser)
    e.preventDefault();
    if (e.target.id === "firstname") {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          firstname: e.target.value
        }
      })
    }
    if (e.target.id === "lastname") {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          lastname: e.target.value
        }
      })
    }
    if (e.target.id === "beruf") {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          beruf: e.target.value
        }
      })
    }
    if (e.target.id === "startyear") {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          startyear: e.target.value
        }
      })
    }
    if (e.target.id === "role") {
      this.setState({
        selectedUser: {
          ...this.state.selectedUser,
          role: e.target.value
        }
      })
    }
  }

  saveToDb = () => {
    console.log(this.state.selectedUser)
    fb.firestore()
      .collection("users")
      .doc(this.state.selectedUser.key)
      // .update(this.state.selectedUser)
      .update({
        firstname: this.state.selectedUser.firstname,
        lastname: this.state.selectedUser.lastname,
        role: this.state.selectedUser.role,
        startyear: this.state.selectedUser.startyear,
        beruf: this.state.selectedUser.beruf,
      }) 
      .then(() => {
        console.log("Success!")
        this.setState({ selectedUser: undefined })
      }
      )

  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <BackendMenuToggle />
          <div className="container-fluid">
            <br />
            <div className="card-columns">
              {this.state.users &&
                this.state.users.map((user) => {


                  return (
                    <div className="card" key={user.key}>
                      <h3 className="card-header">{user.firstname} {user.lastname}</h3>

                      <div className="card-body">

                        <div className="form-group row">
                          <label for="beruf" className="col-sm-3 col-form-label"><b>Beruf</b></label>
                          <div class="col-sm-9">
                            <p class="form-control-plaintext">{user.beruf} </p>
                          </div>

                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>E-Mail</b></label>
                          <div class="col-sm-9">
                            <p class="form-control-plaintext">{user.email} </p>
                          </div>

                          <label for="inputPassword" class="col-sm-3 col-form-label"><b>Ausbildungsstart</b></label>
                          <div class="col-sm-9">
                            <p class="form-control-plaintext" >{user.startyear} </p>
                          </div>

                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>Rolle</b></label>
                          <div class="col-sm-9">
                            {user.role === "1" ? (
                              <p class="form-control-plaintext">Infoordner</p>
                            ) : user.role === "2" ? (
                              <p class="form-control-plaintext">Admin</p>
                            ) : user.role === "3" ? (
                              <p className="form-control-plaintext">Ausbilder</p>
                            ) : (
                              <p classname="form-control-plaintext">Undefiniert</p>
                            )}

                          </div>

                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>User angelegt am: </b></label>
                          <div class="col-sm-9">
                            <p class="form-control-plaintext">{new Date(user.createdAt).toLocaleDateString()}</p>
                          </div>

                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>Zuletzt angemeldet: </b></label>
                          <div class="col-sm-9">
                            <p type="text" readonly class="form-control-plaintext">{new Date(user.lastlogin).toLocaleDateString()} um {new Date(user.lastlogin).toLocaleTimeString()} Uhr</p>
                          </div>


                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>UID</b></label>
                          <div class="col-sm-9">
                            <p class="form-control-plaintext">{user.key}</p>
                          </div>

                          <label for="staticEmail" className="col-sm-3 col-form-label"><b>Status</b></label>
                          {user.signedIn ? (
                            <div class="col-sm-9">
                              <p class="form-control-plaintext text-primary">online</p>
                            </div>
                          ) : (
                            <div class="col-sm-9">
                              <p class="form-control-plaintext text-danger">offline</p>
                            </div>
                          )}

                        </div>
                        {user.confirmed ? (
                          <p className="card-text text-primary"> E-Mail bestätigt </p>
                        ) : (
                          <p className="card-text text-danger">
                            {" "}
                            E-Mail noch nicht bestätigt{" "}
                          </p>
                        )}
                        {user.passwordReset ? (
                          <p className="card-text text-primary"> Passwort wurde zurückgesetzt </p>
                        ) : (
                          <p className="card-text text-danger">
                            {" "}
                            Passwort wurde noch nicht zurückgesetzt{" "}
                          </p>
                        )}


                        {/* BUTTONS  */}
                        <div>
                          {user.deleted ? (
                            <button
                              type="button"
                              className="btnProfil btn  btn-secondary"
                              id={user.key}
                              onClick={this.noDeleteAcc}
                            >
                              Profil
                              wiederherstellen
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btnProfil btn btn-danger"
                              id={user.key}
                              data-toggle="modal"
                              data-target="#deleteProfil"
                              onClick={() => this.setState({ selectedUser: user })}
                            >
                              Profil löschen
                            </button>
                          )}

                          <button
                            type="button"
                            className="btnProfil btn btn-secondary"
                            data-toggle="modal"
                            data-target="#resetPW"
                            onClick={() => this.setState({ selectedUser: user })}
                            id={user.key}
                          >
                            Passwort reset
                          </button>

                          <button
                            type="button"
                            className="btnProfil btn btn-secondary"
                            data-toggle="modal"
                            data-target="#editProfile"
                            onClick={() => this.setState({ selectedUser: user })}
                            id={user.key}
                          >
                            Profil bearbeiten
                          </button>


                          <a
                            type="button"
                            className="btnProfil btn btn-primary"
                            href={"mailto:" + user.email}
                          >
                            Send Mail to User
                          </a>
                        </div>
                      </div>

                      {/* POP UPS  */}
                      <div
                        className="modal fade"
                        id="deleteProfil"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Profil löschen
                              </h5>
                            </div>


                            <div className="modal-body">
                              <p><b>Möchten Sie den Account von {this.state.selectedUser?.firstname} {this.state.selectedUser?.lastname} löschen ? </b>
                                <br />
                              </p>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                id={user.key}
                                onClick={this.deleteAcc}
                                data-dismiss="modal"
                              >
                                Profil löschen
                              </button>

                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => this.setState({ selectedUser: undefined })}
                              >
                                Schließen
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="resetPW"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Passwort zurücksetzen
                              </h5>
                            </div>

                            <div className="modal-body">
                              <p><b>Möchten Sie das Passwort von {this.state.selectedUser?.firstname} {this.state.selectedUser?.lastname} zurücksetzen ? </b>
                                <br />
                              </p>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                id={user.key}
                                // data-toggle="modal"
                                // data-target="#exampleModal"
                                onClick={this.resetPW}
                              >
                                Passwort zurücksetzen
                              </button>


                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => this.setState({ selectedUser: undefined })}
                              >
                                Schließen
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div
                        className="modal fade"
                        id="editProfile"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Profil bearbeiten
                              </h5>
                            </div>
                            <div className="modal-body">
                              <label for="inputPassword" class="col-sm-8 col-form-label"><b>Vorname</b></label>
                              <div class="col-sm-9">
                                <input className="form-control" type="text" id="firstname" placeholder="Vorname" value={this.state.selectedUser?.firstname} onChange={this.triggerChange} />
                              </div>

                              <label for="inputPassword" class="col-sm-8 col-form-label"><b>Nachname</b></label>
                              <div class="col-sm-9">
                                <input className="form-control" type="text" id="lastname" placeholder="Nachname" value={this.state.selectedUser?.lastname} onChange={this.triggerChange} />
                              </div>

                              <br /><div class="dropdown-divider"></div>

                              <label for="inputBeruf" class="col-sm-8 col-form-label"><b>Beruf</b></label>
                              <div class="col-sm-9">
                                <input className="form-control" type="text" id="beruf" placeholder="Beruf" value={this.state.selectedUser?.beruf} onChange={this.triggerChange} />
                              </div>

                              <label for="inputPassword" class="col-sm-8 col-form-label"><b>Ausbildungsstart</b></label>
                              <div class="col-sm-9">
                                <input className="form-control" type="number" id="startyear" placeholder="Jahr" value={this.state.selectedUser?.startyear} onChange={this.triggerChange} />
                              </div>

                              <label for="staticEmail" className="col-sm-3 col-form-label"><b>Rolle</b></label>
                              <div className="col-sm-9">
                                <select className="form-control" defaultValue={this.state.selectedUser?.role} id="role" onChange={this.triggerChange}>
                                  <option value="1">Infoodner</option>
                                  <option value="2">Admin</option>
                                  <option value="3">Ausbilder</option>
                                </select>
                              </div>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary white"
                                id={user.key}
                                onClick={this.saveToDb}
                                data-dismiss="modal"
                              >
                                Speichern
                              </button>

                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => this.setState({ selectedUser: undefined })}
                              >
                                Schließen
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  );

                })}
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default Profile;
