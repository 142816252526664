// import React from "react";

// import { auth, fb } from "../../firebase";
// //SCSS
// import "./Infoordner.scss";

// class Infoordner extends React.Component {
//   constructor(props) {
//     super(props);
//     this.ref = fb.firestore().collection("fl_content");
//     this.unsubscribe = null;
//     this.state = {
//       pdfs: [],
//     };
//   }

//   signOut = () => {
//     fb.firestore().collection("users")
//       .doc(auth.currentUser.uid)
//       .update({ signedIn: false })
//       .then(function () {
//         console.log("logout");
//         window.location.href = "/#/signin";
//         auth.signOut();
//       })
//       .catch(function (error) {
//         console.log("User not loged out", error);
//       });
//   };

//   onCollectionUpdate = (querySnapshot) => {
//     const pdfs = [];
//     var urlPdf = "";
//     querySnapshot.forEach((doc) => {
//       if (doc.data()._fl_meta_.schema === "infoordner") {
//         const { urlPdfProd, urlPdfDev, order } = doc.data();
//         if (process.env.NODE_ENV === "production") {
//           urlPdf = urlPdfProd;
//         } else {
//           urlPdf = urlPdfDev;
//         }
//         pdfs.push({
//           key: doc.id,
//           doc, // DocumentSnapshot
//           urlPdf,
//           order,
//         });
//       }
//     });
//     this.setState({
//       pdfs,
//     });
//   };

//   componentDidMount() {
//     this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
//   }
//   componentWillUnmount() {
//     this.unsubscribe();
//   }

//   render() {
//     // console.log(this.state.pdfs);
//     return (
//       <div className="d-flex" id="wrapper">
//         {this.state.pdfs &&
//           this.state.pdfs.map((pdf, index) => {
//             // console.log(pdf.url);
//             return (
//               <div id="page-content-wrapper" key={`${pdf.urlPdf}-${index}`}>

//                 <a href={pdf.urlPdf} download>
//                   <div className="btn btnDownload btn-primary">
//                     Laden Sie hier den Infoordner runter !
//                   </div>
//                 </a>

//                 {/* <button className="btn btnLogout"
//                   onClick={() => {
//                     this.signOut();
//                   }}>
//                   LogOut
//                 </button> */}
//                 <br />
//                 <embed
//                   width="100%"
//                   height="850px"
//                   type="application/pdf"
//                   src={pdf.urlPdf}

//                 ></embed>
//               </div>
//             );
//           })}
//       </div>
//     );
//   }
// }

// export default Infoordner;

// FERTIGER NEUER INFOORDNER

import React from 'react'
import { auth, fb} from '../../firebase';
import ReactHtmlParser from 'react-html-parser';

import './Infoordner.scss';

class Infoordner extends React.Component {
  
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          infoordner: [],

        };
      }

        signOut = () => {
    fb.firestore().collection("users")
      .doc(auth.currentUser.uid)
      .update({ signedIn: false })
      .then(function () {
        console.log("logout");
        window.location.href = "/#/signin";
        auth.signOut();
      })
      .catch(function (error) {
        console.log("User not loged out", error);
      });
  };

      onCollectionUpdate = (querySnapshot) => {
          
        const infoordner = [];  
        querySnapshot.forEach((doc) => {
          if (doc.data()._fl_meta_.schema === "infoordner") {
            const { thema, antwort, order } = doc.data();

            infoordner.push({
              key: doc.id, 
              doc,
              thema,
              antwort,
              order,
            });
          }
          
            });
            
          this.setState( (prevState) => {

            return {
              ...prevState,
              infoordner: infoordner.sort(function (a, b) {
                if (a.order > b.order) {
                  return 1;
                } else if (a.order < b.order) {
                  return -1;
                } else {
                  return 0;
                }
              })
            }
      
          })
        }
        
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
          }
          componentWillUnmount() {
            this.unsubscribe()
          }

 render(){
    return(
      <div className= "container">
        <div className="row">
        <div className="col-md-12">
          <h1 className="my-4">Infoordner</h1>
          <div id="accordion">
            <div className="card mb-2">
            {this.state.infoordner.map((element, index) =>{
                    return (
                  <div className="accordionItem" key={`${element.thema}-${index}`}>
                    <div 
                      className="card-header text-center collapsed"
                      id={element.key}
                      data-toggle="collapse"
                      data-target={"#collapse" + element.key}
                    >
                      <button 
                        className="btn btn-link" 
                        aria-expanded="true" 
                        aria-controls={"collapse"+element.key}
                      >
                          <h3 className="TitleInfoordner">{element.thema}</h3>
                      </button>
                    </div>
                    {element.thema === 'Vorwort' ? (
                      <div 
                       id={"collapse" + element.key} 
                       className="collapse show" 
                       aria-labelledby={element.key} 
                       data-parent="#accordion"
                      >
                       <div className="card-body" style={{textAlign:"unset"}}>
                               {ReactHtmlParser(element.antwort)}
                       </div>
                     </div>
                    ) : (
                     <div 
                      id={"collapse" + element.key} 
                      className="collapse" 
                      aria-labelledby={element.key} 
                      data-parent="#accordion"
                     >
                      <div className="card-body" style={{textAlign:"unset"}}>
                        {ReactHtmlParser(element.antwort)}
                      </div>
                     </div>
                    )}
                    {/* <div 
                      id={"collapse" + element.key} 
                      className="collapse show" 
                      aria-labelledby={element.key} 
                      data-parent="#accordion"
                    >
                      <div className="card-body" style={{textAlign:"unset"}}>
                              {ReactHtmlParser(element.antwort)}
                      </div>
                    </div> */}
                    </div>
                  )
                  })}
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  }
}
export default Infoordner