import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { auth, fb } from "./firebase";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth !== null) {
        fb.firestore().collection("users")
          .doc(userAuth.uid)
          .get()
          .then((data) => {
            setUser({
              ...userAuth,
              additional: data,
              role: data.get("role"),
              firstname: data.get("firstname"),
              lastname: data.get("lastname"),
            });
          });
      } else {
        setUser(null);
      }
    });
  }, []);

  return <UserContext.Provider value={user}> {children} </UserContext.Provider>;
};

const urlBase = 'https://arburg.innocraft.cloud/';

const instance = createInstance({
  urlBase: 'https://arburg.innocraft.cloud/',
  siteId: 3,
  userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: `${urlBase}/tracking.php`, // optional, default value: `${urlBase}matomo.php`
  srcUrl: `${urlBase}/tracking.js`, // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})




ReactDOM.render(
  <MatomoProvider value={instance}>
  <UserProvider>
      <App />
   </UserProvider>
   </MatomoProvider>
  ,
  document.getElementById("root")
);
