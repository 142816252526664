import React from "react";

//SCSS
import "./Specials.scss";

const Specials = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <h1 className="my-4">Specials</h1>
          <h3>Hier werden verschiedene Specials angeboten</h3>
          <br />
          <br />
          <a className="Button" href="../worldgame/index.html">
            ARBURG Worldgame 2.0
          </a>
          <br />
          <br />
          <a className="Button" href="../react-360/index.html">
            360 Grad Rundgang
          </a>
        </div>
      </div>
    </div>
  );
};

export default Specials;
