import React from 'react'
import LinktreePost from './LinktreePost/LinktreePost.jsx';
import './Linktrees.scss';

const Linktrees = (props) => {
    var sortedArr = [];
    return(
        <div className="row"> 
            {props.linktree.map((linktree, index) => {
                sortedArr[linktree.order] = [linktree.title, linktree.url, linktree.imageUrl, linktree.sort]
                return <div key={`${linktree.title}-${index}`}></div>
            })}
            {
            sortedArr.map((linktree, index) => {
                return <LinktreePost key={`${linktree.title}-${index}`} title={linktree[0]} url={linktree[1]} imageUrl={linktree[2]} sort={linktree[3]} />
            })
            }
        </div>
        
    )
}

export default Linktrees