import React from 'react'
// import { Link } from "react-router-dom"

import './AccordionContent.scss';

const AccordionContent = (props) => {

    return (
        <div className="row">
            {props.ausbildungsberufe.map((beruf, index) => {
                return (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={`${beruf.title}-${index}`}>
                        <div className="card mb-3 blogpost">
                            <a href={'#/Karriere/' + beruf.key}>
                                <img className="card-img-top" src={beruf.imageUrl} alt={beruf.title} />
                                <div className="card-body">
                                    <h5 className="card-title">{beruf.title}</h5>
                                    <p className="card-text">{beruf.summary} </p>
                                    <div className="btn btn-primary">Mehr erfahren</div>
                                </div>
                            </a>
                            {beruf.linkBewerbung ?
                                (<>
                                    <a href={beruf.linkBewerbung}>
                                        <div className="card-footer text-muted">
                                            <a href={beruf.linkBewerbung} className="nav-link active">Jetzt Bewerben !</a>
                                        </div>
                                    </a>
                                </>) : (<>
                                </>)}
                        </div>
                    </div>
                )
            })}
            <></>
        </div >


    )
}
export default AccordionContent