import React from 'react'
import AzubiInfoPost from './AzubiInfoPost/AzubiInfoPost.jsx';
import './AzubiInfos.scss';

const AzubiInfos = (props) => {
    var sortedArr = [];
    return(
        <div className="row">
            {props.azubiInfo.map((azubiInfo, index) => {
                sortedArr[azubiInfo.order] = [azubiInfo.title, azubiInfo.summery, azubiInfo.imageUrl, azubiInfo.url]
                return <div key={`${azubiInfo.title}-${index}`}></div>
            })}
            {
            sortedArr.map((azubiInfo, index) => {
                return <AzubiInfoPost key={`${azubiInfo.title}-${index}`} title={azubiInfo[0]} summery={azubiInfo[1]} imageUrl={azubiInfo[2]} url={azubiInfo[3]} />
            })
            }
        </div>
        
    )
}

export default AzubiInfos