import React from 'react'
import ReactHtmlParser from 'react-html-parser';
//DEBUG
import '../Posttitle/Posttitle.scss';

const style = {
    marginTop: '30px',
    width: '65%',
    display: 'grid',
    marginLeft: 'auto',
    marginRight: 'auto',
  }

  const Posttitle = (props) => {
    var titel_id = props.title;
    titel_id = titel_id.split(" ").join("");
    titel_id = titel_id.split(".").join("_");
    titel_id = titel_id.replace(/^\// , '');
    titel_id = titel_id.replace(/[^\w\s]/gi, '') //Scrollspy mag Sonderzeichen nicht
    return(
        <section id={titel_id} style={style}>
        
          <div className="container">
          <h1>{props.title}</h1>
          <br/>
              { ReactHtmlParser(props.content) }
            
          </div>

          <div className="container">
            <br/>
              <small className="text-muted">Hochgeladen am: {props.date.substr(0,10)}</small>
              <br/>
                <small className="text-muted">Autor: {props.author}</small>
                <br/>
                {/* <small className="text-muted">Tags: {props.tags}</small> */}
              </div>
        </section>
    );    
}
export default Posttitle