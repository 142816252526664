import React from "react";
import BlogPost from "./BlogPost/BlogPost";

import "./BlogEntries.scss";

const BlogEntry = (props) => {

  function sortArticles(){
    props.articles.sort(function(a,b) {
      var dateA = new Date(a.date), dateB = new Date(b.date);
      return dateB - dateA;
    })
  }

  return (
    <div>
      <div id="accordion">
        <div className="card mb-2">
          {props.date.map((item, index) => {
            if (new Date().getFullYear().toString() === item) {
              return (
                <div className="accordionItem" key={`${item}-${index}`}>
                  <div
                    className="card-header text-center"
                    id={item}
                    data-toggle="collapse"
                    data-target={"#collapse" + item}
                  >
                    <button
                      className="btn btn-link"
                      aria-expanded="false"
                      aria-controls={"collapse" + item}
                    >
                      <h3>{item}</h3>
                    </button>
                  </div>
                  <div
                    id={"collapse" + item}
                    className="collapse show"
                    aria-labelledby={item}
                    data-parent="#accordion"
                  >
                    <div className="card-body ">
                      <div className="row ">
                      {sortArticles()}
                        {props.articles.map((article, index) => {
                          if (article.date.substr(0, 4) === item.substr(0, 4)) {
                            // console.log(new Date(article.date).toLocaleDateString())
                          //  console.log(article.urlLink)
                            return (
                              <BlogPost
                                key={`${article.title}-${index}`}
                                url={article.key}
                                title={article.title}
                                summary={article.summary}
                                content={article.content}
                                date={article.date}
                                tags={article.tags}
                                author={article.author}
                                imageUrl={article.imageUrl}
                                urlLink={article.urlLink}
                              />
                            );
                          }
                          return <></>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={`${item}-${index}`}>
                  <div
                    className="card-header text-center collapsed"
                    id={item}
                    data-toggle="collapse"
                    data-target={"#collapse" + item}
                  >
                    <button
                      className="btn btn-link collapsed"
                      aria-expanded="false"
                      aria-controls={"collapse" + item}
                    >
                      <h3>{item}</h3>
                    </button>
                  </div>
                  <div
                    id={"collapse" + item}
                    className="collapse"
                    aria-labelledby={item}
                    data-parent="#accordion"
                  >
                    <div className="card-body ">
                      <div className="row ">
                        {props.articles.map((article, index) => {
                          if (article.date.substr(0, 4) === item.substr(0, 4)) {
                            return (
                              <BlogPost
                              key={`${article.title}-${index}`}
                                url={article.key}
                                title={article.title}
                                summary={article.summary}
                                content={article.content}
                                date={article.date}
                                tags={article.tags}
                                author={article.author}
                                imageUrl={article.imageUrl}
                                urlLink={article.urlLink}
                              />
                            );
                          }

                          return <></>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <br/>
        <br/>
      </div>
    </div>
  );
};

export default BlogEntry;
