import React from "react";


class ProjektCreate extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.ref = fb.firestore().collection("fl_content");

  //   this.state = {
  //     key: "",
  //     title: "",
  //     content: "",
  //     markdown: "",
  //     image: "",
  //     createdAt: "",
  //   };
  // }

  // handleEditorChange = (e) => {
  //   this.setState({
  //     content: e.html,
  //     markdown: e.text,
  //   });
  // };

  // handleChange = (e) => {
  //   this.setState({
  //     [e.target.id]: e.target.value,
  //   });
  //   var date = new Date();

  //   this.setState({
  //     createdAt: date,
  //   });
  // };
  // handleImageChange = (e) => {
  //   this.setState({
  //     image: e.target.value,
  //   });
  // };

  // handleUploadSuccess = (filename) => {
  //   this.setState({ Bildname: filename, progress: 100, isUploading: false });
  //   fb
  //     .storage()
  //     .ref("data")
  //     .child(filename)
  //     .getDownloadURL()
  //     .then((url) => this.setState({ image: url }));
  // };

  // handleSubmit = (e) => {
  //   e.preventDefault();

  //   // createdAt = new Date();



  //   const { title, image, content, markdown, createdAt } = this.state;

  //   this.ref
  //     .add({
  //       title,
  //       image,
  //       content,
  //       markdown,
  //       createdAt,
  //     })
  //     .then((docRef) => {
  //       this.setState({
  //         title: "",
  //         image: "",
  //         content: "",
  //         markdown: "",
  //         createdAt: "",
  //         Bildname: "",
  //       });
  //       this.props.history.push("/");
  //     })
  //     .catch((error) => {
  //       console.error("Error adding document: ", error);
  //     })
  //     .finally(function () {
  //       window.location.replace("/#/ProjektOrganizer");
  //       window.location.reload();
  //     });
  // };

  render() {
    return(
      <div></div>
    )

//     if (this.state.image === "") {
//       return (
//         <div className="d-flex" id="wrapper">
//           <Sidebar />
//           <div id="page-content-wrapper">
//             <BackendMenuToggle />
//             <div className="container">
//               <br />
//               <br />
//               <div className="card">
//                 <div className="card-body">
//                   <React.Fragment>
//                     <Form
//                       className="container-fluid"
//                       onSubmit={this.handleSubmit}
//                     >
//                       <Form.Group controlid="formBasicText">
//                         <label
//                           className="btn-danger"
//                           style={{
//                             padding: 10,
//                             borderRadius: 4,
//                             cursor: "pointer",
//                           }}
//                         >
//                           Bild hinzufügen
//                           <FileUploader
//                             hidden
//                             accept="image/*"
//                             storageRef={fb.storage().ref("data")}
//                             onUploadStart={this.handleUploadStart}
//                             onUploadError={this.handleUploadError}
//                             onUploadSuccess={this.handleUploadSuccess}
//                             onProgress={this.handleProgress}
//                           />
//                         </label>
//                         {this.state.Bildname}
//                         <br />
//                         <Form.Label>Oder Url hinzufügen</Form.Label>

//                         <Form.Control
//                           type="text"
//                           id="image"
//                           value={this.state.image}
//                           onChange={this.handleImageChange}
//                           placeholder="Bild des Projekts"
//                           required="required"
//                         />
//                       </Form.Group>

//                       <Form.Group controlid="formBasicTitle">
//                         <Form.Label>Titel</Form.Label>
//                         <Form.Control
//                           type="text"
//                           id="title"
//                           onChange={this.handleChange}
//                           placeholder="Titel des Projekts"
//                           required="required"
//                         />
//                       </Form.Group>

//                       <Form.Group controlid="formBasicText">
//                         <Form.Label>Inhalt</Form.Label>
//                         {/* <Form.Control
//                         type="text"
//                         as="textarea"
//                         rows="5"
//                         id="content"
//                         onChange={this.handleChange}
//                         placeholder="Inhalt des Projekts"
//                         required="required"
//                       /> */}

//                         {/* <MdEditor
//                           style={{ height: "500px" }}
//                           renderHTML={(content) => mdParser.render(content)}
//                           onChange={this.handleEditorChange}
//                           required="required"
//                           // id="content"
//                         /> */}
//                       </Form.Group>
//                       <Button variant="danger" type="submit">
//                         Projekt erstellen
//                       </Button>
//                     </Form>
//                   </React.Fragment>
//                 </div>
//               </div>
//               <br />
//               <br />
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div className="d-flex" id="wrapper">
//           <Sidebar />
//           <div id="page-content-wrapper">
//             <BackendMenuToggle />
//             <div className="container">
//               <br />
//               <br />
//               <div className="card">
//                 <img
//                   className="card-img-top"
//                   src={this.state.image}
//                   alt={"Card" + this.state.image}
//                 />
//                 <div className="card-body">
//                   <React.Fragment>
//                     <Form
//                       className="container-fluid"
//                       onSubmit={this.handleSubmit}
//                     >
//                       <Form.Group controlid="formBasicText">
//                         <Form.Label>Bild Url</Form.Label>
//                         <Form.Control
//                           type="text"
//                           id="image"
//                           value={this.state.image}
//                           onChange={this.handleImageChange}
//                           placeholder="Bild des Projekts"
//                           required="required"
//                         />
//                       </Form.Group>

//                       <Form.Group controlid="formBasicTitle">
//                         <Form.Label>Titel</Form.Label>
//                         <Form.Control
//                           type="text"
//                           id="title"
//                           onChange={this.handleChange}
//                           placeholder="Titel des Projekts"
//                           required="required"
//                         />
//                       </Form.Group>
//                       <Form.Group controlid="formBasicText">
//                         <Form.Label>Inhalt</Form.Label>
//                         {/* <Form.Control
//                         type="text"
//                         as="textarea"
//                         rows="5"
//                         id="content"
//                         onChange={this.handleChange}
//                         placeholder="Inhalt des Projekts"
//                         required="required"
//                       /> */}
// {/* 
//                         <MdEditor
//                           style={{ height: "500px" }}
//                           renderHTML={(content) => mdParser.render(content)}
//                           onChange={this.handleEditorChange}
//                           required="required"
//                           // id="content"
//                         /> */}
//                       </Form.Group>
//                       <Button variant="danger" type="submit">
//                         Projekt erstellen
//                       </Button>
//                     </Form>
//                   </React.Fragment>
//                 </div>
//               </div>
//               <br />
//               <br />
//             </div>
//           </div>
//         </div>
//       );
    
  }
}

export default ProjektCreate;
