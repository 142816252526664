import React from "react";
import { v4 as uuid } from "uuid";
import Sidebar from "./Sidebar";
import BackendMenuToggle from "../Backend/BackendMenuToggle";
import {fb} from "../../firebase";

//SCSS
import "./Backend.scss";

class Backend extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("Wartung");
    this.state = {
      trigger: undefined,
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      this.setState({ trigger: doc.data().trigger });
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {


    if (this.state.trigger === false) {
      return (
        <div className="d-flex" id="wrapper" key={uuid()}>
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid text-center">
              <div>
                <svg
                  // className="App-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#33cc99"
                  width="30%"
                  className="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.trigger === undefined) {
      return (
        <div className="d-flex" id="wrapper" key={uuid()}>
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid text-center">
              <div>
                <svg
                  // className="App-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ff0000"
                  width="30%"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex" id="wrapper" key={uuid()}>
          <Sidebar />
          <div id="page-content-wrapper">
            <BackendMenuToggle />
            <div className="container-fluid text-center">
              <div>
                <svg
                  // className="App-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffff00"
                  width="30%"
                  className="bi bi-dash-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default Backend;
