import React from 'react'
import BlogEntries from '../../components/News/BlogEntries/BlogEntries';
import {fb} from '../../firebase';

//SCSS
import './News.scss'

 class News extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          articles: []
        };
      }
      onCollectionUpdate = (querySnapshot) => {
        const articles = [];
        var imageUrl = "";
        querySnapshot.forEach((doc) => {
          if (doc.data()._fl_meta_.schema === "artikel") {
            const { summary, title, content, date, tags, author, imageUrlDev, imageUrlProd, urlLink} = doc.data();
            
            if (process.env.NODE_ENV === "production") {
              imageUrl = imageUrlProd;
            } else {
              imageUrl = imageUrlDev;
            }
            
            articles.push({
                key: doc.id,
                doc, // DocumentSnapshot
                summary,
                title,
                content,
                date,
                tags,
                author,
                imageUrl,
                urlLink
              });

              if (process.env.NODE_ENV === "production") {
                imageUrl = imageUrlProd;
              } else {
                imageUrl = imageUrlDev;
              }
            }
            });
            this.setState({
              articles
           });
          }  
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
            
          }

          componentWillUnmount() {
            this.unsubscribe()
          }

 render(){
  var date = [];
  var counter = 0;
  var counter2 = 0;
  var articles2 = [];

  function sortArticles(articles){
    articles.map((article) => {
      date[counter] = article.date.substr(0,4);
      counter++;
      return true;
    })
    date.sort(function(a, b){return b-a});
    let unique = [];
    date.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element)
        }    
    });
    date = unique;
    date.map((datum) => {
      articles.map((element)=>{
        if (element.date.substr(0,4) === datum) {
            articles2[counter2++] = element;
        }
        return element;
      })
      return datum;
    })    
 }
    return(
      <div className="container">
        <h1 className="my-4">News</h1>
        {sortArticles(this.state.articles)}
          <BlogEntries articles={articles2} date={date}/>
      </div>
    )
  }
}
export default News