/**
 * @file SIASeite.jsx
 * @description SIA Projekt
 */

import './SIASeite.scss';

export default function SIASeite() {
    return (
        <div className="sia-page-wrapper">
            <div className="Titelleiste">
                <h1>Bag for Good</h1>
            </div>

            <div className="video-placeholder">
                <video className="video-placeholder" autoPlay muted loop>
                    <source src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2F8a28c8dc-fc09-419c-adea-bb099ce442a2_SiaGruppe%20-%20Trim.mp4?alt=media&token=3be9ad4f-7092-4dc2-9adf-65aea667150d" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <br />
            <p className='ueberschrift'>Was ist die Bag for Good der ARBURG?</p>

            <br />

            <div className="card">
                <br />
                
                <p className='text'>Die Bag for Good Tasche vereint Nachhaltigkeit, Funktionalität und optische Ansprüche.</p>
                <div className="Bild1">
                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2F1d8507e6-81d8-4c06-9381-e0973c0596bd_1000032766.jpg?alt=media&token=f1b82bed-6568-4286-a344-d37b0fc1c281" />
                </div>
                <p className='text'>Messewerbebanner können aufgrund von Verschleiß nur einmal verwendet werden.</p>
                <p className='text'>Um diese danach nicht zu entsorgen, werden sie an die Lebenshilfe Bruchsal geliefert.</p>
                <p className='text'>Dort werden sie zu Taschen weiterverarbeitet.</p>
                <div className="Bild2">
                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2F58dde114-7392-4731-9ce5-9e002a981c1b_Bild2-SIA.jpg?alt=media&token=ec252489-5018-411c-9c63-3589b3fb8a50" />
                </div>
                <p className='ueberschrift'>Das Ergebnis ist die Bag for Good.</p>

                <div className="Bild3">
                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2Fd2750734-eeba-460e-a458-360d6f7b701c_Bild3-SIA.jpg?alt=media&token=263825d5-8981-4c24-9e58-ac571b2e50d6" />
                </div>

                <p className='text'>
                    Die Tasche verkörpert alle Aspekte der Nachhaltigkeit:
                    <br />
                    ökologisch: Sie besteht zu 82% aus recyceltem Material.
                    <br />
                    ökonomisch: Kurze Transportwege, die Tasche wird in Baden Württemberg gefertigt.
                    <br />
                    sozial: Die Tasche wird von der Lebenshilfe Bruchsal gefertigt.
                </p>

                <div className="Bild4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Sonstiges%2F4e4a537c-716f-4f05-812d-2be25f5cf341_Bild4-SIA.jpg?alt=media&token=ddfb7f65-ef53-48fd-b929-3eeb5d649a13" />
                </div>


                <p className='ueberschrift'>Wie kann man die Tasche erhalten?</p>

                <p className='text'>Über ein Praktikumsplatz in der ARBURG.</p>
                
                <p className='text'>Über ein gutes Gespräch bei einem ARBURG Messestand.</p>

            </div>
        </div>
    )
}
