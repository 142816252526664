import React from 'react';
import './BlogPost.scss';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const BlogPost = ({ summary, url, title, date, tags, author, imageUrl, urlLink }) => {
    // var titel_id = title;
    // titel_id = titel_id.split(" ").join("");
    // titel_id = titel_id.split(".").join("_");
    // titel_id = titel_id.replace(/^\// , '');
    // titel_id = "/OnePager/#"+titel_id;

    // function scrollToTop(el) {
    //     el.scrollIntoView(true);
    // }
    var link = "/News/";
    // let formatTitle = title.replace(/[^A-Z0-9]/ig, "");
    return (
        <div className="col-lg-4 col-md-6 col-sm-12">

            <Link to={link + url}>
                <div className="card mb-4 blogpost">
                    {/* <Link from="News" to={link} id="titel_id" className="js-scroll-trigger"> */}
                    {/* <HashLink to={link+url}> */}
                    <img className="card-img-top" src={imageUrl} alt={title} />
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{summary}</p>
                        <HashLink to={link + url} className="btn-mehrerfahren btn btn-primary">Mehr erfahren</HashLink>
                        {/* <HashLink smooth to={link+formatTitle} scroll={(el) => window.scrollTo({top: el.getBoundingClientRect().top-70, left: 0, behavior: "smooth"})} className="btn-mehrerfahren btn btn-primary">Mehr erfahren</HashLink> */}
                        <br />
                        <br />
                        <p className="card-text">
                            {/* <small className="text-muted">Hochgeladen am: {date.substr(0,10)}</small> */}
                            {/* <br/>
                    <small className="text-muted">Autor: {author}</small> */}
                            {/* <br/>
                    <small className="text-muted">Tags: {tags}</small> */}
                        </p>
                    </div>

                    {/* </HashLink> */}
                    {/* </Link> */}
                </div>
            </Link>


        </div>


    )
}
export default BlogPost