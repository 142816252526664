import React from "react";
import "./WartungsControl.scss";
import { fb } from "../../../firebase";
import Sidebar from "../Sidebar";
import BackendMenuToggle from "../BackendMenuToggle";
import $ from "jquery";
import 'react-nice-dates/build/style.css'
import DateTimePicker from './DateTimePicker/DateTimePicker'




class WartungsControl extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("Wartung");
    // this.ref2 = fb.firestore().collection("Countdown");
    this.unsubscribe = null;
    this.unsubscribe2 = null;
    this.state = {
      key: "",
      trigger: false,
      code: "",
      checked: false,
      time: undefined,
      timen: undefined,
      date: undefined,
      daten: undefined,
    };
  }

  // onCollectionUpdate2 = (querySnapshot) => {
  //   querySnapshot.forEach((doc) => {
  //     // this.setState({
  //     //   time: doc.data().time.seconds,
  //     //   date: doc.data().date.seconds,
  //     //   timen: doc.data().time.nanoseconds,
  //     //   daten: doc.data().date.seconds
  //     // })
  //   });
  // };

  onCollectionUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      this.setState({
        trigger: doc.data().trigger,
        key: doc.id,
      });
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    // this.unsubscribe2 = this.ref2.onSnapshot(this.onCollectionUpdate2);

    $("#exampleModal").hide();
    $(".modal-backdrop").hide();
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  handleChange = (e) => {
    const updateRef = fb.firestore().collection("Wartung").doc(this.state.key);
    updateRef
      .set({
        trigger: this.state.checked,
      })
      .then((docRef) => {
        // this.props.history.push("/Edit/" + this.props.match.params.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  handleSubmit = (e) => {
    var pwYear = new Date().getFullYear().toString();
    pwYear = pwYear.substring(2);
    var checked = false;

    if (this.state.code === "azubiIT" + pwYear) {
      if (this.state.trigger === false) {
        checked = true;
      } else {
        checked = false;
      }

      const updateRef = fb
        .firestore()
        .collection("Wartung")
        .doc(this.state.key);
      updateRef
        .update({
          trigger: checked,
        })
        .then((docRef) => {
          console.log("changed db");
          // this.props.history.push("/Edit/" + this.props.match.params.id);
          // this.setState({trigger: this.state.checked})

          // window.location.href = "/#/Wartung";
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } else if (this.state.code === "") {
      alert("Passwort eingeben");
    } else {
      alert("Falsches Passwort");
    }

    e.preventDefault();
    this.setState({ code: "" });

    $("#exampleModal").hide();
    $(".modal-backdrop").hide();
  };

  handleCode = (e) => {
    // e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  handleTrigger = (e) => {
    e.preventDefault();
    var element = document.getElementById("switch");
    element.checked = this.state.trigger;

    // return this.state.trigger
  };

  

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <BackendMenuToggle />

          <div className="container-fluid text-center">
            {/* <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input size"
                checked={this.state.trigger}
                onChange={this.handleChange}
                id="switch"
              />

              <label
                className="custom-control-label label-size"
                htmlFor="switch"
              >
                Wartung
              </label>
            </div> */}
            <h3>Wartungsmodus</h3>
            <div className="custom-control custom-switch custom-switch-xl">
              <input
                type="checkbox"
                className="custom-control-input size"
                checked={this.state.trigger}
                onChange={this.handleTrigger}
                data-toggle="modal"
                data-target="#exampleModal"
                data-whatever="@getbootstrap"
                id="switch"
              />
              <br />
              {this.state.trigger === true ? (
                <label className="custom-control-label" htmlFor="switch">
                  Wartungsmodus an
                </label>
              ) : (
                <label className="custom-control-label" htmlFor="switch">
                  Wartungsmodus aus
                </label>
              )}
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Wartungsmodus aktivieren ?
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Sind Sie sicher, ob Sie den Wartungsmodus aktivieren
                      wollen ?
                    </p>
                    <p>
                      Beim aktivieren des Wartungsmodus wird der Zugang auf das
                      Frontend für nicht angemeldete User gesperrt.
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="code" className="col-form-label">
                          Bestätigungscode:
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="code"
                          value={this.state.code}
                          onChange={this.handleCode}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Bestätigen
                      </button>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    {/* <button type="submit" class="btn btn-primary">
                      Bestätigen
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3 text-center">
              <div className="card-header">Countdown Datum</div>
              <div className="card-body">
                <DateTimePicker/>
              </div>
              <div className="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WartungsControl;
