/**
 * @file: navigation.jsx
 * @description: contains the navigation of the app
 */

import { Link } from "react-router-dom"
import ArburgLogo from "../../../ressources/Navbar/Arburg_Logo.svg"

import './navigation.scss'

export default function Navigation() {


    return (
        <nav className="jobboard-navigation">
            <div className="logo">
                <Link to="/Jobboard/hs">
                    <img src={ArburgLogo} width={200} />
                </Link>
            </div>
            <div className="links">
                <Link to="/Jobboard/hs"
                onClick={() => {window.scroll({top: 0, left: 0})}}>HOME</Link>
                <Link to="/Jobboard/hs?type=praktikum"
                onClick={() => {window.scroll({top: 0, left: 0})}}>PRAKTIKUM</Link>
                <Link to="/Jobboard/hs?type=abschlussarbeit"
                onClick={() => {window.scroll({top: 0, left: 0})}}>ABSCHLUSSARBEIT</Link>
                <Link to="/Jobboard/hs?type=technikerarbeit"
                onClick={() => {window.scroll({top: 0, left: 0})}}>TECHNIKERARBEIT</Link>
                <Link to="/Jobboard/hs?type=umschulung"
                onClick={() => {window.scroll({top: 0, left: 0})}}>UMSCHULUNG</Link>
                <Link to="/Jobboard/hs?type=werkstudierende"
                onClick={() => {window.scroll({top: 0, left: 0})}}>WERKSTUDIERENDE</Link>
            </div>
        </nav>
    )
}