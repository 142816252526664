import React from 'react';
import './AzubiInfoPost.scss';


const AzubiInfoPost = ({ title, summery, url, imageUrl }) => {
    return (

        <div className="card-group col-lg-4 col-md-6 col-sm-12">
                <div className="card mb-4">
                    {imageUrl ? (<><img className="card-img-top" src={imageUrl} alt={title} /></>) : (<></>)}
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p>{summery}</p>
                    </div>
                    {url? (<><a href={url}><div className="card card-footer">Mehr erfahren</div></a></>):(<></>)}
                </div>
        </div>

    )
}
export default AzubiInfoPost