import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { auth, fb } from "../../../firebase";
// import "./Login.scss";

class PWReset extends Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("users");
    this.state = {
      trigger: undefined,
      users: [],
      email: "",
      password: "",
      confirmed: false,
      isActive1: undefined,
      currentUser: [],
      redirect: "",
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        key: doc.id,
        role: doc.data().role,
        confirmed: doc.data().confirmed,
      });
      this.setState({ users });
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    e.preventDefault();
  };

  handleSubmit = (e) => {
    auth
      .sendPasswordResetEmail(this.state.email)
      .then((success) => {
        // Email sent.
        console.log("email send");
      })
      .catch(function (error) {
        // An error happened.
        console.log("email not send", error);
        alert(error.message);
      });

    e.preventDefault();
    this.setState({ email: "" });
  };

  render() {
    if (auth.currentUser !== null) {
      return <Redirect to="/Backend" />;
    } else {
      return (
        <div>
          <div className="container col d-flex justify-content-center pt-4">
            <div className="card text-center w-50">

              <div className="card-header"><h3>Passwort zurücksetzen</h3></div>

              <div className="card-body ">
                <form
                  id="CertForm"
                  className="needs-validation"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipUsername">Username</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="emailPrepend">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-person-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                              <path
                                fillRule="evenodd"
                                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.handleChange}
                          id="email"
                          placeholder="E-Mail"
                          aria-describedby="emailPrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                        Bitte wählen Sie einen eindeutigen und gültigen Benutzernamen.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus !</div>
                      </div>
                    </div>
                  </div>
                  <button
                    id="btnSubmit"
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Zurücksetzen
                  </button>
                </form>
              </div>
              <Link to="/signin">
                <div className="card-footer" >Zurück zum Login</div>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PWReset;
