import React from 'react'
import BlogPost from '../BlogEntries/BlogPost/BlogPost'

const BlogEntries = (props) => {
    var sortedArr = [];

    return (
        <div className="row">
            {props.projektgruppen.map((pgruppe, index) => {
                sortedArr[pgruppe.order] = [pgruppe.title, pgruppe.imageUrl, pgruppe.videoUrl, pgruppe.summary , pgruppe.order]
                return <div key={`${pgruppe.title}-${index}`} title={pgruppe.title} imageUrl={pgruppe.imageUrl} videoUrl={pgruppe.videoUrl} summary={pgruppe.summary} />
            })}
            {
                sortedArr.map((pgruppe, index) => {
                    return <BlogPost key={`${pgruppe[0]}-${index}`} title={pgruppe[0]} imageUrl={pgruppe[1]} videoUrl={pgruppe[2]} summary={pgruppe[3]} />
                })
            }

        </div>

    )
}


export default BlogEntries
