/**
 * @file: PraktikumPost.jsx
 * @description: Die Details eines Praktikums
 */

import Navigation from "../../../pages/Jobboard/navigation/navigation";
import Footer from "../../../pages/Jobboard/footer/footer";

import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { fb } from "../../../firebase";

// PDF
import PDFGen from "../PDF/pdfGen";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";


// styles
import './PraktikumPost.scss';
import Benefit from "../benefits/Benefit";
import Sidebar from "../sidebar/Sidebar";


export default function PraktikumPost() {
    let { id } = useParams();

   


      let [praktiData, setPraktiData] = useState({
        benefits: [],
        title: "",
        department: "",
        shortDescHeader: "",
        shortDesc: "",
        place: "",
        country: "",
        date: "",
        link: "",
        img: "",
        type: "",
        content: {
            block1Title: "",
            block1Content: [],
            block2Title: "",
            block2Content: [],
            endB1Title: "",
            endB1Content: "",
            endB2Title: "",
            endB2Content: "",
        }
      });
      let [image, setImage] = useState("praktikum-post-header");
        
      useEffect(() => {
        let getPraktiData = fb.firestore().collection('studienpraktika');

        document.title = 'Jobboard';

        getPraktiData.onSnapshot((data) => {
            data.forEach((doc) => {
                if(doc.id === id) {    
                setPraktiData({
                    benefits: doc.data().data.benefits,
                    title: doc.data().data.postTitle,
                    department: doc.data().data.department,
                    shortDesc: doc.data().data.shortDesc,
                    shortDescHeader: doc.data().data.shortDescHeader,
                    place: doc.data().data.place,
                    country: doc.data().data.country,
                    date: doc.data().creationDate,
                    link: doc.data().data.link,
                    img: doc.data().data.img,
                    type: doc.data().data.type,
                    content: {
                        block1Title: doc.data().data.content.block1Title,
                        block1Content: doc.data().data.content.block1Content,
                        block2Title: doc.data().data.content.block2Title,
                        block2Content: doc.data().data.content.block2Content,
                        endB1Title: doc.data().data.content.endB1Title,
                        endB1Content: doc.data().data.content.endB1Content,
                        endB2Title: doc.data().data.content.endB2Title,
                        endB2Content: doc.data().data.content.endB2Content,
                    }
                })
                if(doc.data().data.img === "imga") {
                  setImage("praktikum-post-header praktikum-header-imga")
                } else {
                  setImage("praktikum-post-header praktikum-header-imgb")
                }
                }
            })
          })
      }, [])
      
    return (
        <div className="praktikum-post">
            <Navigation />
            <div className="praktikum-post-main">
                <div className={image}>
                    <div className="praktikum-post-text">
                        <h1>{praktiData.title}</h1>
                    </div>
                </div>
                    <div className="praktikum-post-preview">
                        <h4>{praktiData.shortDescHeader}</h4>
                        <p>{praktiData.shortDesc}</p>
                    </div>
                <div className="praktikum-post-content">
                    <div className="post-content-block">
                        <h4>{praktiData.content.block1Title}</h4>
                        <div className="block-list">
                            {praktiData.content.block1Content.map((index, i) => {
                                return <div className="block-list-item"><span className="line-list">/ </span><p key={i}>{index}</p></div>
                            })}
                        </div>
                    </div>
                    <div className="post-content-block">
                        <h4>{praktiData.content.block2Title}</h4>
                        <div className="block-list">
                            {praktiData.content.block2Content.map((index, i) => {
                                return <div className="block-list-item"><span className="line-list">/ </span><p key={i}>{index}</p></div>
                            })}
                        </div>
                    </div>
                    <div className="post-content-block">
                        <h4>{praktiData.content.endB1Title}</h4>
                        <p>{praktiData.content.endB1Content}</p>
                    </div>
                    <div className="post-content-block">
                        <h4>{praktiData.content.endB2Title}</h4>
                        <p>{praktiData.content.endB2Content}</p>
                    </div>
                </div>
                <div className="praktikum-post-aio">
                    <div className="aio-container">
                        <span className="aio-header">Land</span>
                        <span>{praktiData.country}</span>
                    </div>
                    <div className="aio-container">
                        <span className="aio-header">Ort</span>
                        <span>{praktiData.place}</span>
                    </div>
                    <div className="aio-container">
                        <span className="aio-header">Abteilung</span>
                        <span>{praktiData.department}</span>
                    </div>
                    <div className="aio-container">
                        <span className="aio-header">Stelle online seit</span>
                        <span>{praktiData.date}</span>
                    </div>
                </div>
                <div className="praktikum-post-menu">
                    <a href={`${praktiData.link}`} 
                    target="_blank"
                    rel="noreferrer">Jetzt bewerben »</a>
                </div>
                <div className="praktikum-benefits">
                    <div className="benefits-header">
                        <h4><span>/</span> Benefits</h4>
                    </div>
                    <div className="benefits-list">
                        {praktiData.benefits.map((index, i) => {
                            return <Benefit key={i} type={index} />
                        })}
                    </div>
                </div>
            </div>
            <Sidebar
            title={praktiData.title}
            image={praktiData.img}
            shortDescHeader={praktiData.shortDescHeader}
            shortDesc={praktiData.shortDesc}
            b1Title={praktiData.content.block1Title}
            b1Content={praktiData.content.block1Content}
            b2Title={praktiData.content.block2Title}
            b2Content={praktiData.content.block2Content}
            endB1Title={praktiData.content.endB1Title}
            endB1Content={praktiData.content.endB1Content}
            endB2Title={praktiData.content.endB2Title}
            endB2Content={praktiData.content.endB2Content}
            country={praktiData.country}
            place={praktiData.place}
            department={praktiData.department} 
            />
            <Footer />
        </div>
    )
}