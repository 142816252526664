import React from 'react'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'

const NeueAzubis = () => {
    return (
        <>
            <Navbar/>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", flexWrap: "wrap", paddingTop:"3rem"}}>
                    <div className="row">
                        <div className="col">
                            <h1>Willkommen neue Azubis</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <video style={{width:"60%", height:"auto", marginLeft: "20%", marginRight:"20%"}} controls autostart autoplay src="https://firebasestorage.googleapis.com/v0/b/azubi-homepage-prod-1-66fbb.appspot.com/o/Other%2FProjekt_Azubigeschenk_Wenzel_neu.mp4?alt=media&token=dcd6817f-1f8a-4d5a-b247-b0f6ea708c2f" type="video/mp4"></video>
                        </div>
                    </div>
                    

                </div>
            <Footer/>
        </>
    )
}

export default NeueAzubis
