import React, { Component } from "react";
import { Link } from "react-router-dom";
// import BackendMenuToggle from "../BackendMenuToggle";
import { fb, auth } from "../../../firebase";
import readXlsxFile from "read-excel-file";
// import Sidebar from "../Sidebar";

class Register extends Component {

  state = {
    email: "",
    password: "",
    role: "",
    firstname: "",
    lastname: "",
    beruf: "",
    confirmed: false,
    deleted: false,
    passwordReset: false,
    signedIn: false,
    startyear: "",
    import: "Auswählen",
    lastlogin:"",
  };


  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // auth.signInWithEmailAndPassword(this.state.email, this.state.password);
    // this.props.signIn(this.state)
    auth
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((userCredential) => {
        var user = userCredential.user;
        // user.sendEmailVerification({url: "http://localhost:3000"});

        //Setup firestore data
        const data = {
          role: this.state.role,
          email: this.state.email,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          startyear: this.state.startyear,
          // startyear: this.state.startyear,
          confirmed: this.state.confirmed,
          deleted: this.state.deleted,
          passwordReset: this.state.passwordReset,
          signedIn: this.state.signedIn,
        };

        user
          .sendEmailVerification()
          .then(function () {
            console.log("email send");
          })
          .catch(function (error) {
            console.log("email not send", error);
          });

        fb.firestore().collection("users").doc(user.uid).set(data);

        fb.firestore().collection("users")
          .doc(auth.currentUser.uid)
          .update({ signedIn: false })
          .then(function () {
            console.log("logout");
            window.location.href = "/#/signin";
            auth.signOut();
          })
          .catch(function (error) {
            console.log("User not loged out", error);
          });

        // auth.signOut();

      });


  };


  handleInput = (e) => {
    const input = document.getElementById("formFileLg");

    readXlsxFile(input.files[0]).then((rows) => {
      // `rows` is an array of rows
      for (let index = 1; index < rows.length; index++) {

        auth
          .createUserWithEmailAndPassword(rows[index][2], rows[index][3])
          .then((userCredential) => {

            var user = userCredential.user;

            //Setup firestore data
            const data = {
              role: rows[index][5].toString(),
              email: rows[index][2],
              firstname: rows[index][0],
              lastname: rows[index][1],
              startyear: rows[index][4].toString(),
              confirmed: this.state.confirmed,
              deleted: this.state.deleted,
              passwordReset: this.state.passwordReset,
              signedIn: this.state.signedIn,
            };



            user
              .sendEmailVerification()
              .then(function () {
                console.log("email send");
              })
              .catch(function (error) {
                console.log("email not send", error);
              });

            fb.firestore().collection("users").doc(user.uid).set(data);


            // fb.firestore().collection("users").doc(user.uid).update({ signedIn: false });
            // auth.signOut();

            if (index < rows.length) {
              auth.signOut();
            }

          });

      }


    });
  };



  render() {
    // if (auth.currentUser === null) {
    //   // return <Redirect to="/signin" />;
    // } else {
    return (
      <div>
        {/* <div className="d-flex" id="wrapper">
          <Sidebar />
          
          <div id="page-content-wrapper">
            <BackendMenuToggle /> */}
        <div className="container col d-flex justify-content-center pt-4">
          <div className="card text-center w-50">
            {/* <Link to="/Backend"> */}
            <div className="card-header"><h3>Registrierung Azubi-Bereich</h3></div>
            {/* </Link> */}

            {/* <input type="file" id="input" onChange={this.handleInput}/> */}

            <div className="card-body ">
              {/* <div className="form-row">
                <div className="col mb-3">
                  <div className="input-group-prepend">
                   
                    <label
                      className="input-group-text"
                      htmlFor="inputGroupSelect01"
                    >
                      Import
                    </label>

                    <select
                      className="custom-select"
                      id="import"
                      onChange={this.handleChange}
                    >
                      <option>Auswählen</option>
                      <option value="Excel">Import Excel</option>
                      <option value="User">Einzelner User</option>
                    </select>
                  </div>

                </div>
              </div> */}

              {/* {this.state.import !== "Auswählen" ? (
                this.state.import === "Excel" ? (
                  <div>
                    <h1>Import Excel</h1>

                    <div>
                      <label htmlFor="formFileLg" className="form-label">
                        Excel Datei auswählen
                      </label>
                      <input
                        className="form-control form-control-lg"
                        id="formFileLg"
                        onChange={this.handleInput}
                        type="file"
                      />
                    </div>
                  </div>
                ) : ( */}
              <div>
                {/* <h1>Einzelner User</h1> */}
                <form
                  id="CertForm"
                  className="needs-validation"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipUsername">Username</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="emailPrepend"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-person-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                              <path
                                fillRule="evenodd"
                                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.handleChange}
                          id="firstname"
                          placeholder="Vorname"
                          aria-describedby="fistnamePrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie einen eindeutigen und gültigen Benutzernamen.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipUsername">Username</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="emailPrepend"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-person-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                              <path
                                fillRule="evenodd"
                                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.handleChange}
                          id="lastname"
                          placeholder="Nachname"
                          aria-describedby="lastnamePrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie einen eindeutigen und gültigen Benutzernamen.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipUsername">Username</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="emailPrepend"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-person-circle"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                              <path
                                fillRule="evenodd"
                                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          onChange={this.handleChange}
                          id="email"
                          placeholder="E-Mail"
                          aria-describedby="emailPrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie einen eindeutigen und gültigen Benutzernamen.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>



                  <div className="form-row">
                    <div className="col mb-3">
                      {/* <label for="validationTooltipPasswort">password</label> */}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="PasswortPrepend"
                          >
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 16"
                              className="bi bi-key"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"
                              />
                              <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          onChange={this.handleChange}
                          id="password"
                          placeholder="Passwort"
                          aria-describedby="PasswortPrepend"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie ein gültiges Passwort.
                          {/* { authError ? <p>{authError}</p> : null } */}
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>


                  <div className="form-row">
                    <div className="col mb-3">
                      <div className="input-group">

                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="endyear"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                            </svg>

                          </span>

                        </div>


                        <input
                          type="number"
                          className="form-control"
                          onChange={this.handleChange}
                          id="startyear"
                          aria-describedby="fistnamePrepend"
                          min={new Date().getFullYear().toString() - 4}
                          // max={new Date().getFullYear().toString()+5}
                          step="1"
                          // defaultValue={new Date().getFullYear().toString()}
                          placeholder="Startjahr der Ausbildung angeben"
                          required
                        />
                        <div className="invalid-tooltip">
                          Bitte wählen Sie das Startjahr aus.
                          {/* { authError ? <p>{authError}</p> : null } */}
                        </div>
                        <div className="valid-feedback">Sieht gut aus!</div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col mb-3">
                      <div className="input-group-prepend">
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                        >
                          Rolle
                        </label>

                        <select
                          className="custom-select"
                          id="role"
                          required
                          onChange={this.handleChange}
                        >
                          <option defaultValue hidden>Auswählen</option>
                          <option value="1">1 - Infoordner</option>
                          <option value="2">2 - Admin</option>
                          <option value="3">3 - Ausbilder</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <button
                    id="btnSubmit"
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                  >
                    Registrieren
                  </button>
                  <div>
                    {/* { authError ? <p>{authError}</p> : null } */}
                  </div>


                </form>
              </div>

              {/* )
              ) : (
                <></>
              )} */}
            </div>
            <div className="card-footer">

              {/* <a
                type="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
                className="info"
              >
                <p>Informationen zum Registrieren</p>
              </a> */}
              <Link to="/Backend">
                <span Style="display: block;">Zurück zum Backend</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      //   </div>
      // </div>
    );
    // }
  }
}

export default Register;
