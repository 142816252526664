import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';

import ImgA from './image/Ad_Bild_1.jpg'
import ImgB from './image/Ad_Bild_2.jpg'
import ArburgLogo from "../../../ressources/Navbar/ARB_White.png"

Font.register({
    family: "Roboto"
})
    const styles = StyleSheet.create({
        page: {
            fontSize: 11,
            fontFamily: "Roboto"
        },
        arb_logo: {
            position: "absolute",
            top: 10,
            right: 10,
            width: 90
        },
        banner_frame: {
            overflow: "hidden",
        },
        banner_img: {
            zIndex: 1
        },
        header: {
            fontSize: 13,
            fontWeight: "bold",
            paddingBottom: 5
        },
        header_text: {
            width: "100%",
            backgroundColor: '#00000030',
            top: 300,
            padding: 8,
            fontSize: 20,
            fontWeight: "extrabold",
            position: "absolute",
            color: '#ffffff'
        },
        pre: {
            padding: 15,
            backgroundColor: '#33cc99',
            color: '#ffffff',
        },
        list_container: {
            padding: 15,
        },
        list_item: {
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            justifyContent: "flex-start",
        },
        slash_line: {
            color: '#33cc99',
            paddingRight: 5
        },
        container: {
            padding: 15,
        },
        bottom: {
            backgroundColor: '#33cc99',
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-evenly",
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: 10
        },
        bottom_container: {
            textAlign: "center",
        },
        bottom_white: {
            color: '#ffffff'
        }
    })

let PDFGen = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.banner_frame}>
                {props.image === "imga" ? 
                <Image style={styles.banner_img} src={ImgA} /> :
                <Image style={styles.banner_img} src={ImgB}/>
                }
               <View style={styles.header_text}>
                    <Text>{props.title}</Text>
               </View>
               <Image src={ArburgLogo} style={styles.arb_logo} />
            </View>
            <View style={styles.pre}>
               <Text style={styles.header}>{props.shortDescHeader}</Text>
               <Text>{props.shortDesc}</Text>
             </View>
             <View style={styles.list_container}>
               <Text style={styles.header}>{props.b1Title}</Text>
               {props.b1Content.map((index, i) => {
                return (     
                    <View style={styles.list_item} key={i}>
                        <Text style={styles.slash_line}>/</Text>
                        <Text>{index}</Text> 
                    </View>
                )
               })}
             </View>
             <View style={styles.list_container}>
                <Text style={styles.header}>{props.b2Title}</Text>
                {props.b2Content.map((index, i) => {
                    return (     
                        <View style={styles.list_item} key={i}>
                            <Text style={styles.slash_line}>/</Text>
                            <Text>{index}</Text> 
                        </View>
                    )
                })}
             </View>
             <View style={styles.container}>
               <Text style={styles.header}>{props.endB1Title}</Text>
               <Text>
                    {props.endB1Content}
               </Text>
             </View>
             <View style={styles.container}>
               <Text style={styles.header}>{props.endB2Title}</Text>
               <Text>
                    {props.endB2Content}
               </Text>
             </View>
             <View style={styles.bottom}>
                <View style={styles.bottom_container}>
                    <Text>Land</Text>
                    <Text style={styles.bottom_white}>{props.country}</Text>
                </View>
                <View style={styles.bottom_container}>
                    <Text>Ort</Text>
                    <Text style={styles.bottom_white}>{props.place}</Text>
                </View>
                <View style={styles.bottom_container}>
                    <Text>Abteilung</Text>
                    <Text style={styles.bottom_white}>{props.department}</Text>
                </View>
             </View>
        </Page>
    </Document>
)
export default PDFGen;