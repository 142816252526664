import React from "react";
import {fb} from "../../../firebase";
// import { Link } from "react-router-dom";
// import { v4 as uuid } from "uuid";
//SCSS
// import "./Homepage.scss";
import logo1 from "../../../ressources/Navbar/bell-2-64.png"
import logo2 from "../../../ressources/Navbar/bell-3-64.png";
import "./Alert.scss";

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.ref = fb.firestore().collection("fl_content");
    this.unsubscribe = null;
    this.state = {
      infoleiste: [],
      open: false,
    };
  }
  onCollectionUpdate = (querySnapshot) => {
    const infoleiste = [];
    querySnapshot.forEach((doc) => {
      var schema;

      if (doc.data()._fl_meta_.schema === "infoleiste") {
        schema = doc.data()._fl_meta_.schema;
        const {
          infoText,
          infoColor,
          infoBackgroundColor,
          infoLink,
          order,
        } = doc.data();
        infoleiste.push({
          key: doc.id,
          schema,
          infoText,
          infoLink,
          infoColor,
          infoBackgroundColor,
          order,
        });
      }
    });
    // this.setState({
    //   infoleiste,
    // });


    this.setState( (prevState) => {

      return {
        ...prevState,
        infoleiste: infoleiste.sort(function (a, b) {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        })
      }

    })


  };
  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);


  }
  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return (
      <>
        {this.state.infoleiste.length > 0 ? (
          <button className="btn nav-link nav-focus">
          <img
            alt="Bell"
            className="LogoArburg2"
            src={logo2}
            onClick={() => {
              this.setState({ open: !this.state.open });
            }}
          ></img>
          </button>
        ) : (
          <p className="nav-link">
          <img alt="Bell" className="LogoArburg2" src={logo1}></img>
          </p>
        )}
        {this.state.open === true ? (
          <>
            <div className="black-background"></div>
            <div className="alerts">
              <div>
                <h1>Infos</h1>
              </div>
              <button
                className=" btn close-button"
                onClick={() => {
                  this.setState({ open: !this.state.open });
                }}
              >
                X
              </button>

              <div>
                {this.state.infoleiste.map((info, index) => {
                  return (
                    <a
                    key={`${info.title}-${index}`}
                      href={info.infoLink}
                      style={{ color: info.infoColor }}
                    >
                      <div
                        style={{ backgroundColor: info.infoBackgroundColor }}
                        className="alert alert-dismissible fade show text-center"
                        role="alert"
                      >
                        <div>{info.infoText}</div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Alert;
