/**
 * @file: addAusbildungBeta.jsx
 * @description: contains a Beta version for the add Component
 */
import { useEffect, useRef } from 'react';
import { useState, useContext } from 'react';
import { UserContext } from '../../../';
import { fb } from '../../../firebase';
import './AddAusbildung.scss';

export default function AddAusbildungBeta() {
    let [popup, setPopup] = useState(false);
    const userCont = useContext(UserContext);
    
    let name = "";
    let title = "";
    let country = "";
    let place = "";
    let department = "";
    let direction = "Technik";
    let type = "Ausbildung";
    let link = "";
    let shortDesc = "";
    let shortDescHeader = "";
    let image = "Kaufm";
    let dispType = "Ausbildung";
    let startDate = "";

    let benefitsArr = [];

    function ManageBenefitArr(status, name) {
        if(status === true) {
            benefitsArr.push(name);
        } else {
            benefitsArr = benefitsArr.filter(item => item !== name)
        }
    }

    let b1Title = "";
    let b1Line = "";

    let [b1Content, setB1Content] = useState([]);
    let [betaBlock1Content, betaSetBlock1Content] = useState([]);
    let block1Ref = useRef([]);

    function HandleBlock1Content() {
        setB1Content([...b1Content, b1Line])
    } 
    let b2Title = "";
    let b2Line = "";

    let [b2Content, setB2Content] = useState([]);

    let b3Title = "";
    let b3Line = "";

    let [b3Content, setB3Content] = useState([]);

    let b4Title = "";
    let b4Content = "";

    function SaveData() {
        let pushObj = {
            archive: false,
            changedBy: "",
            changedDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
            createdBy: `${userCont?.firstname} ${userCont?.lastname}`,
            creationDate: `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
            data: {
                benefits: benefitsArr,
                content: {
                    b1Content: b1Content,
                    b1Title: b1Title,
                    b2Content: b2Content,
                    b2Title: b2Title,
                    b3Content: b3Content,
                    b3Title: b3Title,
                    b4Content: b4Content,
                    b4Title: b4Title
                },
                direction: direction,
                img: image,
                link: link,
                name: name,
                postTitle: title,
                place: place,
                shortDesc: shortDesc,
                shortDescHeader: shortDescHeader,
                type: type.toLowerCase(),
                displayType: type,
                startDate: startDate
            }
        }
        fb.firestore().collection("ausbildung").add(pushObj)
        window.alert('Artikel wurde erfolgreich angelegt!')
        setB1Content([])
        setB2Content([])
        setB3Content([])
        setPopup(false)
    }

    function Block1MainContent() {
        return (
            <div className="content-container">
                <label className="form-label">Block 1 - Spiegelstriche:</label>
                <div className="content-list-items">
                    {b1Content.map((index, i) => {
                        return (
                            <div key={index} className="content-list-item">
                                <span>{index}</span>
                                <button onClick={() => {
                                    setB1Content(b1Content => b1Content.filter(item => item !== index))
                                }}>&#9986;</button>
                            </div>
                        )
                    })}
                </div>
                <div className=" input-group mb-3">
                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                        b1Line = e.target.value
                    }}/>
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                        setB1Content([...b1Content, b1Line]);
                    }}>Hinzufügen</button>
                </div>
            </div>
        )
    }

    function PopupWindow() {
        if(popup === true) {
            return (
                <div className="add-praktikum-popup">
                    <div className="add-praktikum-main-window">
                        <div className="main-window-header">
                            <div>
                                <h1>Eintrag anlegen</h1>
                            </div>
                            <div>
                                <button className="close-btn" onClick={() => {
                                    setPopup(false)
                                    setB1Content([])
                                    setB2Content([])
                                }}>&#10006;</button>
                            </div>
                        </div>

                        <div className="main-window-sections">

                            {/* Section 1 */}
                            <div className="section-container">
                                <div className="content-container">
                                    <label className="form-label">Titel (Jobboard Liste):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        onChange={(e) => {
                                            name = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Titel (Jobbeschreibung):</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Name"
                                        onChange={(e) => {
                                            title = e.target.value;
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Ort:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Loßburg"
                                        onChange={(e) => {
                                            place = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Startdatum:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="01.09.20xx"
                                        onChange={(e) => {
                                            startDate = e.target.value
                                        }}/>
                                    </div>
                                </div>

                                <div className="content-container">
                                    <label className="form-label">Link:</label>
                                    <div className=" input-group mb-3">
                                        <input 
                                        className="form-control" 
                                        type="text" 
                                        placeholder="Link"
                                        onChange={(e) => {
                                            link = e.target.value
                                        }}/>
                                    </div>
                                </div>
                                
                                <div className="content-container">
                                    <label className="form-label">Art:</label>
                                    <select onChange={(e) => {
                                        type = e.target.value;
                                    }}>
                                        <option value={"Ausbildung"} defaultValue>Ausbildung</option>
                                        <option value={"DHBW"}>DHBW</option>
                                        <option value={"Praktikum"}>Praktikum</option>
                                    </select>
                                </div>
                                
                                <div className="content-container">
                                    <label className="form-label">Fachrichtung:</label>
                                    <select onChange={(e) => {
                                        direction = e.target.value;
                                    }}>
                                        <option value={"Technik"} defaultValue>Technik</option>
                                        <option value={"Informatik"}>Informatik</option>
                                        <option value={"Wirtschaft"}>Wirtschaft</option>
                                    </select>
                                </div>

                                <div className="content-container">
                                        <label className="form-label">Hintergrund:</label>
                                        <select onChange={(e) => {
                                            image = e.target.value;
                                        }}>
                                            <option value={"Kaufm"} defaultValue>Kaufmännisch</option>
                                            <option value={"Techn"}>Technisch</option>
                                            <option value={"IT"}>IT</option>
                                        </select>
                                </div>

                            </div>
                                

                            {/* Section 2 */}
                            <div className="section-container">
                                
                            <div className="content-container">
                                <label className="form-label">Preview - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        shortDescHeader = e.target.value
                                    }}/>
                                </div>
                            </div>
                            
                            <div className="content-container">
                                <label className="form-label">Preview - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Kurze Einleitung"
                                    onChange={(e) => {
                                        shortDesc = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                            </div>
                            
                            </div>
                            {/* Section 3 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 1 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        b1Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                                  
                            <Block1MainContent />
                            
                            </div>
                            
                            {/* Section 4 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 2 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        b2Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                                  
                            <div className="content-container">
                                <label className="form-label">Block 2 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {b2Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setB2Content(block2Content => block2Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        b2Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setB2Content([...b2Content, b2Line])
                                    }}>Hinzufügen</button>
                                </div>
                            </div>

                            </div>

                            {/* Section 5 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 3 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        b3Title = e.target.value
                                    }}/>
                                </div>
                            </div>

                            <div className="content-container">
                                <label className="form-label">Block 3 - Spiegelstriche:</label>
                                <div className="content-list-items">
                                    {b3Content.map((index, i) => {
                                        return (
                                            <div key={index} className="content-list-item">
                                                <span>{index}</span>
                                                <button onClick={() => {
                                                    setB3Content(b3Content => b3Content.filter(item => item !== index))
                                                }}>&#9986;</button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className=" input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Zeileninhalt" onChange={(e) => {
                                        b3Line = e.target.value
                                    }}/>
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => {
                                        setB3Content([...b3Content, b3Line])
                                    }}>Hinzufügen</button>
                                </div>
                            </div>
                            
                            </div>
                            {/* Section 6 */}
                            <div className="section-container">

                            <div className="content-container">
                                <label className="form-label">Block 4 - Überschrift:</label>
                                <div className=" input-group mb-3">
                                    <input 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Überschrift"
                                    onChange={(e) => {
                                        b4Title = e.target.value
                                    }}/>
                                </div>
                            </div>
                            
                            <div className="content-container">
                                <label className="form-label">Block 4 - Hauptinhalt:</label>
                                <div className=" input-group mb-3">
                                    <textarea 
                                    className="form-control" 
                                    type="text" 
                                    placeholder="Hier kommt der Inhalt hin"
                                    onChange={(e) => {
                                        b4Content = e.target.value
                                    } }>
                                    </textarea>
                                </div>
                            </div>
                           
                            </div>
                            {/* Section 7 */}
                            <div className="section-container">
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-barrierefreiheit" onClick={(e) => ManageBenefitArr(e.target.checked, "Barrierefreiheit")} />
                                    <label htmlFor='check-barrierefreiheit'>Barrierefreiheit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-coaching" onClick={(e) => ManageBenefitArr(e.target.checked, "Coaching")} />
                                    <label htmlFor='check-coaching'>Coaching</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-essenszuschuss" onClick={(e) => ManageBenefitArr(e.target.checked, "Essenszuschuss")} />
                                    <label htmlFor='check-essenszuschuss'>Essenszuschuss</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-flexarb" onClick={(e) => ManageBenefitArr(e.target.checked, "flexarb")} />
                                    <label htmlFor='check-flexarb'>Flexible Arbeitszeit</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundmngmt" onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundmngmt")} />
                                    <label htmlFor='check-gesundmngmt'>Gesundheitsmanagement</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-gesundcent" onClick={(e) => ManageBenefitArr(e.target.checked, "Gesundcent")} />
                                    <label htmlFor='check-gesundcent'>Gesundheitszentrum</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-frei" onClick={(e) => ManageBenefitArr(e.target.checked, "Freizeit")} />
                                    <label htmlFor='check-frei'>Hoher Freizeitwert</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-ho" onClick={(e) => ManageBenefitArr(e.target.checked, "Homeoffice")} />
                                    <label htmlFor='check-ho'>Homeoffice</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-international" onClick={(e) => ManageBenefitArr(e.target.checked, "International")} />
                                    <label htmlFor='check-international'>Internationales Umfeld</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-jobticket" onClick={(e) => ManageBenefitArr(e.target.checked, "Landesjugendticket")} />
                                    <label htmlFor='check-jobticket'>Landesjugendticket</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kantine" onClick={(e) => ManageBenefitArr(e.target.checked, "Kantine")} />
                                    <label htmlFor='check-kantine'>Kantine</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kita" onClick={(e) => ManageBenefitArr(e.target.checked, "Kita")} />
                                    <label htmlFor='check-kita'>Kita</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-parkplatz" onClick={(e) => ManageBenefitArr(e.target.checked, "Parkplatz")} />
                                    <label htmlFor='check-parkplatz'>Parkplatz</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-sonder" onClick={(e) => ManageBenefitArr(e.target.checked, "Sonderzahlungen")} />
                                    <label htmlFor='check-sonder'>Sonderzahlungen</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-work_life" onClick={(e) => ManageBenefitArr(e.target.checked, "Work_Life")} />
                                    <label htmlFor='check-work_life'>Work Life Balance</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-zusatz_quali" onClick={(e) => ManageBenefitArr(e.target.checked, "Zusatz_Quali")} />
                                    <label htmlFor='check-zusatz_quali'>Zusatzqualifikationen</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-kleidung" onClick={(e) => ManageBenefitArr(e.target.checked, "Arbeitskleidung")} />
                                    <label htmlFor='check-kleidung'>Arbeitskleidung</label>
                                </div>
                                <div className='checkbox-container'>
                                    <input type="checkbox" name="" id="check-uebernahme" onClick={(e) => ManageBenefitArr(e.target.checked, "Uebernahme")} />
                                    <label htmlFor='check-uebernahme'>Hohe Übernahmequote</label>
                                </div>
                            </div>

                            {/* Section 8 */}
                            <div className="content-actions">
                                <button className='btn btn-success' onClick={() => SaveData()}>Speichern</button>
                            </div>

                        </div>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
    return (
        <>
            <button className="add-praktikum-btn" onClick={() => setPopup(true)}>&#10010;</button>
            <PopupWindow />
        </>
    )
}