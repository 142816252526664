import React from 'react'
import {fb} from '../../firebase';
import AlleGruende from '../../components/Gruende/Gruende.jsx';
import { v4 as uuid } from 'uuid';
import AusbildungAccordion from '../../components/Ausbildung/AusbildungAccordion/AusbildungAccodion';


//SCSS
import './Ausbildung.scss';


class Ausbildung extends React.Component {
  
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
          ausbildungsberufe: [],
          grundeFurArb: [],
        };
      }
      onCollectionUpdate = (querySnapshot) => {
        const ausbildungsberufe = [];
        const grundeFurArb = [];
        var imageUrl ="";
        
        querySnapshot.forEach((doc) => {

          if (doc.data()._fl_meta_.schema === "grundeFurArb") {
            const { title, imageUrlDev, imageUrlProd } = doc.data();
           
            if (process.env.NODE_ENV === "production") {
              imageUrl = imageUrlProd;
            } else {
              imageUrl = imageUrlDev;
            }
            
            grundeFurArb.push({
              key: doc.id, 
              doc, //DocumentSnapshot
              title, 
              imageUrl,
            });
          }
          if(doc.data()._fl_meta_.schema === "ausbildung"){
            const { title, imageUrlDev, imageUrlProd, summary, content, tags, requirement, school, duration,order, sector, linkBewerbung  } = doc.data();

            if (process.env.NODE_ENV === "production") {
              imageUrl = imageUrlProd;
            } else {
              imageUrl = imageUrlDev;
            }
            
            ausbildungsberufe.push({
                key: doc.id,
                doc, // DocumentSnapshot
                title,
                imageUrl,
                summary,
                content, 
                tags, 
                requirement, 
                school, 
                duration,
                order, 
                sector,
                linkBewerbung,  

            })
          }

            });
            
            this.setState({
              ausbildungsberufe,
              grundeFurArb
           });
          }
        
        componentDidMount() {
            this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
          }
          componentWillUnmount() {
            this.unsubscribe()
          }

 render(){
    return(
      <div key={uuid()}>
        <div className="container">
          <h1 className="my-4">Karriere bei ARBURG</h1>
        </div>

        <div className="container">
          <AlleGruende grundeFurArb={this.state.grundeFurArb} />
        </div>

        <div className="container">
          <AusbildungAccordion ausbildungsberufe={this.state.ausbildungsberufe}/>
      </div>
      </div>
            
    )
  }


}
export default Ausbildung