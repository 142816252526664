import React, { useState } from "react";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { fb } from "../../../../firebase";
import { useEffect } from "react";

function DateTimePicker() {
  const [date, setDate] = useState();

  useEffect(() => {
    fb.firestore()
      .collection("Countdown")
      .get()
      .then((data) => {
        data.forEach((doc) => {
          var cDate = new Date(
            doc.data().month + "." + doc.data().day + "." + doc.data().year
          );
          setDate(cDate);
        });
      });
  }, []);

  const saveDateToDb = () => {
    fb.firestore()
      .collection("Countdown")
      .doc("1")
      .set({
        day: new Date(date).getDate(),
        month: new Date(date).getMonth() + 1,
        year: new Date(date).getFullYear(),
        hours: new Date(date).getHours(),
        min: new Date(date).getMinutes(),
        sec: new Date(date).getSeconds(),
      })
      .then(() => console.log("Success!"));
  };
  return (
    <div>
      <DatePicker
        date={date}
        onDateChange={setDate}
        locale={enGB}
        format="dd/MM/yyyy HH:mm"
      >
        {({ inputProps, focused }) => (
          <div className="form-group row">
            <label htmlFor="colFormLabel" className="col-sm-2 col-form-label">
              Countdown Enddatum
            </label>
            <div className="col-sm-10">
              <input
                className={"input form-control" + (focused ? " -focused" : "")}
                {...inputProps}
              />
            </div>
          </div>
        )}
      </DatePicker>
      <button className="btn btn-primary" onClick={saveDateToDb}>
        {" "}
        Speichern{" "}
      </button>
    </div>
  );
}
export default DateTimePicker;
