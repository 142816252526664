// import { start } from '@popperjs/core';
import React from 'react';
// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
// import firebase from '../../firebase'
import { fb } from '../../firebase'
import Countdown from "../Countdown/Countdown";

import "./VeranstaltungDetails.scss";


const CountdownShow = (e) => {
    if (e.show === true) {
        return <h2 className="text-primary">ein paar Tagen</h2>;
    } else {
        return (
            <div>
                <Countdown date={e.date} />
            </div>
        );
    }
};

class VeranstaltungDetails extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            veranstaltung: [],
            url: "",
            key: ""
        };
    }

    onCollectionUpdate = (querySnapshot) => {
        const veranstaltung = [];
        querySnapshot.forEach((doc) => {

            if (doc.data()._fl_meta_.schema === "veranstaltungen" && doc.data().url === this.state.key) {
                const { title, url, iFrame, startDatum, endDatum, activ, dauerAktiv } = doc.data();
                veranstaltung.push({
                    key: doc.id,
                    title,
                    url,
                    iFrame,
                    startDatum,
                    endDatum,
                    activ,
                    dauerAktiv
                });
            }

        })

        this.currentTime();

        this.setState({
            veranstaltung
        });
    }

    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);

        var url = window.location.href;
        var laenge = 0
        laenge = url.search('veranstaltung/') + 14;
        laenge = url.search('Veranstaltung/') + 14;
        var key = url.slice(laenge);

        this.setState({ key });
        this.setState({ url });

    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    currentTime() {
        const today = new Date();
        var date= "";
        var month = "";
        var hour = "";
        var minutes = "";
        if (today.getDate() < 10) {
             date = "0" + today.getDate()
        }
        else {
             date = today.getDate()
        }

        if (today.getMonth() < 10) {
            month = "0" + (today.getMonth() + 1)
        }
        else {
            month = today.getMonth()
        }
        if (today.getHours() < 10) {
            hour = "0" + (today.getHours())
        }
        else {
            hour = today.getHours()
        }
        if (today.getMinutes() < 10) {
            minutes = "0" + (today.getMinutes())
        }
        else {
            minutes = today.getMinutes()
        }
        const currentTime = today.getFullYear() + '-' + month + '-' + date + 'T' + hour + ":" + minutes;
        this.setState({ currentTime: currentTime })
    }


    render() {
        return (
            <div>
                {this.state.veranstaltung.map((anm, index) => {
                    // console.log("startDatum: " + anm.startDatum)
                    // console.log("endDatum: " + anm.endDatum)
                    // console.log("currentTime: " + this.state.currentTime)

                    if ((anm.dauerAktiv === true ) || (this.state.currentTime > anm.startDatum && this.state.currentTime < anm.endDatum && anm.activ === true)) {
                        // console.log("Anmeldung anzeigen")
                        return (
                            <iframe
                                key={`${anm.url}-${index}`}
                                title={anm.title}
                                className="AnmeldungInfotage"
                                src={anm.iFrame}
                            ></iframe>
                        );

                    }
                    else if (this.state.currentTime < anm.startDatum && anm.activ === true) {
                        // console.log("Anmeldung in zukunft")
                        return (
                            <div className="container event-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="my-4">{anm.title}</h1>
                                        <p>Die Anmeldung für die <b>{anm.title}</b> beginnt in<CountdownShow date={anm.startDatum} /></p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else if ((this.state.currentTime >= anm.startDatum && this.state.currentTime >= anm.endDatum) && anm.activ === true) {
                        // console.log("Anmeldung in vergangenheit")
                        return (
                            <div className="container event-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="my-4">{anm.title}</h1>
                                        <p>Die Anmeldung für die <b>{anm.title}</b> ist bereits geschlossen.</p>
                                        <p>Wir freuen uns schon Sie nächstes Jahr begrüßen zu können.</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else if (anm.activ === false) {
                        // console.log("Anmeldung nicht aktiv")
                        return (
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="my-4">{anm.title}</h1>
                                        <p>Die Anmeldung für die <b>{anm.title}</b> ist zurzeit nicht aktiv.</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    return<></>
                    
                })}
            </div>
        );
    }
}
export default VeranstaltungDetails;