import React from 'react'
import { fb } from '../../../firebase';
import AzubiInfos from '../../../components/AzubiInfo/AzubiInfos';

//SCSS
import './AzubiInfoMonitor.scss';

class AzubiInfoMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.ref = fb.firestore().collection('fl_content');
        this.unsubscribe = null;
        this.state = {
            azubiInformationen: [],
        };
    }

    onCollectionUpdate = (querySnapshot) => {
        const azubiInformationen = [];
        var imageUrl = "";

        querySnapshot.forEach((doc) => {

            if (doc.data()._fl_meta_.schema === "azubiInformationen") {
                const { title, summery, imageUrlDev, imageUrlProd, url, order } = doc.data();
                if (process.env.NODE_ENV === "production") {
                    imageUrl = imageUrlProd;
                } else {
                    imageUrl = imageUrlDev;
                }
                azubiInformationen.push({
                    key: doc.id,
                    doc,
                    title,
                    summery,
                    url,
                    imageUrl,
                    order,
                });
            }
        });
        this.setState({
            azubiInformationen
        });
    }

    componentDidMount() {
        this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    }
    componentWillUnmount() {
        this.unsubscribe()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <h1 className="my-4">Ausbildung-Aktuell Monitor</h1>
                        <AzubiInfos azubiInfo={this.state.azubiInformationen}></AzubiInfos>
                    </div >
                </div >
            </div >
        )
    }
}

export default AzubiInfoMonitor