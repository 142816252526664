import React from "react";
import "./BlogPost.scss";

const BlogPost = ({ title, imageUrl, videoUrl, summary }) => {

  // var title_id = title;
  // title_id = title_id.split(" ").join("");
  // title_id = title_id.split(".").join("_");

  // function stopVideo() {
  //   let videos = document.getElementsByTagName("video");
  //   let videoArray = [];
  //   for (let index = 0; index < videos.length; index++) {
  //     videoArray[index] = videos[index];
  //   }
  //   videoArray.map(video => {
  //     video.pause();
  //     return null;
  //     video.currentTime = 0; //Kann man auch weglassen, falls der user mal aus Versehen daneben klickt...
  //   })

  // }

  return (
    <div className="card-group col-lg-4 col-md-6 col-sm-12">
      <div className="card mb-4 blogpost">

        <video controls name="media">
          <source src={videoUrl} type="video/mp4">
          </source>
        </video>

        <div className="card-image">
          {/* <img src={imageUrl} className="card-img" alt={title} /> */}
        </div>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text"> {summary}</p>
          {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target={"#exampleModal" + title_id}>
            Mehr erfahren
          </button> */}
          {/* <div className="modal fade" id={"exampleModal" + title_id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={stopVideo}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={stopVideo}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="embed-responsive embed-responsive-16by9">
                    <video controls name="media">
                      <source src={videoUrl} type="video/mp4">
                      </source>
                    </video>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Schließen</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
